import React from 'react'

function AideFaq() {
  return (
    <>
        <h2 className='titlePage'>Aide et FAQ</h2>
    </>
  )
}

export default AideFaq