import React from "react";

function NotFound() {
    return ( 
        <div className="error">
            <h1>404</h1>
            <p>Oups, cette page n&apos;hexiste pas</p>
        </div>
     );
}

export default NotFound;