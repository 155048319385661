import React, { useEffect, useState } from 'react'
import styles from './CreateOrEditProject.module.scss'
import Cookies from 'js-cookie'
import NotifFeedBackFecth from '../../components/NotifFeedBackFecth/NotifFeedBackFecth'
import { useNavigate } from 'react-router-dom'
import ConfirmationRequired from '../ConfirmationRequired/ConfirmationRequired'

function CreateOrEditProject({ context, project }) {
  const navigate = useNavigate()
  const [title, setTitle] = useState('')
  const [details, setDetails] = useState('')
  const [budget, setBudget] = useState('Moins de 500 €')
  const [profilsSearch, setProfilsSearch] = useState(['Influenceur'])
  const [loading, setLoading] = useState('')
  const [confirmation, setConfirmation] = useState('')
  const [confirmationContainer, setConfirmationContainer] = useState(false)

  // Confirmation required lors de la fermeture ou la publication d'un projet
  const ShowConfirmationContainer = (e) => {
    e.preventDefault()
    setConfirmationContainer(!confirmationContainer)
    setConfirmation(e.target.name)
  }

  const ConfirmationModif = async () => {
    if (confirmation === 'publish') {
      await EditProject(confirmation)
      setConfirmationContainer(!confirmationContainer)
      setConfirmation('')
    }
    if (confirmation === 'close') {
      await EditProject(confirmation)
      // await CloseProject(confirmation)
      setConfirmationContainer(!confirmationContainer)
      setConfirmation('')
    }
  }

  // Les constantes de la gestions des notifs feedBack
  const [modifAuthorize, setModifAuthorize] = useState('')
  const [messageFecth, setMessageFecth] = useState('')
  const [callBackMessage, setCallBackMessage] = useState('')

  // Function reset du message feedBack
  function resetFeedback() {
    setTimeout(() => {
      setModifAuthorize('')
      setMessageFecth('')
      setCallBackMessage('')
    }, 6000)
  }
  //

  // Création et formatage de la date de publication
  const currentDate = new Date()
  const datePublish = currentDate.toISOString()
  //

  // Récuperation du token de connexion de l'user et definition d'un header de requette
  const token = Cookies.get('_influencapp_tk_log')
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  myHeaders.append('Authorization', `Bearer ${token}`)
  //

  // function de l'ajout ou de la suppression d'un profil dans le tableau du profils recherchés.
  const searchProfils = (e) => {
    const optionValue = e.target.value

    if (e.target.checked) {
      setProfilsSearch([...profilsSearch, optionValue])
    } else {
      setProfilsSearch(profilsSearch.filter((option) => option !== optionValue))
    }
  }

  // function de la création d'un nouveau projet
  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(e.target.name)

    if (e.target.name !== 'save' && e.target.name !== 'publish') {
      setTimeout(() => {
        setLoading('')
        setModifAuthorize('error')
        setCallBackMessage('flex')
        setMessageFecth(
          "Oups, une erreur s'est produite, veuillez réessayer ultérièrement.",
        )

        resetFeedback()
      }, 1000)
    } else {
      // les données a envoyé dans le body de la requette
      const dataFecth = {
        title: title,
        description: details,
        budget: budget,
        statut: e.target.name === 'publish' ? 'prePublish' : e.target.name,
        profilSearch: profilsSearch,
        dateCrea: datePublish,
      }

      try {
        const requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: JSON.stringify(dataFecth),
        }

        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/project/create`,
          requestOptions,
        )
        if (response.ok) {
          setTimeout(() => {
            // on reinitialise les données une fois que la requette s'est bien passé
            setTitle('')
            setDetails('')
            setBudget('Moins de 500 €')
            setProfilsSearch(['Influenceur'])
            document.querySelector('form').reset()
            setLoading('')

            // on affiche un message de feedback pour indiquer l'enregistrement ou la publication du projet.
            setModifAuthorize(true)
            setCallBackMessage('flex')
            if (e.target.name === 'save') {
              setMessageFecth(
                "Votre projet a été enregistré avec succès, il n'est pas encore plublié.",
              )
            } else if (e.target.name === 'publish') {
              setMessageFecth(
                'Votre demande de publication a été prise en compte.',
              )
            }

            // on reset le message feedback après 3s
            resetFeedback()
          }, 2000)
        } else {
          setTimeout(() => {
            setLoading('')
            setModifAuthorize('error')
            setCallBackMessage('flex')
            setMessageFecth(
              "Veuillez s'il vous plait remplir correctement tout les champs obligatoire (*)",
            )

            resetFeedback()
          }, 2000)
        }
      } catch (error) {
        setLoading('')
        setModifAuthorize('error')
        setCallBackMessage('flex')
        setMessageFecth(
          "Oups, une erreur s'est produite, veuillez réessayer ultérièrement.",
        )
        console.log("Une erreur s'est produite lors de la requette", error)

        resetFeedback()
      }
    }
  }

  // La function pour fermer un projet
  // const CloseProject = async (value) => {
  //   setLoading(value)

  //   const requestOptions = {
  //     method: 'PATCH',
  //     headers: myHeaders,
  //     body: JSON.stringify({ id: project?._id }),
  //   }

  //   try {
  //     const response = await fetch(
  //       `${process.env.REACT_APP_API_URL}/project/close`,
  //       requestOptions,
  //     )
  //     if (response.ok) {
  //       setTimeout(() => {
  //         setLoading('')
  //         setModifAuthorize(true)
  //         setCallBackMessage('flex')
  //         setMessageFecth('Projet fermé avec succès.')
  //         resetFeedback()

  //         setTimeout(() => {
  //           navigate('/my-projects')
  //         }, 1000)
  //       }, 2000)
  //     } else {
  //       setTimeout(() => {
  //         setLoading('')
  //         setModifAuthorize('error')
  //         setCallBackMessage('flex')
  //         setMessageFecth(
  //           "Une erreur s'est produite, veuillez réessayer ultérieurement.",
  //         )

  //         resetFeedback()
  //       }, 2000)
  //     }
  //   } catch (error) {
  //     console.log('error lors de la requette fecth', error)
  //     setLoading('')
  //     setModifAuthorize('error')
  //     setCallBackMessage('flex')
  //     setMessageFecth(
  //       "Une erreur s'est produite, veuillez réessayer ultérieurement.",
  //     )

  //     resetFeedback()
  //   }
  // }
  //

  // La function de modification d'un projet
  const EditProject = async (value) => {
    setLoading(value)

    const dataFecth = {
      id: project._id,
      title: title,
      description: details,
      budget: budget,
      statut: value === 'publish' ? 'prePublish' : value,
      profilSearch: profilsSearch,
      ...(value === 'publish' && { dateCrea: datePublish }),
      ...(project.statut === 'publish' && { dateProjet: project.dateCrea }),
    }

    const requestOptions = {
      method: 'PATCH',
      headers: myHeaders,
      body: JSON.stringify(dataFecth),
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/project/update`,
        requestOptions,
      )
      if (response.ok) {
        setTimeout(() => {
          setLoading('')
          setModifAuthorize(true)
          setCallBackMessage('flex')
          setMessageFecth(
            `${value === 'publish' ? 'Votre demande de publication a été prise en compte.' : value === 'close' ? 'Projet fermé avec succès.' : 'Projet enregistré avec succès.'}`,
          )
          resetFeedback()

          if (value === 'publish') {
            setTimeout(() => {
              navigate('/my-projects')
            }, 5000)
          }
        }, 1000)
      } else {
        setTimeout(() => {
          setLoading('')
          setModifAuthorize('error')
          setCallBackMessage('flex')
          setMessageFecth(
            "Une erreur s'est produite, veuillez réessayer ultérieurement.",
          )

          resetFeedback()
        }, 1000)
      }
    } catch (error) {
      console.log('error lors de la requette fecth', error)
      setLoading('')
      setModifAuthorize('error')
      setCallBackMessage('flex')
      setMessageFecth(
        "Une erreur s'est produite, veuillez réessayer ultérieurement.",
      )

      resetFeedback()
    }
  }
  //

  useEffect(() => {
    if (project) {
      setTitle(project?.title)
      setDetails(project?.description)
      setBudget(project?.budget)
      setProfilsSearch(project?.profilSearch)
    }

    // lorsque l'user click sur la création d'un project de l'un des thèmes depuis la page d'accueil
    if (context === 'createProject' && sessionStorage.getItem('titleProject')) {
      setTitle(
        `Recherche d'un influenceur dans la niche de ${sessionStorage.getItem('titleProject')}`,
      )
      sessionStorage.removeItem('titleProject')
    }
  }, [project, context])

  return (
    <>
      <div className={`fadinAnimation ${styles.allContainer}`}>
        <form>
          <h2>Quels est votre besoin ?</h2>
          <div className={styles.formContentValue}>
            {/* section titre et description du projet */}
            <div>
              <div>
                <div>
                  <label className={styles.titleSection} htmlFor='titleProject'>
                    Titre de votre projet *
                  </label>{' '}
                  <br />
                  <input
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    id='titleProject'
                    type='text'
                    placeholder="ex : recherche d'un influenceur instragram"
                    required
                  />
                </div>

                <div>
                  <label className={styles.titleSection} htmlFor='descProject'>
                    Détails de votre projet *
                  </label>{' '}
                  <br />
                  <textarea
                    value={details}
                    onChange={(e) => setDetails(e.target.value)}
                    id='descProject'
                    minLength={40}
                    cols='30'
                    rows='10'
                    placeholder='100 caractères minimum recommandé'
                  ></textarea>
                </div>
              </div>
            </div>

            <div>
              {/* section du choix des type de profils */}
              <div className={styles.chooseOptionSection}>
                <p className={styles.titleSection}>Profils recherchés *</p>
                <p>Quels types de profils recherchez vous ?</p>
                <label>
                  <input
                    checked={profilsSearch.includes('Influenceur')}
                    onChange={searchProfils}
                    type='checkbox'
                    name='Influenceur'
                    value='Influenceur'
                  />
                  Influenceur
                </label>{' '}
                <br />
                <label>
                  <input
                    checked={profilsSearch.includes('Créateur de contenu')}
                    onChange={searchProfils}
                    type='checkbox'
                    name='Créateur de contenu'
                    value='Créateur de contenu'
                  />
                  Créateur de contenu
                </label>{' '}
                <br />
                <label>
                  <input
                    checked={profilsSearch.includes('Agent')}
                    onChange={searchProfils}
                    type='checkbox'
                    name='Agent'
                    value='Agent'
                  />
                  Agent
                </label>{' '}
                <br />
                <label>
                  <input
                    checked={profilsSearch.includes('Agence')}
                    onChange={searchProfils}
                    type='checkbox'
                    name='Agence'
                    value='Agence'
                  />
                  Agence
                </label>{' '}
                <br />
                <label>
                  <input
                    checked={profilsSearch.includes('Marque')}
                    onChange={searchProfils}
                    type='checkbox'
                    name='Marque'
                    value='Marque'
                  />
                  Marque
                </label>{' '}
                <br />
                <label>
                  <input
                    checked={profilsSearch.includes('Acteur')}
                    onChange={searchProfils}
                    type='checkbox'
                    name='Acteur'
                    value='Acteur'
                  />
                  Acteur
                </label>{' '}
                <br />
              </div>

              {/* section du choix du budget */}
              <div className={styles.chooseOptionSection}>
                <p className={styles.titleSection}>Votre budget *</p>
                <p>
                  Donnez une estimation de votre budget pour la réalisation de
                  ce projet.
                </p>
                <label>
                  <input
                    checked={budget === 'Moins de 500 €'}
                    onChange={(e) => setBudget(e.target.value)}
                    type='radio'
                    name='budget'
                    value='Moins de 500 €'
                  />
                  Moins de 500 €
                </label>{' '}
                <br />
                <label>
                  <input
                    checked={budget === '500 € à 1 000 €'}
                    onChange={(e) => setBudget(e.target.value)}
                    type='radio'
                    name='budget'
                    value='500 € à 1 000 €'
                  />
                  500 € à 1 000 €
                </label>{' '}
                <br />
                <label>
                  <input
                    checked={budget === '1 000 € à 10 000 €'}
                    onChange={(e) => setBudget(e.target.value)}
                    type='radio'
                    name='budget'
                    value='1 000 € à 10 000 €'
                  />
                  1 000 € à 10 000 €
                </label>{' '}
                <br />
                <label>
                  <input
                    checked={budget === 'Plus de 10 000 €'}
                    onChange={(e) => setBudget(e.target.value)}
                    type='radio'
                    name='budget'
                    value='Plus de 10 000 €'
                  />
                  Plus de 10 000 €
                </label>{' '}
                <br />
              </div>
            </div>
          </div>

          {/* les bouttons à afficher si c'est une modification ou une nouvelle création */}
          <div className={styles.btnCallToAction}>
            {context === 'createProject' ? (
              <>
                <button
                  className={styles.publishBtn}
                  name='publish'
                  onClick={handleSubmit}
                >
                  {loading === 'publish' ? 'Publication...' : 'Publier'}
                </button>
                <button
                  className={styles.saveBtn}
                  name='save'
                  onClick={handleSubmit}
                >
                  {loading === 'save' ? 'Enregistrement...' : 'Enregistrer'}
                </button>
              </>
            ) : context === 'editProject' ? (
              <>
                <button
                  className={styles.publishBtn}
                  name='publish'
                  onClick={ShowConfirmationContainer}
                >
                  {loading === 'publish' ? 'Publication...' : 'Publier'}
                </button>
                <button
                  className={styles.saveBtn}
                  name='save'
                  onClick={(e) => {
                    e.preventDefault()
                    EditProject(e.target.name)
                  }}
                >
                  {loading === 'save' ? 'Enregistrement...' : 'Enregistrer'}
                </button>
                <button
                  className={styles.seeBtn}
                  onClick={() => {
                    navigate(`/project/${project?._id}`)
                  }}
                >
                  Voir le projet
                </button>
                <button
                  className={styles.closeBtn}
                  name='close'
                  onClick={ShowConfirmationContainer}
                >
                  {loading === 'close' ? 'En cours...' : 'Fermer le projet'}
                </button>
              </>
            ) : null}
          </div>
        </form>
      </div>

      <NotifFeedBackFecth
        modifAuthorizeValue={modifAuthorize}
        callBackMessageValue={callBackMessage}
        messageFecthValue={messageFecth}
      />
      {confirmationContainer && (
        <ConfirmationRequired
          contexte={
            confirmation === 'publish'
              ? 'Confimez-vous la publication de cet projet, il sera visible par tout le monde une fois valider.'
              : confirmation === 'close'
                ? 'Confimez-vous la fermeture de cet projet (vous pourrez le réouvrir plus tard).'
                : null
          }
          reset={ShowConfirmationContainer}
          confirmation={ConfirmationModif}
        />
      )}
    </>
  )
}

export default CreateOrEditProject
