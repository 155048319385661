import Cookies from "js-cookie";

export async function fecthFavourite(user, context){ 
    const token = Cookies.get('_influencapp_tk_log');

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({ userLiked : user, context : context }),
    };
    
    fetch(`${process.env.REACT_APP_API_URL}/user/favourite`, requestOptions)
    .then(response => { response.json() })
    .then(result => { return result })
    .catch(error => console.log('error', error));

    return;
}