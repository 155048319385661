import React, { useEffect, useState } from 'react'
import styles from './Project.module.scss'
import ProjectInfo from '../../components/ProjectComponent/ProjectInfo/ProjectInfo'
import { useParams } from 'react-router-dom'
import ViewProject from '../../apiUtils/ViewProject'
import AuthorCoordonnees from '../../components/ProjectComponent/AuthorCoordonnees/AuthorCoordonnees'
import AuthorProfile from '../../components/ProjectComponent/AuthorProfile/AuthorProfile'
import Cookies from 'js-cookie'

function Project() {
  const [project, setProject] = useState({})
  const [authorInfo, setAuthorInfo] = useState({})
  const [userAuthSubscription, setSserAuthSubscription] = useState({})
  const [loading, setLoading] = useState('loadingComponent')
  const { id } = useParams()
  const token = Cookies.get('_influencapp_tk_log')

  useEffect(() => {
    setLoading('loadingComponent')
    const fecthProject = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/project?id=${id}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        )
        if (response.ok) {
          const resultat = await response.json()
          setProject(resultat?.project)
          setAuthorInfo(resultat?.authorInfo)
          setSserAuthSubscription(resultat?.userAuthSubscription)
          setLoading('')
        } else {
          setLoading('')
        }
      } catch (error) {
        setLoading('')
        console.log('error lors de la requette fetch', error)
      }
    }
    fecthProject()
  }, [id, token])

  return (
    <>
      {loading === 'loadingComponent' ? null : project.title !== undefined ? (
        <div className={styles.allContainer}>
          <div className={styles.ProjectInfoContainer}>
            <ProjectInfo
              dateCrea={project?.dateCrea}
              title={project?.title}
              description={project?.description}
              budget={project?.budget}
              statut={project?.statut}
              profilSearch={project?.profilSearch}
              views={project?.statistique?.views}
              totalOffers={
                (project?.statistique?.totaleOffers).length !== undefined
                  ? (project?.statistique?.totaleOffers).length
                  : 0
              }
              userAuthSubscription={userAuthSubscription}
              dateProjet={project?.dateProjet}
            />
          </div>

          <div className={styles.authorInfoContainer}>
            <h2 className={styles.titleAuthorAnnouce}>Auteur de ce projet</h2>
            <AuthorProfile
              pseudo={authorInfo?.pseudo}
              name={authorInfo?.name}
              imgUrl={authorInfo?._link_photo_profil?.url}
              statut={authorInfo?.statut}
              lastConnect={authorInfo?.statistique?.lastConnect}
              subscription={authorInfo?.subscription}
            />

            <AuthorCoordonnees
              secteur={authorInfo?.categorie}
              identityVerify={authorInfo?.identity_verif}
              phone={authorInfo?.phone}
            />
          </div>

          <ViewProject projectId={id} />
        </div>
      ) : (
        <div className={styles.notFoundProject}>Aucun projet trouvé</div>
      )}
    </>
  )
}

export default Project
