import styles from './CardUserProfile.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart, faEye, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom/dist'
import { useEffect, useState } from 'react'

function formatDerniereConnexion(dateDerniereConnexion) {
  const dateActuelle = new Date(dateDerniereConnexion)
  const maintenant = new Date()
  const difference = maintenant - dateActuelle

  const minute = 60 * 1000
  const heure = minute * 60
  const jour = heure * 24
  const semaine = jour * 7
  const mois = jour * 30
  const annee = jour * 365

  if (difference < minute) {
    return '• Disponible'
  } else if (difference < heure) {
    const minutes = Math.floor(difference / minute)
    return `${minutes} minute${minutes > 1 ? 's' : ''}`
  } else if (difference < jour) {
    const heures = Math.floor(difference / heure)
    return `${heures} heure${heures > 1 ? 's' : ''}`
  } else if (difference < semaine) {
    const jours = Math.floor(difference / jour)
    return `${jours} jour${jours > 1 ? 's' : ''}`
  } else if (difference < mois) {
    const semaines = Math.floor(difference / semaine)
    return `${semaines} semaine${semaines > 1 ? 's' : ''}`
  } else if (difference < annee) {
    const moisPasses = Math.floor(difference / mois)
    return `${moisPasses} mois`
  } else {
    const annees = Math.floor(difference / annee)
    return `${annees} an${annees > 1 ? 's' : ''}`
  }
}

function abreviationNombre(n) {
  if (n < 1000) {
    return n.toString()
  } else if (n < 1000000) {
    return (n / 1000).toFixed(1) + 'k'
  } else if (n < 1000000000) {
    return (n / 1000000).toFixed(1) + 'M'
  } else if (n < 1000000000000) {
    return (n / 1000000000).toFixed(1) + 'B'
  } else {
    return n.toString()
  }
}

function CardUserProfile({
  pseudo,
  name,
  statut,
  lastConnect,
  urlProfil,
  presentation,
  views,
  inFavorize,
  subscription,
}) {
  const [userConnect, setUserConnect] = useState('')
  const [colorGreen, setColorGreen] = useState('')

  useEffect(() => {
    const newFormat = formatDerniereConnexion(lastConnect)
    setUserConnect(newFormat)

    if (userConnect.split(' ')[0] === '•') {
      setColorGreen('green')
    } else {
      setColorGreen('')
    }
  }, [lastConnect, userConnect])

  return (
    <>
      <Link className={styles.linkCard} to={`/profile/${pseudo}`}>
        <div key={pseudo} className={`cardBackground ${styles.allContainer}`}>
          <div className={styles.firstSection}>
            <p className={styles.imagProfile}>
              {urlProfil !== undefined ? (
                <img
                  src={`${process.env.REACT_APP_URL_BASE_IMAGE}${urlProfil}`}
                  alt={`Profile avatar ${urlProfil}`}
                />
              ) : null}
            </p>
            <div>
              <div className={styles.profileNameContainer}>
                <h3>{name ? `${name}` : pseudo}</h3>
                {subscription && subscription !== 'null' && (
                  <div
                    className={styles.badgesPro}
                    style={
                      subscription === 'ultimate'
                        ? { width: '65px', backgroundColor: '#FF8B49' }
                        : subscription === 'premium'
                          ? { width: '60px', backgroundColor: '#FFAB7B' }
                          : // : subscription === 'standard'
                            //   ? { width: '50px', backgroundColor: '#FFC65A' }
                            {}
                    }
                  >
                    {subscription !== 'standard' && <p>PRO</p>}
                    {(subscription === 'premium' ||
                      subscription === 'ultimate') && (
                      <FontAwesomeIcon
                        style={{ marginLeft: '4px' }}
                        icon={faPlusCircle}
                      />
                    )}
                    {subscription === 'ultimate' && (
                      <FontAwesomeIcon
                        style={{ marginLeft: '-4px' }}
                        icon={faPlusCircle}
                      />
                    )}
                  </div>
                )}
              </div>
              <p>{statut}</p>
              <p style={{ color: colorGreen }}>
                Connecté il y'a : {userConnect.split(' ')[0]}{' '}
                <span>{userConnect.split(' ')[1]}</span>
              </p>
            </div>
          </div>

          <div className={styles.presentation}>
            {presentation !== undefined && presentation !== '' ? (
              <div>
                {presentation.substring(0, 196)}
                {presentation.length > 196 ? <span>...</span> : ''}
              </div>
            ) : (
              'Aucun contenu dans la présentation'
            )}
          </div>

          <div className={styles.viewEfavoris}>
            <div className={styles.iconeContainer}>
              <p className={styles.icone}>
                <FontAwesomeIcon icon={faEye} />
              </p>
              <p>
                {abreviationNombre(views)} <span>fois</span>
              </p>
            </div>

            <div className={styles.iconeContainer}>
              <p className={styles.icone}>
                <FontAwesomeIcon icon={faHeart} />
              </p>
              <p>
                {abreviationNombre(inFavorize)} <span>fois</span>
              </p>
            </div>
          </div>
        </div>
      </Link>
    </>
  )
}

export default CardUserProfile
