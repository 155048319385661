import React from 'react'

function CommentCaMarche() {
  return (
    <>
        <h2 className='titlePage'>Comment ça marche ?</h2>
    </>
  )
}

export default CommentCaMarche