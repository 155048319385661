import styles from './allProfiles.module.scss'
import CardUserProfile from '../../components/CardUserProfile/CardUserProfile'
import { useEffect, useMemo, useState } from 'react'
import Cookies from 'js-cookie'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAngleRight,
  faFilter,
  faSearch,
} from '@fortawesome/free-solid-svg-icons'
import GifLoading from '../../components/GifLoading/GifLoading'
import GifFecthLoad from '../../components/GifFecthLoad/GifFecthLoad'
import ComponentAuthRequired from '../../middlewares/ComponentAuthRequired/ComponentAuthRequired'
import { Link } from 'react-router-dom'

function AllProfiles() {
  const [data, setData] = useState([])
  const [page, setPage] = useState(1)
  const [pageSize] = useState(10)
  const [loading, setLoading] = useState('fecthLoad')
  const [hasMore, setHasMore] = useState(false)
  const [fecthUrl, setFecthUrl] = useState(
    `${process.env.REACT_APP_API_URL}/user/get-users?filter&page=1&pageSize=10`,
  )

  // les constante pour gerer la recherche et filtre des profils
  const [searchPseudo, setSearchPseudo] = useState('')
  const [filtreProfil, setFiltreProfil] = useState('')
  const [filtreViews, setFiltreViews] = useState('')
  const [filtreInFavorize, setFiltreInFavorize] = useState('')

  //   const [filtreIdentity, setFiltreIdentity] = useState(false)
  //   const [filtreInsta, setFiltreInsta] = useState(false)
  //   const [filtreYoutube, setFiltreYoutube] = useState(false)
  //   const [filtreSnap, setFiltreSnap] = useState(false)
  //   const [filtreX, setFiltreX] = useState(false)
  //   const [filtreTiktok, setFiltreTiktok] = useState(false)

  // les fonctions pour gerer les valeurs recherche et filtre des profils
  const SearchPseudoFunction = (e) => {
    if (e.target.value !== '' && e.target.value !== null) {
      setSearchPseudo(`&pseudo=${e.target.value}`)
    } else {
      setSearchPseudo('')
    }
    setPage(1)
  }

  const FiltreParProfil = (e) => {
    if (e.target.value !== 'Tous') {
      setFiltreProfil(`&profil=${e.target.value}`)
    } else {
      setFiltreProfil('')
    }
  }

  const FiltreParViews = (e) => {
    if (e.target.value !== 'Tous') {
      setFiltreViews(`&minView=${e.target.value}`)
    } else {
      setFiltreViews('')
    }
  }

  // Filtre par verification
  //   const FiltreParIdentityVerify = () => {
  //     setFiltreIdentity(!filtreIdentity)
  //   }

  //   const FiltreParInstaVerify = () => {
  //     setFiltreInsta(!filtreInsta)
  //   }

  //   const FiltreParYoutubeVerify = () => {
  //     setFiltreYoutube(!filtreYoutube)
  //   }

  //   const FiltreParSnapVerify = () => {
  //     setFiltreSnap(!filtreSnap)
  //   }

  //   const FiltreParXVerify = () => {
  //     setFiltreX(!filtreX)
  //   }

  //   const FiltreParTiktokVerify = () => {
  //     setFiltreTiktok(!filtreTiktok)
  //   }

  const FiltreParinFavourite = (e) => {
    if (e.target.value !== 'Tous') {
      setFiltreInFavorize(`&minFavourite=${e.target.value}`)
    } else {
      setFiltreInFavorize('')
    }
  }

  // function de création du lien de la requette de recherche ou filtre
  const NewUrlFecth = (e, onlyProfil = null) => {
    if (e) {
      e.preventDefault()
    }
    setLoading('filtre')
    let newUrl = `${process.env.REACT_APP_API_URL}/user/get-users?filter`
    if (searchPseudo !== '') {
      newUrl = `${newUrl}${searchPseudo}`
    }
    if (onlyProfil || filtreProfil !== '') {
      // on effectue le filtre de onlyProfil ici lors du filtre depuis la LeftContent sur les grand ecran.
      newUrl = `${newUrl}${onlyProfil ? onlyProfil : filtreProfil}`
    }
    if (filtreViews !== '') {
      newUrl = `${newUrl}${filtreViews}`
    }
    if (filtreInFavorize !== '') {
      newUrl = `${newUrl}${filtreInFavorize}`
    }
    // if (filtreIdentity === true) {
    //   newUrl = `${newUrl}${`&verifyIdentity=${filtreIdentity}`}`
    // }
    // if (filtreInsta === true) {
    //   newUrl = `${newUrl}${`&instaVerify=${filtreInsta}`}`
    // }
    // if (filtreYoutube === true) {
    //   newUrl = `${newUrl}${`&youtubeVerify=${filtreYoutube}`}`
    // }
    // if (filtreX === true) {
    //   newUrl = `${newUrl}${`&twitterVerify=${filtreX}`}`
    // }
    // if (filtreSnap === true) {
    //   newUrl = `${newUrl}${`&snapVerify=${filtreSnap}`}`
    // }
    // if (filtreTiktok === true) {
    //   newUrl = `${newUrl}${`&tiktokVerify=${filtreTiktok}`}`
    // }

    setTimeout(() => {
      setLoading('')
      setFecthUrl(`${newUrl}&page=1&pageSize=10`)
      setPage(1)
      setShowFilter(false)
    }, 300)
  }

  // Reinitialisation du filtre
  const ResetFilter = (e) => {
    setLoading('filtre')
    setTimeout(() => {
      setLoading('')
      e.preventDefault()
      setPage(1)
      setShowFilter(false)
      setFiltreProfil('')
      setFiltreViews('')
      setFiltreInFavorize('')
      //   setFiltreIdentity(false)
      //   setFiltreInsta(false)
      //   setFiltreYoutube(false)
      //   setFiltreSnap(false)
      //   setFiltreX(false)
      //   setFiltreTiktok(false)
      const newUrl = `${process.env.REACT_APP_API_URL}/user/get-users?filter`
      setFecthUrl(`${newUrl}&page=1&pageSize=10`)
    }, 2000)
  }

  // constante et function pour gerer l'affichage du menu de filtre
  const [showFilter, setShowFilter] = useState(false)
  const displayFilterContainer = () => {
    setShowFilter(!showFilter)
  }

  // On englobe resquestOptions dans useMemo pour eviter de le recalculer si tokenLog ne change pas
  const tokenLog = Cookies.get('_influencapp_tk_log')
  const resquestOptions = useMemo(
    () => ({
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${tokenLog}`,
      },
    }),
    [tokenLog],
  )

  // La fonction pour faire un fecth en recupérant des user en paginant la reponse.
  const loadMoreData = () => {
    setLoading('seeMore')

    fetch(
      `${fecthUrl.split('&page')[0]}&page=${page + 1}&pageSize=${pageSize}`,
      resquestOptions,
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Erreur HTTP : ${response.status}`)
        }
        return response.json()
      })
      .then((result) => {
        const { users, hasMore } = result
        setTimeout(() => {
          setData([...data, ...users])
          setHasMore(hasMore)
          setPage(page + 1)
          setLoading('')
        }, 1000)
      })
      .catch((error) => {
        setLoading('')
        console.log('error lors de la requette fecth', error)
      })
  }

  // Le premier rendu de la pagination
  useEffect(() => {
    setLoading('fecthLoad')
    const fetchInitialData = async () => {
      try {
        const response = await fetch(fecthUrl, resquestOptions)
        if (!response.ok) {
          setLoading('')
          throw new Error(`Erreur HTTP : ${response.status}`)
        }
        const result = await response.json()
        const { users, hasMore } = result
        setData(users)
        setHasMore(hasMore)
        setLoading('')
      } catch (error) {
        setLoading('')
        console.log('error lors de la requette fecth', error)
      }
    }

    fetchInitialData()
  }, [resquestOptions, fecthUrl])

  return (
    <>
      <div className={`fadinAnimation ${styles.allContainer}`}>
        {loading === 'filtre' ? <GifLoading positionDiv='fixed' /> : null}
        <h2 className={`titlePage`}>Tous les profils</h2>
        <div className={styles.widgetContent}>
          <div className={styles.LeftContent}>
            <h3>Filtrer par catégorie</h3>
            <div>
              <p
                onClick={() => {
                  setFiltreProfil('')
                  NewUrlFecth(null, '&profil=')
                }}
                style={
                  filtreProfil.split('&profil=')[1] === undefined ||
                  filtreProfil.split('&profil=')[1] === 'Tous'
                    ? { color: '#E87213' }
                    : {}
                }
              >
                <FontAwesomeIcon icon={faAngleRight} />
                Tous les catégories
              </p>

              <p
                onClick={() => {
                  setFiltreProfil('&profil=Influenceur')
                  NewUrlFecth(null, '&profil=Influenceur')
                }}
                style={
                  filtreProfil.split('&profil=')[1] === 'Influenceur'
                    ? { color: '#E87213' }
                    : {}
                }
              >
                <FontAwesomeIcon icon={faAngleRight} />
                Influenceur
              </p>

              <p
                onClick={() => {
                  setFiltreProfil('&profil=Créateur de contenu')
                  NewUrlFecth(null, '&profil=Créateur de contenu')
                }}
                style={
                  filtreProfil.split('&profil=')[1] === 'Créateur de contenu'
                    ? { color: '#E87213' }
                    : {}
                }
              >
                <FontAwesomeIcon icon={faAngleRight} />
                Créateur de contenu
              </p>

              <p
                onClick={() => {
                  setFiltreProfil('&profil=Youtubeur')
                  NewUrlFecth(null, '&profil=Youtubeur')
                }}
                style={
                  filtreProfil.split('&profil=')[1] === 'Youtubeur'
                    ? { color: '#E87213' }
                    : {}
                }
              >
                <FontAwesomeIcon icon={faAngleRight} />
                Youtubeur
              </p>

              <p
                onClick={() => {
                  setFiltreProfil('&profil=Agence')
                  NewUrlFecth(null, '&profil=Agence')
                }}
                style={
                  filtreProfil.split('&profil=')[1] === 'Agence'
                    ? { color: '#E87213' }
                    : {}
                }
              >
                <FontAwesomeIcon icon={faAngleRight} />
                Agence
              </p>

              <p
                onClick={() => {
                  setFiltreProfil('&profil=Agent')
                  NewUrlFecth(null, '&profil=Agent')
                }}
                style={
                  filtreProfil.split('&profil=')[1] === 'Agent'
                    ? { color: '#E87213' }
                    : {}
                }
              >
                <FontAwesomeIcon icon={faAngleRight} />
                Agent
              </p>

              <p
                onClick={() => {
                  setFiltreProfil('&profil=Marque')
                  NewUrlFecth(null, '&profil=Marque')
                }}
                style={
                  filtreProfil.split('&profil=')[1] === 'Marque'
                    ? { color: '#E87213' }
                    : {}
                }
              >
                <FontAwesomeIcon icon={faAngleRight} />
                Marque
              </p>
            </div>
          </div>

          <div className={styles.RightContent}>
            <div className={styles.searhEfilterContainer}>
              <form onSubmit={NewUrlFecth} className={styles.containerSearch}>
                <div className={styles.champSearchPseudo}>
                  <label htmlFor='pseudoSearch'>
                    <FontAwesomeIcon icon={faSearch} />
                  </label>
                  <input
                    onChange={SearchPseudoFunction}
                    id='pseudoSearch'
                    type='text'
                    placeholder='trouver un profil...'
                  />
                </div>
                <button type='submit' className={styles.btnSubmitSearch}>
                  <FontAwesomeIcon icon={faSearch} />
                </button>
              </form>

              <div
                onClick={displayFilterContainer}
                className={styles.btnFilter}
              >
                <FontAwesomeIcon icon={faFilter} />
              </div>
            </div>

            {showFilter ? (
              <div className={styles.allContainerFilter}>
                <div className={styles.containerFilter}>
                  <div className={styles.queryFilter}>
                    {/* filtre par type de profil */}
                    <div className={`w_inf_1080 ${styles.oneSectionFilter}`}>
                      <h3>Filtrer par profil</h3>
                      <label onClick={FiltreParProfil}>
                        <input
                          type='radio'
                          name='profil'
                          value='Tous'
                          defaultChecked={
                            filtreProfil.split('&profil=')[1] !== undefined
                              ? filtreProfil.split('&profil=')[1] === 'Tous'
                              : true
                          }
                        />
                        Tous
                      </label>{' '}
                      <br />
                      <label onClick={FiltreParProfil}>
                        <input
                          type='radio'
                          name='profil'
                          value='Porteur de projet'
                          defaultChecked={
                            filtreProfil.split('&profil=')[1] ===
                            'Porteur de projet'
                          }
                        />
                        Porteur de projet
                      </label>{' '}
                      <br />
                      <label onClick={FiltreParProfil}>
                        <input
                          type='radio'
                          name='profil'
                          value='Créateur de contenu'
                          defaultChecked={
                            filtreProfil.split('&profil=')[1] ===
                            'Créateur de contenu'
                          }
                        />
                        Créateur de contenu
                      </label>{' '}
                      <br />
                      <label onClick={FiltreParProfil}>
                        <input
                          type='radio'
                          name='profil'
                          value='Influenceur'
                          defaultChecked={
                            filtreProfil.split('&profil=')[1] === 'Influenceur'
                          }
                        />
                        Influenceur
                      </label>{' '}
                      <br />
                      <label onClick={FiltreParProfil}>
                        <input
                          type='radio'
                          name='profil'
                          value='Agent'
                          defaultChecked={
                            filtreProfil.split('&profil=')[1] === 'Agent'
                          }
                        />
                        Agent
                      </label>{' '}
                      <br />
                      <label onClick={FiltreParProfil}>
                        <input
                          type='radio'
                          name='profil'
                          value='Agence'
                          defaultChecked={
                            filtreProfil.split('&profil=')[1] === 'Agence'
                          }
                        />
                        Agence
                      </label>{' '}
                      <br />
                      <label onClick={FiltreParProfil}>
                        <input
                          type='radio'
                          name='profil'
                          value='Acteur'
                          defaultChecked={
                            filtreProfil.split('&profil=')[1] === 'Acteur'
                          }
                        />
                        Acteur
                      </label>{' '}
                      <br />
                      <label onClick={FiltreParProfil}>
                        <input
                          type='radio'
                          name='profil'
                          value='Marque'
                          defaultChecked={
                            filtreProfil.split('&profil=')[1] === 'Marque'
                          }
                        />
                        Marque
                      </label>{' '}
                      <br />
                    </div>

                    {/* filtre par nombre de vues  */}
                    <div className={styles.oneSectionFilter}>
                      <h3>Filtrer par vue</h3>
                      <label onClick={FiltreParViews}>
                        <input
                          type='radio'
                          name='views'
                          value='Tous'
                          defaultChecked={
                            filtreViews.split('&minView=')[1] !== undefined
                              ? filtreViews.split('&minView=')[1] === 'Tous'
                              : true
                          }
                        />
                        Tous
                      </label>{' '}
                      <br />
                      <label onClick={FiltreParViews}>
                        <input
                          type='radio'
                          name='views'
                          value='500'
                          defaultChecked={
                            filtreViews.split('&minView=')[1] === '500'
                          }
                        />
                        Plus de 500 vues
                      </label>{' '}
                      <br />
                      <label onClick={FiltreParViews}>
                        <input
                          type='radio'
                          name='views'
                          value='1000'
                          defaultChecked={
                            filtreViews.split('&minView=')[1] === '1000'
                          }
                        />
                        Plus de 1000 vues
                      </label>{' '}
                      <br />
                      <label onClick={FiltreParViews}>
                        <input
                          type='radio'
                          name='views'
                          value='100000'
                          defaultChecked={
                            filtreViews.split('&minView=')[1] === '100000'
                          }
                        />
                        Plus de 100 000 vues
                      </label>{' '}
                      <br />
                    </div>

                    {/* filtre par nombre de fois ajouté au favoris */}
                    <div className={styles.oneSectionFilter}>
                      <h3>Filtrer par ajout au favoris</h3>
                      <label onClick={FiltreParinFavourite}>
                        <input
                          type='radio'
                          name='inFavorize'
                          value='Tous'
                          defaultChecked={
                            filtreInFavorize.split('&minFavourite=')[1] !==
                            undefined
                              ? filtreInFavorize.split('&minFavourite=')[1] ===
                                'Tous'
                              : true
                          }
                        />
                        Tous
                      </label>{' '}
                      <br />
                      <label onClick={FiltreParinFavourite}>
                        <input
                          type='radio'
                          name='inFavorize'
                          value='500'
                          defaultChecked={
                            filtreInFavorize.split('&minFavourite=')[1] ===
                            '500'
                          }
                        />
                        Plus de 500 ajouts
                      </label>{' '}
                      <br />
                      <label onClick={FiltreParinFavourite}>
                        <input
                          type='radio'
                          name='inFavorize'
                          value='1000'
                          defaultChecked={
                            filtreInFavorize.split('&minFavourite=')[1] ===
                            '1000'
                          }
                        />
                        Plus de 1000 ajouts
                      </label>{' '}
                      <br />
                      <label onClick={FiltreParinFavourite}>
                        <input
                          type='radio'
                          name='inFavorize'
                          value='100000'
                          defaultChecked={
                            filtreInFavorize.split('&minFavourite=')[1] ===
                            '100000'
                          }
                        />
                        Plus de 100 000 ajouts
                      </label>{' '}
                      <br />
                    </div>

                    {/* filtre par verification */}
                    {/* <div className={styles.oneSectionFilter}>
                      <h3>Filtrer par vérification</h3>
                      <label>
                        <input
                          onClick={FiltreParIdentityVerify}
                          type='checkbox'
                          name='identity'
                          defaultChecked={filtreIdentity}
                        />
                        Identité vérifier
                      </label>{' '}
                      <br />
                      <label>
                        <input
                          onClick={FiltreParInstaVerify}
                          type='checkbox'
                          name='insta'
                          defaultChecked={filtreInsta}
                        />
                        Instagram vérifier
                      </label>{' '}
                      <br />
                      <label>
                        <input
                          onClick={FiltreParYoutubeVerify}
                          type='checkbox'
                          name='youtube'
                          defaultChecked={filtreYoutube}
                        />
                        Youtube vérifier
                      </label>{' '}
                      <br />
                      <label>
                        <input
                          onClick={FiltreParSnapVerify}
                          type='checkbox'
                          name='snapchat'
                          defaultChecked={filtreSnap}
                        />
                        Snapchat vérifier
                      </label>{' '}
                      <br />
                      <label>
                        <input
                          onClick={FiltreParTiktokVerify}
                          type='checkbox'
                          name='tiktok'
                          defaultChecked={filtreTiktok}
                        />
                        Tiktok vérifier
                      </label>{' '}
                      <br />
                      <label>
                        <input
                          onClick={FiltreParXVerify}
                          type='checkbox'
                          name='x'
                          defaultChecked={filtreX}
                        />
                        X vérifier
                      </label>{' '}
                      <br />
                    </div> */}
                  </div>

                  <div className={styles.btnFilter}>
                    <div onClick={NewUrlFecth}>Appliquer</div>
                    <div onClick={ResetFilter}>Réinitialiser</div>
                  </div>
                </div>
              </div>
            ) : null}

            {loading === 'fecthLoad' ? (
              <GifFecthLoad positionDiv='fixed' />
            ) : (
              <>
                <div className={styles.cardContainer}>
                  {data.map((user, index) => (
                    <CardUserProfile
                      key={index}
                      pseudo={user?.pseudo}
                      name={user?.name}
                      urlProfil={user?._link_photo_profil?.url}
                      statut={user?.statut}
                      presentation={user?.presentation}
                      views={user?.statistique?.views}
                      inFavorize={user?.statistique?.inFavorize}
                      lastConnect={user?.statistique?.lastConnect}
                      subscription={user?.subscription?.type}
                    />
                  ))}

                  {loading === 'fecthLoad' ? null : data.length > 0 ? null : (
                    <div className={styles.notFoundProfil}>
                      Aucun profil ne correspond à votre recherche.
                    </div>
                  )}
                </div>

                <ComponentAuthRequired
                  valueDefault={
                    <div className={styles.callToSingup}>
                      <p>
                        Inscrivez-vous gratuitement pour voir plus de profils.
                      </p>
                      <Link to='/inscription'>
                        <button>S'inscrire gratuitement</button>
                      </Link>
                    </div>
                  }
                >
                  {hasMore && (
                    <div className={styles.showMoreContainer}>
                      <button onClick={loadMoreData}>
                        {loading === 'seeMore'
                          ? 'Chargement...'
                          : 'Afficher plus'}
                      </button>
                    </div>
                  )}
                </ComponentAuthRequired>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default AllProfiles
