import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import { useEffect, useState } from 'react'

const StatutUserForRegister = ({ children }) => {
  const navigate = useNavigate()
  const user = Cookies.get('_influencapp_statut_select')
  const [statutUser, setStatutUser] = useState(false)

  useEffect(() => {
    if (user + '' === 'undefined') {
      navigate('/inscription')
    } else {
      setStatutUser(true)
    }
  }, [navigate, user])

  return statutUser ? children : ''
}

export default StatutUserForRegister
