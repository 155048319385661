import React from 'react'

function Partenaires() {
  return (
    <>
        <h2 className='titlePage'>Partenaires</h2>
    </>
  )
}

export default Partenaires