import React, { useState } from 'react'
import styles from './inscription.module.scss'
import { Link, useLocation } from 'react-router-dom/dist'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'

function Inscription() {
  const [choix, setChoix] = useState('Influenceur')
  const navigate = useNavigate()

  const now = new Date()
  const expirationTime = new Date(now.getTime() + 30 * 60 * 1000)

  Cookies.set('_influencapp_statut_select', choix, {
    expires: expirationTime,
    sameSite: 'Strict',
  })

  // verification si l'user s'inscrit avec un lien de parrainage
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const sponsor = queryParams.get('referral_link')
  if (sponsor) {
    Cookies.set('_influencapp_sponsor', sponsor, {
      expires: expirationTime,
      sameSite: 'Strict',
    })
  }

  const handleFormSubmit = (e) => {
    e.preventDefault()
    Cookies.set('_influencapp_statut_select', choix, {
      expires: expirationTime,
      sameSite: 'Strict',
    })
    navigate('/form-inscription')
  }

  return (
    <>
      <div className={`fadinAnimation ${styles.allContainer}`}>
        <h2>Inscrivez-vous gratuitement</h2>
        <p>
          Incrivez vous et publier des projets gratuitement pour avoir des
          propositions.
        </p>

        <div className={styles.selectTypeProfile}>
          <h3>Quel est votre profil ?</h3>
          <p>Vous pourrez le modifier plus tard.</p>
        </div>

        <form onSubmit={handleFormSubmit}>
          <input
            onChange={(e) => setChoix(e.target.value)}
            type='radio'
            name='choix'
            id='option1'
            value='Créateur de contenu'
          />
          <label htmlFor='option1'>Créateur de contenu</label>

          <input
            onChange={(e) => setChoix(e.target.value)}
            type='radio'
            name='choix'
            id='option2'
            value='Influenceur'
            defaultChecked
          />
          <label htmlFor='option2'>Influenceur</label>

          <input
            onChange={(e) => setChoix(e.target.value)}
            type='radio'
            name='choix'
            id='option8'
            value='Acteur'
          />
          <label htmlFor='option8'>Acteur</label>

          <input
            onChange={(e) => setChoix(e.target.value)}
            type='radio'
            name='choix'
            id='option3'
            value='Youtubeur'
          />
          <label htmlFor='option3'>Youtubeur</label>

          <input
            onChange={(e) => setChoix(e.target.value)}
            type='radio'
            name='choix'
            id='option4'
            value='Agent'
          />
          <label htmlFor='option4'>Agent</label>

          <input
            onChange={(e) => setChoix(e.target.value)}
            type='radio'
            name='choix'
            id='option5'
            value='Agence'
          />
          <label htmlFor='option5'>Agence</label>

          <input
            onChange={(e) => setChoix(e.target.value)}
            type='radio'
            name='choix'
            id='option6'
            value='Marque'
          />
          <label htmlFor='option6'>Marque</label>

          <input
            onChange={(e) => setChoix(e.target.value)}
            type='radio'
            name='choix'
            id='option7'
            value='Porteur(se) de projet'
          />
          <label htmlFor='option7'>Porteur de projet</label>

          <div
            onChange={(e) => setChoix(e.target.value)}
            className={styles.containerSubmitButton}
          >
            <button type='submit'>Commencer</button>
          </div>
        </form>

        <p className={styles.callToSignin}>
          Vous avez déjà un compte ? <Link to='/connexion'>Connectez-vous</Link>
        </p>
      </div>
    </>
  )
}

export default Inscription
