import { useEffect, useState } from "react";
import styles from "./edit-log-info.module.scss";
import Cookies from "js-cookie";

import { fetchUserInfo }  from "../../apiUtils/fetch-info-user";
import NotifFeedBackFecth from "../../components/NotifFeedBackFecth/NotifFeedBackFecth";
import MonCompteSousMenu from "../../components/MonCompteSousMenu/MonCompteSousMenu";

function FormEditLogInfo() {
    const [loading, setLoading] = useState("");
    const [emailDefault, setEmailDefault] = useState("");
    
    const [pseudo, setPseudo] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [code, setCode] = useState("");
    const [password, setPassword] = useState("");
    const [newpassword, setNewPassword] = useState("");
    const [mailChange, setMailChange] = useState("noChange");

    const [messageSendCode, setMessageSendCode] = useState("");
    const [modifAuthorize, setModifAuthorize] = useState("");
    const [callBackMessage, setCallBackMessage] = useState("");
    const [messageFecth, setMessageFecth] = useState("");

    useEffect(()=>{
        const fetchData = async () => {
            const userData = await fetchUserInfo();

            if (userData?.name) {
                setName(userData.name);
            }
            if (userData?.email) {
                setEmail(userData.email);
                setEmailDefault(userData?.email);
            }
            if (userData?.pseudo) {
                setPseudo(userData?.pseudo);
            }

        };
        fetchData();

    }, [emailDefault]);

    
    const updateName = (e) =>{
        setName(e.target.value);
        setModifAuthorize("");
        setCallBackMessage("");
    }

    const updateEmail = (e) =>{
        setEmail(e.target.value);
        setModifAuthorize("");
        setMessageSendCode("");
        setCallBackMessage("");

        if(emailDefault !== e.target.value){
            setMailChange("change");
        } else if (emailDefault === e.target.value){
            setMailChange("noChange");
        }
    }

    const updateCode = (e) =>{
        setCode(e.target.value);
        setModifAuthorize("");
        setCallBackMessage("");
    }

    const updatePassword = (e) =>{
        setPassword(e.target.value);
        setModifAuthorize("");
        setCallBackMessage("");
    }

    const updateNewPassword = (e) =>{
        setNewPassword(e.target.value);
        setModifAuthorize("");
        setCallBackMessage("");
    }

    // Function de soumission de la modification
    async function handleSubmit (e){
        e.preventDefault();
        setModifAuthorize("");
        setCallBackMessage("");

        const formData = {
            "name" : name,
            "email": email,
            "code": code,
            "newpassword": newpassword,
            "mail_change": mailChange,
        };
        
        if(password !== newpassword){
            setMessageFecth("Les mots de passe saisis ne sont pas identiques. Veuillez revoir vos saisies.");
            setModifAuthorize("error");
            setCallBackMessage("flex");
        } else {
            setLoading("editPassword");
            try {
                const tokenLog = Cookies.get("_influencapp_tk_log");
                const response = await fetch(`${process.env.REACT_APP_API_URL}/user/update-log-info`, {
                    method: "PUT",
                    headers: { "Content-Type": "application/json", Authorization: `Bearer ${tokenLog}`,},
                    body: JSON.stringify(formData),
                });
                if (response.ok) {
                    setTimeout(()=>{
                        setLoading("");
                        setModifAuthorize(true);
                        setMessageFecth("Mise à jour effectué avec succès.");
                        setCallBackMessage("flex");
                        setEmailDefault(email);
                        setMailChange("noChange")
    
                        setPassword("");
                        setNewPassword("");
                        setCode("");
                        setMessageSendCode("");
                    }, 2000)
                } else {
                    setTimeout(()=>{
                        setLoading("");
                        setMessageFecth("Echec de modification, vérifier vos informations saisies et réessayer.");
                        setModifAuthorize("error");
                        setCallBackMessage("flex");
                    }, 2000)
                }
            } catch (error) {
                setLoading("");
                setMessageFecth("Oups, une erreur s'est produite, veuillez réessayer plus tard.");
                setCallBackMessage("flex");
                setModifAuthorize("error");
                console.error("Erreur lors de la requête fetch :", error);
            }
        }
    }

    // Envoi du code de verification à l'adresse e-mail entrez
    const sendCodeVerif = async () =>{
        const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if(regex.test(email)){
            if(emailDefault !== email){
                setLoading("sendCodeVerif");
                try {
                    const response = await fetch(`${process.env.REACT_APP_API_URL}/verification/send-mail`, {
                    method: "POST",
                    headers: {"Content-Type": "application/json"},
                    body: JSON.stringify({email: email, context: "editMail"}),
                    });
                    if (response.ok) {
                        setTimeout(async ()=>{
                            setLoading("");
                            const res = await response.json();
                            if(res.sendCode){
                            setMessageSendCode(true);
                            }
                        }, 2000)
                    } else {
                        setTimeout(async ()=>{
                            setLoading("")
                            const res = await response.json();
                            if(res.user_mail_exist){
                                setMessageSendCode(false);
                            } else{
                                setMessageSendCode("error");
                            }
                        }, 2000)
                    }
                } catch (error) {
                    setLoading("");
                    setMessageSendCode("errorFetch");
                    console.error("Erreur lors de la requête fetch :", error);
                }
            }
        } else {
            setMessageSendCode("error");
        }
    }

    return (
        <>
            <div className={`fadinAnimation ${styles.allContainer}`}>
                <h2 className={`titlePage`}>Modifier vos informations de connexion</h2>

                <div className={`${styles.contentPage}`}>
                    <div className={`w_sp_800`}>
                        <MonCompteSousMenu pseudo={pseudo}/>
                    </div>

                    <form onSubmit={handleSubmit} className={styles.formContainer}>
                        <div className={styles.InputPseudo}>
                            <h3>Pseudo (Obligatoire)</h3>
                            <div>{pseudo}</div>
                        </div>

                        <div>
                            <h3>Nom complet</h3>
                            <input name="name" value={name} minLength={2} onChange={updateName} type="text"/>
                        </div>

                        <div className={styles.emailContainer}>
                            <div>
                                <h3>Adresse e-mail</h3>
                                <input name="email" value={email} onChange={updateEmail} type="email" required/>

                                <div className={styles.callbackMessageContainer}>
                                    {messageSendCode === true ? <p className={styles.succes}>Code envoyé avec succès.</p> : messageSendCode === false ? <p className={styles.failed}>Oups, code non envoyé, un compte existe déjà avec cette adresse e-mail.</p> : messageSendCode === "error" ? <p className={styles.failed}>Erreur, verifier votre saisie et réessayer.</p> : messageSendCode === "errorFetch" ? <p className={styles.failed}>Oups, une erreur s'est produite veuillez réessayer dans quelque minutes.</p> : ""}
                                </div>

                                <div className={styles.btnSendCode}>
                                    <p onClick={sendCodeVerif}>{loading === "sendCodeVerif" ? "Envoi du code..." : "Envoyer le code"}</p>
                                </div>
                            </div>

                        </div>

                        <div>
                            <h3>Code de vérification <span>(obligatoire si vous changez d'adresse email)</span></h3>
                            <input name="code" value={code} minLength={4} maxLength={6} onChange={updateCode} type="phone"/>
                        </div>

                        <div>
                            <h3>Nouveau mot de passe</h3>
                            <input name="password" value={password} minLength={6} onChange={updatePassword} type="password"/>
                        </div>
                        <div>
                            <h3>Saisissez à nouveau le nouveau mot de passe</h3>
                            <input name="newpassword" value={newpassword} minLength={6} onChange={updateNewPassword} type="password" />
                        </div>

                        <div className={styles.buttonContainer}>
                            <button type="submit">{loading === "editPassword" ? "Enregistrement..." : "Mettre à jour"}</button>
                        </div>
                    </form>
                </div>

            </div>
            
            <NotifFeedBackFecth modifAuthorizeValue={modifAuthorize} callBackMessageValue={callBackMessage} messageFecthValue={messageFecth} />
        </>
    )
}

export default FormEditLogInfo;
