import { useEffect, useState } from "react";
import styles from "./index.module.scss";
import YouTube from 'react-youtube';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmarkCircle } from "@fortawesome/free-solid-svg-icons";

function Medias({ medias }) {
    const regex = /^galeries\/galeries_/
    const [displayMedia, setDisplayMedia] = useState(false);
    const [displaySlider, setDisplaySlider] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(()=>{
        const regex = /^galeries\/galeries_/
        for(let i = 1; i < 4 ; i++){
            if(regex.test(medias?.[`image_galerie_${i}`]?.url)){
                setDisplayMedia(true);
                return;
            }
        }
    }, [medias]);

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: currentIndex,
        afterChange: (index) => setCurrentIndex(index),
    };

    const showSlider = () =>{
        setDisplaySlider(!displaySlider);   
    }

    return (
        <>
            {displayMedia ?
            <div className={`fadinAnimation containerStyles ${styles.medias}`}>
                <h2>Médias</h2>
                
                <div className={styles.imageContainer}>
                    {regex.test(medias?.image_galerie_1?.url) ? <div onClick={()=>setCurrentIndex(0)}><img onClick={showSlider} src={`${process.env.REACT_APP_URL_BASE_IMAGE}${medias.image_galerie_1.url}`} alt={"Galerie pic 1"} /></div> : null }
                    {regex.test(medias?.image_galerie_2?.url) ? <div onClick={()=>setCurrentIndex(1)}><img onClick={showSlider} src={`${process.env.REACT_APP_URL_BASE_IMAGE}${medias.image_galerie_2.url}`} alt={"Galerie pic 2"} /></div> : null }
                </div>

                {medias.video_url && <div className={styles.videoContainer}><YouTube videoId={medias.video_url} /></div>}
            </div>
            : null }

            {
                displaySlider &&
                <div className={styles.sliderMediasContainer}>
                    <div className={styles.btnCloseSlider}>
                        <FontAwesomeIcon onClick={showSlider} icon={faXmarkCircle}/>
                    </div>

                    <Slider {...settings}>
                        {regex.test(medias?.image_galerie_1?.url) ? <div><img src={`${process.env.REACT_APP_URL_BASE_IMAGE}${medias.image_galerie_1.url}`} alt={"Galerie pic 1"} /></div> : null }
                        {regex.test(medias?.image_galerie_2?.url) ? <div><img src={`${process.env.REACT_APP_URL_BASE_IMAGE}${medias.image_galerie_2.url}`} alt={"Galerie pic 2"} /></div> : null}
                    </Slider>
                </div>
            } 
        </>
    )
}

export default Medias;
