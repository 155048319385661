import React, { useState } from 'react';
import styles from "./FormSendOffer.module.scss";
import NotifFeedBackFecth from '../../NotifFeedBackFecth/NotifFeedBackFecth';
import Cookies from 'js-cookie';
import { useParams } from 'react-router-dom';

function FormSendOffer({ displayFrom, offerSuccesSent }) {
    const { id } = useParams();
    const [selectedFile, setSelectedFile] = useState("");
    const [amount, setAmount] = useState("");
    const [taxe, setTaxe] = useState("€ TTC");
    const [message, setMessage] = useState("");

    const [modifAuthorize, setModifAuthorize] = useState("");
    const [messageFecth, setMessageFecth] = useState("");
    const [callBackMessage, setCallBackMessage] = useState("");
    const [loading, setLoading] = useState("");
    const [loadingFile, setLoadingFile] = useState(false);

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        const tailleMaxAutorisee = 10 * 1024 * 1024;

        if (selectedFile) {
            const allowedFormats = [
                "image/jpeg",
                "image/jpg",
                "image/png",
                "image/webp",
                "image/svg+xml",
                "application/pdf",
                "application/msword", // .doc
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // .docx
                "application/vnd.ms-excel", // .xls
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
            ];

            if (allowedFormats.includes(selectedFile.type) && selectedFile.size <= tailleMaxAutorisee) {
                const reader = new FileReader();
                reader.onload = () => {
                    setSelectedFile(selectedFile);
                };
                reader.readAsDataURL(selectedFile);
                setModifAuthorize("");
                setCallBackMessage("");
                setMessageFecth("");
            } else {
                setModifAuthorize("error");
                setCallBackMessage("flex");
                setMessageFecth("Erreur, vérifier le format et la taille de votre image et réessayer.");
                e.target.value = '';

                resetFeeBack();
            }
        }
    };

    function resetFeeBack(){
        setTimeout(()=>{
            setModifAuthorize("");
            setCallBackMessage("");
            setMessageFecth("");
        }, 6000);
    }

    const handleSubmit = async (e) =>{
        e.preventDefault();
        setLoading("addNewOffer");

        if(amount && amount !== " " && amount >= 50 && message && message !== " " && taxe && taxe !== " "){
            const currentDate = new Date();
            const formData = new FormData();
            formData.append("file", selectedFile);
            formData.append("amount", `${amount} ${taxe}`);
            formData.append("messageOffre", message);
            formData.append("idProject", id);
            formData.append("dateSend", currentDate.toISOString());

            if(selectedFile){
                setLoadingFile(true);
            }

            try {
                const tokenLog = Cookies.get("_influencapp_tk_log");
                const response = await fetch(`${process.env.REACT_APP_API_URL}/offer/add-new`, {
                    method: "POST",
                    headers: { Authorization: `Bearer ${tokenLog}` },
                    body: formData,
                });

                if(response.ok){
                    setTimeout(()=>{
                        setModifAuthorize(true);
                        setCallBackMessage("flex");
                        setMessageFecth("Offre envoyée avec succès.");
                        setLoading("");
                        setLoadingFile("");
                        
                        resetFeeBack();
                        setTimeout(()=>{
                            offerSuccesSent();
                            displayFrom();
                        }, 1000);
                    }, 2000);
                } else{
                    setTimeout(()=>{
                        setLoading("");
                        setLoadingFile("");
                        setModifAuthorize("error");
                        setCallBackMessage("flex");
                        setMessageFecth("Une erreur s'est produite, vérifiez vos saisie et réessayer.");

                        resetFeeBack();
                    }, 2000);
                }
            } catch (error) {
                setLoading("");
                setLoadingFile("");
                console.log("Error lors de la requette fecth", error);
                setModifAuthorize("error");
                setCallBackMessage("flex");
                setMessageFecth("Oups, une erreur s'est produite, veuillez réessayer ultérieurement.");

                resetFeeBack();
            }

        } else{
            setLoading("");
            setLoadingFile("");
            setModifAuthorize("error");
            setCallBackMessage("flex");
            setMessageFecth("Erreur, veuillez remplir correctement tout les champs obligatoire.");

            resetFeeBack();
        }
    }
    
    return (
        <>
            <div className={styles.allContainer}>
                <h3>Faire une offre sur ce projet</h3>
                <form onSubmit={handleSubmit} className='fromAddOffer'>
                    <label htmlFor="amount">Mon offre <span>(obligatoire)</span></label> <br />
                    <div className={styles.amountContainer}>
                        <input 
                            onChange={(e)=>setAmount(e.target.value)}
                            min={50} 
                            id="amount" 
                            type="number" 
                            name="amount" 
                            placeholder="montant..." 
                            required />
                        <select name="taxe" defaultValue={`€ TTC`}>
                            <option onClick={(e)=>setTaxe(e.target.value)} value="€ TTC">€ TTC</option>
                            <option onClick={(e)=>setTaxe(e.target.value)} value="€ HT">€ HT</option>
                        </select>
                    </div>
                    <p>( min. 50€, montant privé, visible uniquement par l’auteur du projet )</p>

                    <label htmlFor="message">Message privé <span>(obligatoire)</span></label> <br />
                    <textarea
                        onChange={(e)=>setMessage(e.target.value)} 
                        name="message" 
                        id="message" 
                        cols="30" 
                        rows="10" 
                        placeholder="Votre message privé..." 
                        required>
                    </textarea>

                    <div className={styles.inputFile}>
                        <label htmlFor="fileInput">Parcourir</label>
                        <p title={selectedFile.name}>{selectedFile.name ? selectedFile.name : "Aucun fichier selectionné." }</p>
                        <input style={{ display: 'none' }} onChange={handleFileChange} id="fileInput" type="file" />
                    </div>
                    
                    {loadingFile && <p className={styles.loadingFileMessage}>Chargement du fichier joint...</p>}
                    <div className={styles.caracteristiqueFile}><span>Formats :</span> .jpg, .jpeg, .png, .pdf, .doc, .xls</div>
                    <div className={styles.caracteristiqueFile}><span>Taille maximum :</span> 10Mo</div>

                    <div className={styles.btnContainer}>
                        <button onClick={handleSubmit} type="submit">{loading === "addNewOffer" ? "Envoi encours..." : "Envoyer l'offre"}</button>
                        <button onClick={displayFrom}>Annuler</button>
                    </div>
                </form>
            </div>
            <NotifFeedBackFecth modifAuthorizeValue={modifAuthorize} callBackMessageValue={callBackMessage} messageFecthValue={messageFecth} />
        </>
    )
}

export default FormSendOffer