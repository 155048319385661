import Cookies from "js-cookie";

export async function fetchUserInfo() {
  try {
    const tokenLog = Cookies.get("_influencapp_tk_log");
    const response = await fetch(`${process.env.REACT_APP_API_URL}/user/info-user`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${tokenLog}`,
      },
    });
    if (response.ok) {
      const res = await response.json();
      return res.user;
    } else {
      console.error("Erreur lors de la requête fetch");
      return null;
    }
  } catch (error) {
    console.error("Erreur lors de la requête fetch :", error);
    return null;
  }
}