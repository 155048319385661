import React, { useEffect, useState } from 'react'
import styles from './MySubscription.module.scss'
import AbonnementEncours from '../../components/PageMonAbonnement/AbonnementEncours/AbonnementEncours'
import PacksAbonnement from '../../components/PageMonAbonnement/PacksAbonnement/PacksAbonnement'
import ComponentAuthRequired from '../../middlewares/ComponentAuthRequired/ComponentAuthRequired'
import Cookies from 'js-cookie'
import { Link } from 'react-router-dom'

function MySubscription() {
  const [refresh, setRefresh] = useState(false)
  const [subscription, setSubscription] = useState('')
  const token = Cookies.get(`_influencapp_tk_log`)

  const refreshPage = () => {
    setRefresh(!refresh)
  }

  useEffect(() => {
    const fecthSubscription = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/user/user-subscription`,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        )
        if (response.ok) {
          const result = await response.json()
          setSubscription(result?.subscription)
        }
      } catch (error) {
        console.log('Error lors de la requtte fetch', error)
      }
    }
    fecthSubscription()
  }, [refresh, token])

  return (
    <>
      <h2 className='titlePage'>
        <ComponentAuthRequired valueDefault={'Nos tarifications'}>
          {/* Mon abonnement */}
          Packs influencapp
        </ComponentAuthRequired>
      </h2>

      <div className={styles.pageContent}>
        <AbonnementEncours userSubscription={subscription} />

        <ComponentAuthRequired
          valueDefault={
            <div className={styles.userNotAuth}>
              <h3>Porteurs de projets</h3>
              <p>
                L'utilisation de influencapp.com est gratuite pour les porteurs
                de projet, la publication d'un projet est gratuite.{' '}
                <Link to='/inscription'>Inscrivez-vous</Link> gratuitement pour
                déposer une annonce.
              </p>
            </div>
          }
        ></ComponentAuthRequired>

        <ComponentAuthRequired
          valueDefault={
            <div className={styles.userNotAuth}>
              <h3>Pour envoyer des offres</h3>
              {/* <p>La création d'un compte est gratuite mais il faut s'abonner à partir de 30,99 euros HT par mois pour pouvoir participer aux projets et envoyer des offres aux clients.</p> */}
              <p>
                La création d'un compte est gratuite mais il faut acheter des
                crédits à partir de 30,99 euros HT pour pouvoir participer aux
                projets et envoyer des offres aux clients.
              </p>
            </div>
          }
        ></ComponentAuthRequired>

        <PacksAbonnement newSubscription={refreshPage} />
      </div>
    </>
  )
}

export default MySubscription
