import React from 'react'
import styles from './PartialInfoProfileUser.module.scss'
import { Link } from 'react-router-dom'

function PartialInfoProfileUser({ pseudo, margin }) {
  return (
    <>
      <div
        style={{ [margin]: '-15px' }}
        className={`fadinAnimation containerStyles ${styles.AllContainer}`}
      >
        <p>Accédez au profil complet de {pseudo}.</p>
        <p>
          Créez un compte en 2 minutes et accédez au profil complet de tous les
          utilisateurs.
        </p>
        <div>
          <Link to='/inscription'>
            <div>S'inscrire gratuitement</div>
          </Link>
        </div>
      </div>
    </>
  )
}

export default PartialInfoProfileUser
