import React from 'react'

function MentionsLegales() {
  return (
    <>
        <h2 className='titlePage'>Mentions légales</h2>
    </>
  )
}

export default MentionsLegales