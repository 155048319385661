import styles from './CardOffer.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart, faEye, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom/dist'

function abreviationNombre(n) {
  if (n < 1000) {
    return n.toString()
  } else if (n < 1000000) {
    return (n / 1000).toFixed(1) + 'k'
  } else if (n < 1000000000) {
    return (n / 1000000).toFixed(1) + 'M'
  } else if (n < 1000000000000) {
    return (n / 1000000000).toFixed(1) + 'B'
  } else {
    return n.toString()
  }
}

function CardOffer({
  idOffer,
  idProject,
  pseudo,
  name,
  statut,
  dateCrea,
  dateSendOffer,
  urlProfil,
  amount,
  views,
  inFavorize,
  offerSelect,
  subscription,
}) {
  return (
    <div className={styles.fullContent}>
      <div
        key={pseudo}
        className={`cardBackground ${styles.allContainer}`}
        style={
          offerSelect
            ? { border: '2px solid green', paddingBottom: '35px' }
            : null
        }
      >
        <p className={styles.dateCreaOffer}>
          Offre déposée le : {dateSendOffer}
        </p>
        <div className={styles.firstSect_AmountContaiter}>
          <div className={styles.firstSection}>
            <Link className={styles.linkCard} to={`/profile/${pseudo}`}>
              <p className={styles.imagProfile}>
                {urlProfil !== undefined ? (
                  <img
                    src={`${process.env.REACT_APP_URL_BASE_IMAGE}${urlProfil}`}
                    alt={`Profile avatar ${urlProfil}`}
                  />
                ) : null}
              </p>
            </Link>
            <div>
              <div className={styles.profileNameContainer}>
                <Link className={styles.linkCard} to={`/profile/${pseudo}`}>
                  <h3>{name ? `${name}` : pseudo}</h3>
                </Link>
                {subscription && subscription !== 'null' && (
                  <div
                    className={styles.badgesPro}
                    style={
                      subscription === 'ultimate'
                        ? { width: '65px', backgroundColor: '#FF8B49' }
                        : subscription === 'premium'
                          ? { width: '60px', backgroundColor: '#FFAB7B' }
                          : // : subscription === 'standard'
                            //   ? { width: '50px', backgroundColor: '#FFC65A' }
                            {}
                    }
                  >
                    {subscription !== 'standard' && <p>PRO</p>}
                    {(subscription === 'premium' ||
                      subscription === 'ultimate') && (
                      <FontAwesomeIcon
                        style={{ marginLeft: '4px' }}
                        icon={faPlusCircle}
                      />
                    )}
                    {subscription === 'ultimate' && (
                      <FontAwesomeIcon
                        style={{ marginLeft: '-4px' }}
                        icon={faPlusCircle}
                      />
                    )}
                  </div>
                )}
              </div>
              <p>{statut}</p>
              <p>
                Membre depuis : <span>{dateCrea}</span>
              </p>
            </div>
          </div>

          <div className={styles.amount}>
            <p>
              Montant de l'offre :
              {amount !== 'null' ? <span>{amount}</span> : <span>masqué</span>}
            </p>
          </div>
        </div>

        <div className={styles.viewEfavoris}>
          <div className={styles.iconeContainer}>
            <p className={styles.icone}>
              <FontAwesomeIcon icon={faEye} />
            </p>
            <p>
              {abreviationNombre(views)} <span>fois</span>
            </p>
          </div>

          <div className={styles.iconeContainer}>
            <p className={styles.icone}>
              <FontAwesomeIcon icon={faHeart} />
            </p>
            <p>
              {abreviationNombre(inFavorize)} <span>fois</span>
            </p>
          </div>
        </div>
      </div>

      {offerSelect && (
        <div className={styles.messageOfferSelect}>
          Offre sélectionnée par le porteur de projet
        </div>
      )}

      {/* boutton voir l'offre  */}
      {amount !== 'null' && (
        <div className={styles.viewOfferContainer}>
          <Link to={`/offer/${idOffer}/${idProject}`}>
            <button>Voir l'offre</button>
          </Link>
        </div>
      )}
    </div>
  )
}

export default CardOffer
