import React, { useState } from 'react'
import styles from './Header.module.scss'
import { Link } from 'react-router-dom'
import ComponentAuthRequired from '../../middlewares/ComponentAuthRequired/ComponentAuthRequired'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCirclePlus,
  faBriefcase,
  faUmbrella,
  faHeart,
  faUserPen,
  faLock,
  faCartShopping,
  faRightFromBracket,
  // faShareNodes,
  faUsers,
  faEnvelope,
  faCircleUser,
  faFolderOpen,
  faTicket,
  // faCalendarDays,
  faRankingStar,
  faPlusCircle,
  faCoins,
  faBullhorn,
} from '@fortawesome/free-solid-svg-icons'
import { MessagesUser } from '../../apiUtils/Messages'
import Cookies from 'js-cookie'
import { useNavigate } from 'react-router-dom/dist'
import ConfirmationRequired from '../ConfirmationRequired/ConfirmationRequired'
import GenerateReferralLink from '../GenerateReferralLink/GenerateReferralLink'
import InfoForDisableLink from '../InfoForDisableLink/InfoForDisableLink'

// function formatDate(dateFormat) {
//   const options = {
//     day: '2-digit',
//     month: '2-digit',
//     year: 'numeric',
//     hour: '2-digit',
//     minute: '2-digit',
//   }
//   const date = new Date(dateFormat)
//   const formattedDate = new Intl.DateTimeFormat('fr-FR', options).format(date)
//   return formattedDate.replace('/', '/')
// }

const value = await MessagesUser()
sessionStorage.setItem('notifMessage', value)

export default function Header() {
  const userPseudo = Cookies.get(`_influencapp_user_pseudo`)
  const token = Cookies.get(`_influencapp_tk_log`)
  const [sousMenu, setSousMenu] = useState('')
  const [subscription, setSubscription] = useState('')
  const [coins, setCoins] = useState(0)
  const [confirmationContainer, setConfirmationContainer] = useState(false)
  const [referralLink, setReferralLink] = useState(false)
  const [disableLink, setDisableLink] = useState(false)

  let messageNotRead = sessionStorage.getItem('notifMessage')

  const displaySousMenu = () => {
    // if(sousMenu === false || sousMenu === ""){
    setSousMenu(!sousMenu)
    // } else{
    //   setSousMenu(false);
    // }
  }

  const navigate = useNavigate()
  const deconnexion = () => {
    setConfirmationContainer(!confirmationContainer)
    const cookieinfluencapp = Cookies.get()
    for (const nameCookie in cookieinfluencapp) {
      if (nameCookie.startsWith('_influencapp')) {
        Cookies.remove(nameCookie)
      }
    }
    navigate('/connexion')
  }

  // On oblige le renvoi de la requette à chaque clique sur le menu burger pour avoir la mis à jour de l'etat de l'abonnement de l'user
  const fetchSubscrition = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/user/user-subscription`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Cache-Control': 'no-cache',
          },
        },
      )
      if (response.ok) {
        const result = await response.json()
        setSubscription(result?.subscription)
      } else {
        setSubscription('')
      }
    } catch (error) {
      console.log('Error lors de la requette fecth', error)
    }
  }

  const fecthCoinsUser = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/user/user-coins`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Cache-Control': 'no-cache',
          },
        },
      )
      if (response.ok) {
        const result = await response.json()
        setCoins(result?.coins)
      } else {
        setCoins(0)
      }
    } catch (error) {
      console.log('Error lors de la requette fecth', error)
    }
  }

  return (
    <div className={styles.HeaderLogContainer}>
      <h1 className={styles.logo}>
        <Link to='/'>
          Influenc<span>APP</span>
        </Link>
      </h1>

      <div className={styles.HeaderSecondContainer}>
        <ComponentAuthRequired
          valueDefault={
            <div className={`w_sup_649 ${styles.LinkHeader}`}>
              <Link to='/connexion'>Se connecter</Link>
              <Link to='/inscription'>S'inscrire gratuitement</Link>
              <ComponentAuthRequired
                valueDefault={
                  <Link to='/connexion'>
                    <p className={`w_sup_969 ${styles.btnAjoutProjet}`}>
                      <FontAwesomeIcon icon={faCirclePlus} /> Déposer un projet
                    </p>
                  </Link>
                }
              >
                <Link to='/create-project'>
                  <p className={`w_sup_969 ${styles.btnAjoutProjet}`}>
                    <FontAwesomeIcon icon={faCirclePlus} /> Déposer un projet
                  </p>
                </Link>
              </ComponentAuthRequired>
            </div>
          }
        >
          <div className={`w_sup_649 ${styles.LinkHeader}`}>
            <p>
              {/* <Link to='/projects'>Projets</Link> */}
              <Link
                onClick={() => setDisableLink(!disableLink)}
                style={{ textDecoration: 'line-through' }}
                to='#'
              >
                Projets
              </Link>
            </p>
            <p>
              {/* <Link to='/all-profiles'>Tous les profils</Link> */}
              <Link
                onClick={() => setDisableLink(!disableLink)}
                style={{ textDecoration: 'line-through' }}
                to='#'
              >
                Tous les profils
              </Link>
            </p>
            <p className={`w_sup_969`}>
              <Link to='/my-projects'>Mes projets</Link>
            </p>
            <Link to='/create-project'>
              <p className={`w_sup_969 ${styles.btnAjoutProjet}`}>
                <FontAwesomeIcon icon={faCirclePlus} /> Déposer un projet
              </p>
            </Link>
          </div>
        </ComponentAuthRequired>

        <ComponentAuthRequired valueDefault={null}>
          <div className={styles.messageIcone}>
            {messageNotRead > 9 ? (
              <p>+9</p>
            ) : messageNotRead > 0 ? (
              <p>{messageNotRead}</p>
            ) : null}
            <Link to='/messages'>
              <FontAwesomeIcon icon={faEnvelope} />
            </Link>
          </div>
        </ComponentAuthRequired>

        <div onClick={displaySousMenu} className={styles.menuBurger}>
          <p
            className={
              sousMenu
                ? `${styles.animationBarTop}`
                : sousMenu === false
                  ? `${styles.BarTopSetAnim}`
                  : ''
            }
          ></p>
          <p
            className={
              sousMenu
                ? `${styles.animationBarBottom}`
                : sousMenu === false
                  ? `${styles.BarBottomSetAnim}`
                  : ''
            }
          ></p>

          {/* ce div permet update la subscription quand on clique dessus  */}
          {!sousMenu && (
            <ComponentAuthRequired valueDefault={null}>
              <div
                onClick={() => {
                  fetchSubscrition()
                  fecthCoinsUser()
                }}
                className={styles.divVoid}
              ></div>
            </ComponentAuthRequired>
          )}
        </div>
      </div>

      {sousMenu ? (
        <ComponentAuthRequired
          valueDefault={
            <>
              <div
                onClick={displaySousMenu}
                className={styles.sousMenuContainer}
              ></div>
              <div className={styles.sousMenu}>
                <div>
                  {/* le sous menu à afficher par defaut */}
                  <p onClick={displaySousMenu}>
                    <Link to='/'>Accueil</Link>
                  </p>
                  <p onClick={displaySousMenu}>
                    <Link to='/connexion'>Se connecter</Link>
                  </p>
                  <p onClick={displaySousMenu}>
                    <Link to='/inscription'>S'inscrire gratuitement</Link>
                  </p>
                  <p onClick={displaySousMenu}>
                    <Link to='/my-subscription'>Nos tarifs</Link>
                  </p>
                </div>
              </div>
            </>
          }
        >
          <div
            onClick={displaySousMenu}
            className={styles.sousMenuContainer}
          ></div>
          <div className={styles.sousMenu}>
            {/* le sous menu à afficher lorsque l'user est connecter */}
            <div className={styles.sousMenuUserLog}>
              <Link to='/create-project'>
                <p
                  className={`w_inf_970 ${styles.btnAjoutProjet}`}
                  onClick={displaySousMenu}
                >
                  <FontAwesomeIcon icon={faCirclePlus} /> Déposer un projet
                </p>
              </Link>
              <p
                className={`w_inf_650`}
                onClick={() => setDisableLink(!disableLink)}
                style={{ textDecoration: 'line-through' }}
              >
                <Link to='#'>
                  <FontAwesomeIcon icon={faBriefcase} />
                  Voir les projets
                </Link>
              </p>
              {/* <p className={`w_inf_650`} onClick={displaySousMenu}>
                <Link to='/projects'>
                  <FontAwesomeIcon icon={faBriefcase} />
                  Voir les projets
                </Link>
              </p> */}

              <p className={`w_inf_970`} onClick={displaySousMenu}>
                <Link to='/my-projects'>
                  <FontAwesomeIcon icon={faUmbrella} />
                  Gérer mes projets
                </Link>
              </p>

              <p
                onClick={() => setDisableLink(!disableLink)}
                style={{ textDecoration: 'line-through' }}
              >
                <Link to='#'>
                  <FontAwesomeIcon icon={faFolderOpen} />
                  Mes offres envoyées
                </Link>
              </p>
              {/* <p onClick={displaySousMenu}>
                <Link to='/offers-send'>
                  <FontAwesomeIcon icon={faFolderOpen} />
                  Mes offres envoyées
                </Link>
              </p> */}
              <p
                className={`w_inf_650`}
                onClick={() => setDisableLink(!disableLink)}
                style={{ textDecoration: 'line-through' }}
              >
                <Link to='#'>
                  <FontAwesomeIcon icon={faUsers} />
                  Tous les profils
                </Link>
              </p>

              {/* <p className={`w_inf_650`} onClick={displaySousMenu}>
                <Link to='/all-profiles'>
                  <FontAwesomeIcon icon={faUsers} />
                  Tous les profils
                </Link>
              </p> */}

              {/* points et parrainage  */}
              <h3>Points & Parrainage</h3>
              <>
                <p>
                  <FontAwesomeIcon icon={faCoins} />
                  Pts restants : <span>{coins}</span>
                </p>

                <p
                  style={{ cursor: 'pointer' }}
                  onClick={() => setReferralLink(!referralLink)}
                >
                  <FontAwesomeIcon icon={faBullhorn} />
                  Mon lien de parrainage
                </p>
              </>

              <h3>Mon pack encours</h3>
              {/* <h3>Mon abonnement</h3> */}
              <div className={styles.subscriptionSection}>
                {subscription ? (
                  <>
                    <div className={styles.typeSubscription}>
                      <p>
                        <FontAwesomeIcon icon={faRankingStar} />
                        {subscription?.type}
                      </p>
                      <div
                        className={styles.badgesPro}
                        style={
                          subscription?.type === 'ultimate'
                            ? { minWidth: '65px', backgroundColor: '#FF8B49' }
                            : subscription?.type === 'premium'
                              ? { minWidth: '60px', backgroundColor: '#FFAB7B' }
                              : // : subscription?.type === 'standard'
                                //   ? {
                                //       minWidth: '50px',
                                //       backgroundColor: '#FFC65A',
                                //     }
                                {}
                        }
                      >
                        {subscription?.type !== 'standard' && (
                          <p
                            style={
                              subscription?.type !== 'standard'
                                ? { marginLeft: '3px' }
                                : {}
                            }
                          >
                            PRO
                          </p>
                        )}
                        {(subscription?.type === 'premium' ||
                          subscription?.type === 'ultimate') && (
                          <FontAwesomeIcon
                            style={{ marginLeft: '0px' }}
                            icon={faPlusCircle}
                          />
                        )}
                        {subscription?.type === 'ultimate' && (
                          <FontAwesomeIcon
                            style={{ marginLeft: '-10px' }}
                            icon={faPlusCircle}
                          />
                        )}
                      </div>
                    </div>
                    <p>
                      <FontAwesomeIcon icon={faTicket} />
                      Crédits : <span>{subscription?.balanceOffer}</span>
                    </p>
                    <p onClick={displaySousMenu}>
                      <Link to='/my-subscription'>
                        <FontAwesomeIcon icon={faCartShopping} />
                        Acheter des crédits
                      </Link>
                    </p>
                    {/* <p>
                      <FontAwesomeIcon icon={faCalendarDays} />
                      Expire :{' '}
                      <span>{formatDate(subscription?.expireDate)}</span>
                    </p> */}
                  </>
                ) : (
                  <p
                    onClick={displaySousMenu}
                    className={styles.typeSubscription}
                  >
                    <Link to='/my-subscription'>
                      <FontAwesomeIcon icon={faRankingStar} />
                      Passer <span>PRO</span>
                    </Link>
                  </p>
                )}
              </div>

              <h3>Mon compte</h3>
              <>
                <p onClick={displaySousMenu}>
                  <Link to={`/profile/${userPseudo}`}>
                    <FontAwesomeIcon icon={faCircleUser} />
                    Voir mon profil
                  </Link>
                </p>
                <p onClick={displaySousMenu}>
                  <Link to='/edit-log-info'>
                    <FontAwesomeIcon icon={faLock} />
                    Informations de connexion
                  </Link>
                </p>
                <p onClick={displaySousMenu}>
                  <Link to='/my-account'>
                    <FontAwesomeIcon icon={faUserPen} />
                    Gérer mon compte
                  </Link>
                </p>
                <p
                  onClick={() => setDisableLink(!disableLink)}
                  style={{ textDecoration: 'line-through' }}
                >
                  <Link to='#'>
                    <FontAwesomeIcon icon={faHeart} />
                    Mes favoris
                  </Link>
                </p>
                {/* <p onClick={displaySousMenu}>
                  <Link to='/my-favourites'>
                    <FontAwesomeIcon icon={faHeart} />
                    Mes favoris
                  </Link>
                </p> */}

                {/* <p onClick={displaySousMenu}>
                  <Link to='/my-social-net'>
                    <FontAwesomeIcon icon={faShareNodes} />
                    Mes réseaux sociaux
                  </Link>
                </p> */}
              </>

              {/* <h3>Mes finances</h3>
              <p onClick={displaySousMenu}>
                <Link to='/my-subscription'>
                  <FontAwesomeIcon icon={faCartShopping} />
                  Mon abonnement
                </Link>
              </p> */}

              <p
                className={styles.btnDeconnexion}
                onClick={() => {
                  setConfirmationContainer(!confirmationContainer)
                  displaySousMenu()
                }}
              >
                <FontAwesomeIcon icon={faRightFromBracket} /> Déconnexion
              </p>
            </div>
          </div>
        </ComponentAuthRequired>
      ) : (
        ''
      )}

      {confirmationContainer && (
        <ConfirmationRequired
          contexte='Confirmez-vous vouloir vous deconnecter ?'
          confirmation={deconnexion}
          reset={() => setConfirmationContainer(!confirmationContainer)}
        />
      )}

      {/* gestion de l'affichage du lien de parrainage */}
      {referralLink && (
        <GenerateReferralLink reset={() => setReferralLink(!referralLink)} />
      )}

      {/* Affichage message page non diponible */}
      {disableLink && (
        <InfoForDisableLink reset={() => setDisableLink(!disableLink)} />
      )}
    </div>
  )
}
