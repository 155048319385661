import { useEffect, useState } from 'react'
import styles from './FormUpdateProfil.module.scss'
import Cookies from 'js-cookie'
import NotifFeedBackFecth from '../../NotifFeedBackFecth/NotifFeedBackFecth'

function FormUpdateProfil({ userData }) {
  const [loading, setLoading] = useState('')
  const [modifAuthorize, setModifAuthorize] = useState('')
  const [messageFecth, setMessageFecth] = useState('')
  const [callBackMessage, setCallBackMessage] = useState('')
  const [userStatut, setUserStatut] = useState('')
  const [presentation, setPresentation] = useState('')
  const [activity, setActivity] = useState('Non défini')
  const [localisation, setLocalisation] = useState('')
  const [phone, setPhone] = useState('')

  useEffect(() => {
    if (userData) {
      if (
        userData.statut !== null &&
        userData.statut !== undefined &&
        userData.statut !== ''
      ) {
        setUserStatut(userData.statut)
      }

      if (
        userData.presentation !== null &&
        userData.presentation !== undefined &&
        userData.presentation !== ''
      ) {
        setPresentation(userData.presentation)
      }

      if (
        userData.categorie !== null &&
        userData.categorie !== undefined &&
        userData.categorie !== ''
      ) {
        setActivity(userData.categorie)
      }

      if (
        userData.localisation !== null &&
        userData.localisation !== undefined &&
        userData.localisation !== ''
      ) {
        setLocalisation(userData.localisation)
      }

      if (
        userData.phone !== null &&
        userData.phone !== undefined &&
        userData.phone !== ''
      ) {
        setPhone(userData.phone)
      }
    }
  }, [userData])

  function resetFeedBack() {
    setTimeout(() => {
      setModifAuthorize('')
      setCallBackMessage('')
      setMessageFecth('')
    }, 6000)
  }

  const updateUserStatut = (e) => {
    setUserStatut(e.target.value)

    setModifAuthorize('')
    setCallBackMessage('')
    setMessageFecth('')
  }

  const updatePresentation = (e) => {
    setPresentation(e.target.value)

    setModifAuthorize('')
    setCallBackMessage('')
    setMessageFecth('')
  }

  const updateActivity = (e) => {
    setActivity(e.target.value)

    setModifAuthorize('')
    setCallBackMessage('')
    setMessageFecth('')
  }

  const updateLocalisation = (e) => {
    setLocalisation(e.target.value)

    setModifAuthorize('')
    setCallBackMessage('')
    setMessageFecth('')
  }

  const updatePhone = (e) => {
    setPhone(e.target.value)

    setModifAuthorize('')
    setCallBackMessage('')
    setMessageFecth('')
  }

  const regex =
    /(\d{10,}|\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}\b|contact skype|adresse|(https?|ftp):\/\/[^\s/$.?#].[^\s]*)/i

  async function handleSubmit(e) {
    e.preventDefault()
    setLoading('modifInfo')
    if (regex.test(presentation)) {
      setTimeout(() => {
        setLoading('')
        setModifAuthorize('error')
        setCallBackMessage('flex')
        setMessageFecth(
          'Modifier votre saisie dans le champ présentation et réessayer',
        )

        resetFeedBack()
      }, 2000)
    } else {
      const data = {
        statut: userStatut,
        presentation: presentation,
        categorie: activity,
        localisation: localisation,
        phone: phone,
      }
      try {
        const tokenLog = Cookies.get('_influencapp_tk_log')
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/user/update-info`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${tokenLog}`,
            },
            body: JSON.stringify(data),
          },
        )
        if (response.ok) {
          setTimeout(() => {
            setLoading('')
            setModifAuthorize(true)
            setCallBackMessage('flex')
            setMessageFecth('Modification effectuée avec succès.')

            resetFeedBack()
          }, 2000)
        } else {
          setTimeout(() => {
            setLoading('')
            setModifAuthorize('error')
            setCallBackMessage('flex')
            setMessageFecth(
              'Erreur, vérifier les informations saisies et réessayer !',
            )

            resetFeedBack()
          }, 2000)
        }
      } catch (error) {
        setLoading('')
        setModifAuthorize('error')
        setCallBackMessage('flex')
        setMessageFecth(
          "Oups, une erreur c'est produite veuillez réessayer plustard.",
        )
        console.error('Erreur lors de la requête fetch :', error)

        resetFeedBack()
      }
    }
  }

  return (
    <>
      <div className={`fadinAnimation ${styles.allContainer}`}>
        <form onSubmit={handleSubmit} className={styles.formContainer}>
          <div className={styles.statutUserContainer}>
            <div>
              <h3>Votre statut</h3>
              Je suis un/une :
            </div>

            <select
              name='statut-user'
              value={userStatut}
              onChange={updateUserStatut}
            >
              <option value='Influenceur'>influenceur</option>
              <option value='Créateur de contenu'>créateur de contenu</option>
              <option value='Acteur'>acteur</option>
              <option value='Youtubeur'>youtubeur</option>
              <option value='Agent'>agent</option>
              <option value='Agence'>agence</option>
              <option value='Marque'>marque</option>
              <option value='Porteur de projet'>porteur de projet</option>
              <option value='Autre'>autre</option>
            </select>
          </div>

          <div className={styles.presentationContainer}>
            <h3>Présentation</h3>
            <p>
              Toute information permettant de vous contacter en dehors
              d'influencapp (email, contact skype, adresse, téléphone, url de
              votre site professionnel) est interdite dans le profil.
            </p>

            <textarea
              minLength={20}
              onChange={updatePresentation}
              name='pesentation'
              id='pesentation'
              cols='30'
              rows='8'
              value={presentation}
            ></textarea>
          </div>

          <div className={styles.secteurActivite}>
            <h3>Secteur d'activité</h3>
            <select
              value={activity}
              name='statut-user'
              onChange={updateActivity}
            >
              <option value='Personnalité publique'>
                Personnalité publique
              </option>
              <option value="Agence d'influenceur">Agence d'influenceur</option>
              <option value='Influceur Multi-niche'>
                Influceur Multi-niche
              </option>
              <option value='Influenceur chien'>Influenceur chien</option>
              <option value='Influenceuse mode'>Influenceuse mode</option>
              <option value='Influenceur voyage'>Influenceur voyage</option>
              <option value='Influenceur food'>Influenceur food</option>
              <option value='Influenceur sport'>Influenceur sport</option>
              <option value='Influenceur automobile'>
                Influenceur automobile
              </option>
              <option value='Influenceuse beauté'>Influenceuse beauté</option>
              <option value='Influenceur mode homme'>
                Influenceur mode homme
              </option>
              <option value='Influenceur décoration'>
                Influenceur décoration
              </option>
              <option value='Influenceur gastronomie'>
                Influenceur gastronomie
              </option>
              <option value='Influenceur technologie'>
                Influenceur technologie
              </option>
              <option value='Influenceur gaming'>Influenceur gaming</option>
              <option value='Influenceur DIY'>Influenceur DIY</option>
              <option value='Influenceur santé'>Influenceur santé</option>
              <option value='Influenceur parent'>Influenceur parent</option>
              <option value='Influenceur animaux'>Influenceur animaux</option>
              <option value='Développement personnel'>
                Développement personnel
              </option>
              <option value='Influenceur moto'>Influenceur moto</option>
              <option value='Non défini'>Non défini</option>
            </select>
          </div>

          <div>
            <h3>Votre localisation</h3>
            <input
              value={localisation}
              onChange={updateLocalisation}
              type='text'
              placeholder='Quelle est votre localisation ? (Ex: Paris, France)'
            />
          </div>

          <div>
            <h3>N° de téléphone</h3>
            <input
              value={phone}
              minLength={6}
              maxLength={12}
              onChange={updatePhone}
              type='phone'
              placeholder='Entrez votre numéro de téléphone'
            />
          </div>

          <div className={styles.buttonContainer}>
            <button type='submit'>
              {loading === 'modifInfo' ? 'Mise à jour...' : 'Mettre à jour'}
            </button>
          </div>
        </form>
      </div>
      <NotifFeedBackFecth
        modifAuthorizeValue={modifAuthorize}
        callBackMessageValue={callBackMessage}
        messageFecthValue={messageFecth}
      />
    </>
  )
}

export default FormUpdateProfil
