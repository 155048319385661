import { Outlet } from 'react-router-dom'
import Header from './components/Header/Header'
import LoadingAllSite from './components/LoadingAllSite/LoadingAllSite'
// import Footer from './components/Footer/Footer'
import { useEffect } from 'react'
import ScrollToTop from './components/ScrollToTop/ScrollToTop'
// import OnDevBadge from './components/OnDevBadge/OnDevBadge'

function App() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <ScrollToTop />
      {/* <div className='w_inf_800'> */}
      <LoadingAllSite />
      {/* <OnDevBadge /> */}
      <Header style={{ marginTop: '40px' }} />
      <div style={{ minHeight: '70vh' }}>
        <Outlet />
      </div>
      {/* <Footer /> */}
    </>
  )
}

export default App
