import styles from "./index.module.scss";
function ProfilCoordonnees({ secteur, identityVerify, phone}) {
    return (
        <>
            <div className={`fadinAnimation containerStyles ${styles.profilEcoordonnees}`}>
                <h2>Profil et coordonnées</h2>

                {secteur && (secteur !== undefined) && (secteur !== null) ?
                    <div className={styles.content}>
                        <p>Secteur :</p>
                        <p>{secteur}</p>
                    </div>
                :""}
                
                <div className={styles.content}>
                    <p>Adresse e-mail : </p>
                    <p className={styles.verify}>vérifier</p>
                </div>
                <div className={styles.content}>
                    <p>Pièce d'identité :</p>
                    <p className={identityVerify ? styles.verify : styles.noVerify}>{identityVerify ? "vérifier" : "non vérifier"}</p>
                </div>

                {phone && (phone !== undefined) && (phone !== null) ? 
                    <div className={styles.content}>
                        <p>N° de téléphone :</p>
                        <p>{phone}</p>
                    </div>
                :""}
            </div>
        </>
    )
}

export default ProfilCoordonnees;
