import React, { useEffect, useState } from 'react'
import styles from './ProjectInfo.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircleCheck,
  faEuro,
  faEye,
  faPaperPlane,
  faTrophy,
  faUser,
  faXmarkCircle,
} from '@fortawesome/free-solid-svg-icons'
import FormSendOffer from '../FormSendOffer/FormSendOffer'
import CardOffer from '../CardOffer/CardOffer'
import { Link as ScrollLink } from 'react-scroll'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import Cookies from 'js-cookie'
import ComponentAuthRequired from '../../../middlewares/ComponentAuthRequired/ComponentAuthRequired'
import SubscriptionImage from '../../../assets/images/subscribe.webp'

function formatDeCreation(dateCrea) {
  const options = {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  }
  const date = new Date(dateCrea)
  const formattedDate = new Intl.DateTimeFormat('fr-FR', options).format(date)
  return formattedDate.replace('à', 'à ')
}

// function d'abreviation du nombre de vue ou nombre d'offres
function abreviationNombre(n) {
  if (n < 1000) {
    return n.toString()
  } else if (n < 1000000) {
    return (n / 1000).toFixed(1) + 'k'
  } else if (n < 1000000000) {
    return (n / 1000000).toFixed(1) + 'M'
  } else if (n < 1000000000000) {
    return (n / 1000000000).toFixed(1) + 'B'
  } else {
    return n.toString()
  }
}

function ProjectInfo({
  dateCrea,
  title,
  description,
  statut,
  budget,
  profilSearch,
  views,
  totalOffers,
  userAuthSubscription,
  dateProjet,
}) {
  const { id } = useParams()
  const [authorProject, setAuthorProject] = useState(false)
  const [displayFrom, setDisplayFrom] = useState(false)
  const [offerAvailable, setOfferAvailable] = useState(false)
  const [offerSelect, setOfferSelect] = useState([])
  const [projectWon, setProjectWon] = useState(false)
  const [allOffers, setAlloffers] = useState([])
  const [loading, setLoading] = useState('loadingComponent')
  const [refresh, setRefresh] = useState(false)
  const [callToSubscription, setCallToSubscription] = useState(false)
  const descriptionFormate = description?.split('\n')

  // Function d'affichage et masquage du form faire une offre
  const AddOfferForm = (e) => {
    if (e?.target?.name && e?.target?.name !== 'showForm') {
      // on effectue cette verification ne pas bloquer l'effect de l'ancre lors de l'affichage du form
      e?.preventDefault()
    }
    setDisplayFrom(!displayFrom)
  }

  const RefreshComponent = () => {
    setRefresh(!refresh)
  }

  const HandleSubscription = (e) => {
    e.preventDefault()
    setCallToSubscription(!callToSubscription)
  }

  useEffect(() => {
    const fecthOffersProject = async () => {
      try {
        const tokenLog = Cookies.get('_influencapp_tk_log')
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/offer/all-offers?id=${id}`,
          {
            method: 'GET',
            headers: { Authorization: `Bearer ${tokenLog}` },
          },
        )
        if (response.ok) {
          const result = await response.json()
          setAlloffers(result?.offers)
          setAuthorProject(result?.authorProject)
          setOfferAvailable(result?.userOfferAvailable)
          setProjectWon(result?.userProjectWon)

          if (statut === 'attribute') {
            if (result.offers !== undefined) {
              for (const offer of result.offers) {
                if (offer.statutOffer === 'select') {
                  setOfferSelect([offer])
                  break
                }
              }
            }
          }
        }
        setLoading('')
      } catch (error) {
        setLoading('')
        console.log('Error lors de la requette fecth', error)
      }
    }

    fecthOffersProject()
  }, [id, statut, refresh])

  return (
    <>
      <div className={`cardBackground ${styles.allContainer}`}>
        <div className={styles.firstSection}>
          <h3>{title}</h3>
          <div className={styles.statutEdateCrea}>
            <div>
              <div
                style={
                  statut === 'publish'
                    ? { color: 'green' }
                    : statut === 'allocation'
                      ? { color: 'red' }
                      : statut === 'prePublish'
                        ? { color: '#E87213' }
                        : { color: 'gray' }
                }
              >
                <p
                  style={
                    statut === 'publish'
                      ? { backgroundColor: 'green' }
                      : statut === 'allocation'
                        ? { backgroundColor: 'red' }
                        : statut === 'prePublish'
                          ? { backgroundColor: '#E87213' }
                          : { backgroundColor: 'gray' }
                  }
                ></p>
                <span>
                  {statut === 'publish'
                    ? 'Ouvert'
                    : statut === 'allocation'
                      ? "Encours d'attribution"
                      : statut === 'save'
                        ? 'Enregistré, non publié'
                        : statut === 'close'
                          ? 'Fermé'
                          : statut === 'prePublish'
                            ? 'Encours de vérification'
                            : statut === 'attribute'
                              ? 'Attributé'
                              : ''}
                </span>
              </div>
              {dateProjet && statut !== 'save' && (
                <p>
                  Édité le : <span>{formatDeCreation(dateCrea)}</span>
                </p>
              )}
              <p>
                {statut === 'save' || dateProjet ? 'Créé' : 'Publiée'} le :{' '}
                <span>
                  {dateProjet
                    ? formatDeCreation(dateProjet)
                    : formatDeCreation(dateCrea)}
                </span>
              </p>
            </div>

            {/* Gestion du statut du bouton d'envoi d'une offre */}
            <div>
              <ComponentAuthRequired
                valueDefault={
                  statut === 'publish' ? (
                    <div className={styles.inviteLogUser}>
                      Connectez-vous pour deposer une offre sur ce projet.
                      <Link to='/connexion'>Se connecter</Link>
                    </div>
                  ) : (
                    <div className={styles.inviteLogUser}>
                      Inscrivez-vous gratuitement pour créer un project
                      similaire.
                      <Link to='/inscription'>S'inscrire gratuitement</Link>
                    </div>
                  )
                }
              >
                {loading === 'loadingComponent' ? null : authorProject ? ( // verification si c'est l'auteur du projet
                  <div
                    className={`fadinAnimation ${styles.btnOfferAuthorProject}`}
                  >
                    <ScrollLink
                      to='allOffersSection'
                      smooth
                      duration={500}
                      offset={-50}
                    >
                      <button>Voir les offres</button>
                    </ScrollLink>

                    <Link to={`/edit-project/${id}`}>
                      <button
                        className={`fadinAnimation ${styles.gestionProjects}`}
                      >
                        Modifier l'annonce
                      </button>
                    </Link>
                  </div>
                ) : offerAvailable &&
                  !projectWon &&
                  (statut === 'publish' || statut === 'allocation') ? ( // verification si une autre a déjà été envoyée.
                  <div className={`fadinAnimation ${styles.offerAvailable}`}>
                    <FontAwesomeIcon icon={faCircleCheck} />
                    Offre déposée
                  </div>
                ) : offerAvailable && projectWon && statut === 'attribute' ? ( // verification si l'user a remporté ce projet.
                  <div className={`fadinAnimation ${styles.offerAvailable}`}>
                    <FontAwesomeIcon icon={faTrophy} />
                    Projet remporté
                  </div>
                ) : offerAvailable && !projectWon && statut === 'attribute' ? ( // verification si l'offre de l'user a été déclinée.
                  <div className={`fadinAnimation ${styles.offerDeclined}`}>
                    <FontAwesomeIcon icon={faXmarkCircle} />
                    Offre déclinée
                  </div>
                ) : offerAvailable && statut === 'close' ? ( // verification si l'user déposer une offre mes le projet a été fermé.
                  <div className={`fadinAnimation ${styles.offerClose}`}>
                    <FontAwesomeIcon icon={faXmarkCircle} />
                    Projet fermé
                  </div>
                ) : !displayFrom && statut === 'publish' ? (
                  <>
                    {userAuthSubscription === true ? (
                      <ScrollLink
                        to='addOfferForm'
                        smooth
                        duration={500}
                        offset={-50}
                      >
                        <button
                          name='showForm'
                          onClick={AddOfferForm}
                          className={`fadinAnimation ${styles.btnAddOffer}`}
                        >
                          Faire une offre
                        </button>
                      </ScrollLink>
                    ) : (
                      <button
                        name='showForm'
                        onClick={HandleSubscription}
                        className={`fadinAnimation ${styles.btnAddOffer}`}
                      >
                        Faire une offre
                      </button>
                    )}
                  </>
                ) : (
                  (statut === 'close' || statut === 'save') && (
                    <button
                      className={`fadinAnimation ${styles.btnNotAddOffer}`}
                    >
                      Non disponible aux offres
                    </button>
                  )
                )}
              </ComponentAuthRequired>
            </div>
          </div>
        </div>

        <div className={styles.description}>
          {description !== undefined && description !== ''
            ? descriptionFormate.map((line, index) => (
                <div key={index}>
                  {line}
                  {index !== descriptionFormate.length - 1 && <br />}
                </div>
              ))
            : 'Aucun détails renseigné.'}
        </div>

        <div className={styles.statContainer}>
          <div className={styles.iconeContainer}>
            <p className={styles.icone}>
              <FontAwesomeIcon icon={faEuro} />
            </p>
            <p>
              Budget estimé : <span>{budget}</span>
            </p>
          </div>

          <div className={styles.iconeContainer}>
            <p className={styles.icone}>
              <FontAwesomeIcon icon={faEye} />
            </p>
            <p>
              Projet vues : <span>{abreviationNombre(views)}</span> fois
            </p>
          </div>

          <div className={styles.iconeContainer}>
            <p className={styles.icone}>
              <FontAwesomeIcon icon={faPaperPlane} />
            </p>
            <p>
              Offres reçus : <span>{abreviationNombre(totalOffers)}</span>{' '}
              offres
            </p>
          </div>

          <div className={styles.iconeContainer}>
            <p className={styles.icone}>
              <FontAwesomeIcon icon={faUser} />
            </p>
            <p>Profils recherchés : </p>
            {profilSearch.map((profil) => (
              <span key={profil}>{profil}.</span>
            ))}
          </div>
        </div>

        {/* Formulaire d'envoi d'une offre  */}
        <div id='addOfferForm'>
          {displayFrom && (
            <FormSendOffer
              displayFrom={AddOfferForm}
              offerSuccesSent={RefreshComponent}
            />
          )}
        </div>
      </div>

      {/* Toutes les offres du projet */}
      <div id='allOffersSection' className={styles.offersSection}>
        <h2 className={styles.titleOffersSection}>Offres pour ce projet</h2>
        {/* offre selectionnée  */}
        {statut === 'attribute' && offerSelect && offerSelect.length > 0
          ? offerSelect.map((offer) => (
              <CardOffer
                key={offer?.idOffer}
                idOffer={offer?.idOffer}
                idProject={id}
                pseudo={offer?.user?.pseudo}
                name={offer?.user?.name}
                statut={offer?.user?.statut}
                dateCrea={offer?.user?.statistique?.dateCrea}
                dateSendOffer={formatDeCreation(offer?.dateSendOffer)}
                urlProfil={offer?.user?._link_photo_profil?.url}
                amount={offer?.amount}
                views={offer?.user?.statistique?.views}
                inFavorize={offer?.user?.statistique?.inFavorize}
                offerSelect={true}
                subscription={offer?.user?.subscription?.type}
              />
            ))
          : null}

        {/* liste des offres  */}
        {allOffers && allOffers.length > 0
          ? allOffers.map((offer) => {
              if (offer.statutOffer !== 'select') {
                return (
                  <CardOffer
                    key={offer?.idOffer}
                    idOffer={offer?.idOffer}
                    idProject={id}
                    pseudo={offer?.user?.pseudo}
                    name={offer?.user?.name}
                    statut={offer?.user?.statut}
                    dateCrea={offer?.user?.statistique?.dateCrea}
                    dateSendOffer={formatDeCreation(offer?.dateSendOffer)}
                    urlProfil={offer?.user?._link_photo_profil?.url}
                    amount={offer?.amount}
                    views={offer?.user?.statistique?.views}
                    inFavorize={offer?.user?.statistique?.inFavorize}
                    subscription={offer?.user?.subscription?.type}
                  />
                )
              } else {
                return null
              }
            })
          : offerSelect.length <= 0 && (
              <div className={styles.voidOffers}>
                Il n'y a pas encore d'offres sur ce projet.
              </div>
            )}
      </div>

      {/* popup callToSubscription */}
      {callToSubscription && (
        <div className={styles.containerCallToSubscription}>
          <div className={styles.contentContainer}>
            <div className={styles.contentCallTopSubscription}>
              <p onClick={HandleSubscription} className={styles.iconeClose}>
                <FontAwesomeIcon icon={faXmarkCircle} />
              </p>
              <p>
                <img src={SubscriptionImage} alt='subscription-img' />
              </p>
              <div>
                {userAuthSubscription === false && (
                  <>
                    <h3>Abonnez-vous pour contactez ce client</h3>
                    <p>Le dépôt d'offre est limité aux abonnés influencapp.</p>
                  </>
                )}

                {userAuthSubscription === 'balanceOver' && (
                  <>
                    <h3>Vous n'avez plus de crédit</h3>
                    {/* <p>
                      Optez pour un abonnement plus pour envoyer plus d'offre.
                    </p> */}
                    <p>Achetez un nouveau pack pour envoyer plus d'offre.</p>
                  </>
                )}
                <Link to={`/my-subscription`}>
                  {/* <button>Voir les abonnements</button> */}
                  <button>Voir les packs</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default ProjectInfo
