import styles from "./monCompte.module.scss";
import { useEffect, useState } from "react";
import { fetchUserInfo }  from "../../apiUtils/fetch-info-user";
import FormUpdateProfil from "../../components/PageMonCompteComponent/FormUpdateProfil/FormUpdateProfil";
import FormUpdateImageProfil from "../../components/PageMonCompteComponent/FormUpdateImageProfil/index";
import FormUpdateImageCouverture from "../../components/PageMonCompteComponent/FormUpdateImageCouverture/index";
import ImageGalerie from "../../components/PageMonCompteComponent/ImageGalerie/index";
import VideoPrez from "../../components/PageMonCompteComponent/VideoPrez/VideoPrez";
import MonCompteSousMenu from "../../components/MonCompteSousMenu/MonCompteSousMenu";

function MonCompte() {
    const [user, setUser] = useState({});
    useEffect(()=>{
        const fetchData = async () => {
            const userData = await fetchUserInfo();
            setUser(userData);
        };
        fetchData();
    }, []);

    return (
        <>
            <h2 className={`titlePage`}>Modifier vos informations personnelles</h2>
            <div className={styles.contentPage}>
                <div className={`w_sp_800`}>
                    <MonCompteSousMenu pseudo={user.pseudo}/>
                </div>

                <div className={styles.sectionRight}>
                    <FormUpdateProfil userData={user}/>
                    <FormUpdateImageProfil userData={user}/>
                    <FormUpdateImageCouverture userData={user}/>
                    
                    <div className={styles.GalerieContainer}>
                        <h3>Médias</h3>
                        <p>Ajoutez quelque image de vous en avant.</p>

                        <div className={styles.image}>
                            <ImageGalerie userData={user} index={1}/>
                            <ImageGalerie userData={user} index={2}/>
                        </div>

                        <div className={styles.video}>
                            <VideoPrez idVideo={user?._medias?.video_url} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MonCompte;
