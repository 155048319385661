import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import { useEffect, useState } from 'react'
import LastLogUser from '../../apiUtils/LastLogUser'

const AuthRequired = ({ children }) => {
  const navigate = useNavigate()
  const token = Cookies.get('_influencapp_tk_log')
  const [tokenVerif, setTokenVerif] = useState(false)
  const [userPseudo, setUserPseudo] = useState('')

  useEffect(() => {
    const fetchLog = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/user/tk-log`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          },
        )
        if (response.ok) {
          const res = await response.json()
          setUserPseudo(res.userPseudo)
          Cookies.set('_influencapp_user_pseudo', res.userPseudo, {
            expires: 1,
            sameSite: 'Strict',
          })
          setTokenVerif(true)
        } else {
          navigate('/connexion')
          setTokenVerif(false)
        }
      } catch (error) {
        setTokenVerif(false)
        console.error('Erreur lors de la requête fetch :', error)
      }
    }
    fetchLog()
  }, [token, navigate])

  return tokenVerif ? (
    <>
      {children}
      <LastLogUser userPseudo={userPseudo} />
    </>
  ) : (
    ''
  )
}

export default AuthRequired
