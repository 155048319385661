import React from 'react'
import styles from './GenerateReferralLink.module.scss'
import Cookies from 'js-cookie'

function GenerateReferralLink({ reset }) {
  const pseudoUser = Cookies.get('_influencapp_user_pseudo')
  return (
    <>
      <div className={styles.container}>
        <div className={styles.contentContainer}>
          <div className={styles.content}>
            <div>
              <h3>Lien de parrainage</h3>
              <p>Votre lien de pairranage est :</p>
              <div className={styles.LinkReferral}>
                https://{window.location.hostname}/inscription?referral_link=
                {pseudoUser}
              </div>
              <div className={styles.buttonContainer}>
                <button onClick={reset}>Fermer</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default GenerateReferralLink
