import React, { useEffect, useState } from 'react';
import styles from "./messagesReçus.module.scss";
import Cookies from 'js-cookie';
import CardMessage from '../../components/CardMessage/CardMessage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArchive, faBars, faBell, faBellSlash, faDownload, faEyeSlash, faSearch, faStar, faXmark } from '@fortawesome/free-solid-svg-icons';
import NotifFeedBackFecth from '../../components/NotifFeedBackFecth/NotifFeedBackFecth';
import GifLoading from "../../components/GifLoading/GifLoading";

function MessagesReçus() {
    const token = Cookies.get("_influencapp_tk_log");
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState("fecthLoad");
    const [statusNotif, setStatusNotif] = useState(true);
    const [showSousMenu, setShowSousMenu] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize] = useState(2);
    const [hasMore, setHasMore] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [fecthUrl, setFecthUrl] = useState(`${process.env.REACT_APP_API_URL}/offer/all-rooms-offer?filter&page=1&pageSize=2`);

    const [messageFecth, setMessageFecth] = useState("");
    const [callBackMessage, setCallBackMessage] = useState("");
    const [modifAuthorize, setModifAuthorize] = useState("");

    const [senderSearch, setSenderSearch] = useState("");
    const [statusMessage, setStatusMessage] = useState("none");

    const handleRefresh = () =>{
        setRefresh(!refresh);
    }

    const resetFeedBack = () =>{
        setTimeout(()=>{
            setModifAuthorize("");
            setCallBackMessage("");
            setMessageFecth("");
        }, 6000)
    }

    const handleClickNotif = async () =>{
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/user/notif-messages-status?status=${!statusNotif}`, {
                method : "PATCH",
                headers : { 
                    Authorization : `Bearer ${token}`,
                    "Content-Type" : "application/json"
                }
            });
            if(response.ok){
                setModifAuthorize(true);
                setCallBackMessage("flex");
                setMessageFecth(statusNotif ? "Désactivée : vous ne recevrez plus de notifications par mail" : "Activée : vous recevrez les notifications par mail");
                setStatusNotif(!statusNotif);

                resetFeedBack();
            }  else{
                setModifAuthorize("error");
                setCallBackMessage("flex");
                setMessageFecth("Oups, une erreur s'est produite veuillez réessayez ultérieument");

                resetFeedBack();
            }   
        } catch (error) {
            console.log("Erreur lors de la requette", error);
            setModifAuthorize("error");
            setCallBackMessage("flex");
            setMessageFecth("Oups, une erreur s'est produite veuillez réessayez ultérieument");

            resetFeedBack();
        }

    }

    // La fonction pour faire un fecth en recupérant des user en paginant la reponse.
    const loadMoreData = async () => {
        setLoading("seeMore");
        const token = Cookies.get("_influencapp_tk_log");     
        try {
            const response = await fetch(`${fecthUrl.split("&page")[0]}&page=${page + 1}&pageSize=${pageSize}`, {
                headers : {Authorization : `Bearer ${token}`}
            })
            if (!response.ok) {
                throw new Error(`Erreur HTTP : ${response.status}`);
            }
            const result = await response.json();
            setTimeout(() => {
                const { roomsMessageOffer, hasMore } = result;
                setData([...data, ...roomsMessageOffer]);
                setHasMore(hasMore);
                setPage(page + 1);
                setLoading("");
            }, 1000)

        } catch (error) {
            setLoading("");
            console.log("error lors de la requette fecth", error);
        }
    };

    // function de création du lien de la requette de recherche ou filtre
    const NewUrlFecth = (e, status) =>{
        e.preventDefault();
        setLoading("filtre");
        let newUrl = `${process.env.REACT_APP_API_URL}/offer/all-rooms-offer?filter`;
        if (senderSearch !== "") {
            newUrl = `${newUrl}${`&sender=${senderSearch}`}`;
        }
        if (status) {
            setStatusMessage(status);
            newUrl = `${newUrl}${`&status=${status ? status : statusMessage}`}`;
        }
        
        setFecthUrl(`${newUrl}&page=1&pageSize=2`);
        setPage(1);
        setShowSousMenu(false);
        
        setTimeout(()=>{
            setLoading("");
        }, 2000)
    }
    
    useEffect(()=>{
        const token = Cookies.get("_influencapp_tk_log");
        const fecthData = async ()=>{
            try {
                const response = await fetch(fecthUrl, {
                    headers : {Authorization : `Bearer ${token}`}
                })
                if(response.ok){
                    const result = await response.json();
                    setData(result.roomsMessageOffer);
                    setStatusNotif(result.notif_status);
                    setHasMore(result.hasMore);

                    const messageNotRead = result.roomsMessageOffer.filter((item) => item.receiverRead === false);
                    sessionStorage.setItem("notifMessage", messageNotRead.length);
                    setLoading("");
                }
                setLoading("");
            } catch (error) {
                setLoading("");
                console.log("Error lors de la requette fetch");
            }
        }
        fecthData();
    }, [refresh, fecthUrl]);

    return (
        <>
            {loading === "filtre" || loading === "fecthLoad" ? <GifLoading positionDiv="fixed" /> : null}
            <h2 className='titlePage'>Messages d'offre reçus</h2>
            <div onClick={handleClickNotif} className={styles.iconeNotifContainer}>
                {
                    statusNotif || statusNotif === undefined ? 
                    <FontAwesomeIcon icon={faBell}/> :
                    <FontAwesomeIcon icon={faBellSlash}/>
                }
            </div>
            <div className={styles.allContainer}>
                <div className={styles.widgetContent}>
                    <div className={styles.LeftContent}>
                        <div className={styles.sousMenu}>
                            <h2>Messagerie</h2>
                            <div className={styles.sousMenuMessage}>
                                <button style={statusMessage === "none" ? {color: "#E87213", fontWeight: "500"} : {}} onClick={ (e)=> { NewUrlFecth(e, "none"); } } ><FontAwesomeIcon icon={faDownload} />Boite de réception</button> <br />
                                <button style={statusMessage === "notRead" ? {color: "#E87213", fontWeight: "500"} : {}} onClick={ (e)=> { NewUrlFecth(e, "notRead"); } } ><FontAwesomeIcon icon={faEyeSlash} />Non lus</button> <br />
                                <button style={statusMessage === "favourite" ? {color: "#E87213", fontWeight: "500"} : {}} onClick={ (e)=> { NewUrlFecth(e, "favourite"); } } ><FontAwesomeIcon icon={faStar} />Favoris</button> <br />
                                <button style={statusMessage === "archive" ? {color: "#E87213", fontWeight: "500"} : {}} onClick={ (e)=> { NewUrlFecth(e, "archive"); } } ><FontAwesomeIcon icon={faArchive} />Archivés</button> <br />
                            </div>
                        </div>
                    </div>

                    <div className={styles.RightContent}>
                        <div className={styles.searhEfilterContainer}>
                            <div onClick={()=>setShowSousMenu(!showSousMenu)} className={styles.sousMenuIcone}>
                                {statusMessage && statusMessage !== "none" && <div className={styles.filtreActive}></div>}
                                <FontAwesomeIcon icon={showSousMenu ? faXmark : faBars} />
                            </div>
                            <form onSubmit={(e)=> { NewUrlFecth(e, "none")}} className={styles.containerSearch}>
                                <div className={styles.champSearchMessage}>
                                    <label htmlFor="searchMessage"><FontAwesomeIcon icon={faSearch} /></label>
                                    <input onChange={(e)=>setSenderSearch(e.target.value)} id="searchMessage" type="text" placeholder="chercher un message..." />
                                </div>
                                <button type="submit" className={styles.btnSubmitSearch}><FontAwesomeIcon icon={faSearch} /></button>
                            </form>
                        </div>

                        {/* sous menu */}
                        {showSousMenu && 
                            <>
                                <div onClick={()=>setShowSousMenu(!showSousMenu)} className={styles.sousMenuContainer}></div>
                                <div className={styles.sousMenu}>
                                    <div className={styles.sousMenuMessage}>
                                        <button style={statusMessage === "none" ? {color: "#E87213", fontWeight: "500"} : {}} onClick={ (e)=> { NewUrlFecth(e, "none"); } } ><FontAwesomeIcon icon={faDownload} />Boite de réception</button> <br />
                                        <button style={statusMessage === "notRead" ? {color: "#E87213", fontWeight: "500"} : {}} onClick={ (e)=> { NewUrlFecth(e, "notRead"); } } ><FontAwesomeIcon icon={faEyeSlash} />Non lus</button> <br />
                                        <button style={statusMessage === "favourite" ? {color: "#E87213", fontWeight: "500"} : {}} onClick={ (e)=> { NewUrlFecth(e, "favourite"); } } ><FontAwesomeIcon icon={faStar} />Favoris</button> <br />
                                        <button style={statusMessage === "archive" ? {color: "#E87213", fontWeight: "500"} : {}} onClick={ (e)=> { NewUrlFecth(e, "archive"); } } ><FontAwesomeIcon icon={faArchive} />Archivés</button> <br />
                                    </div>
                                </div>
                            </>
                        }

                        {/* liste message */}
                        <div className={styles.ListeMessageContainer}>
                        { 
                            loading === "fecthLoad" ? null :
                            <>
                                {data.map((message, index)=>(
                                    <CardMessage
                                        key={index}
                                        id={message?._id}
                                        sender={message?.sender}
                                        project={message?.project}
                                        idOffer={message?.idOffer}
                                        receiverRead={message?.receiverRead}
                                        timestamp={message?.timestamp}
                                        statusRoom={message?.status}
                                        refresh={handleRefresh}
                                    />
                                ))}

                                {hasMore && (
                                    <div className={styles.showMoreContainer}>
                                        <button onClick={loadMoreData}>
                                        {loading === "seeMore" ? 'Chargement...' : 'Afficher plus'}
                                        </button>
                                    </div>
                                )}

                                {     
                                    data.length > 0 ? null : <div className={styles.notFoundMessage}>Aucun message</div>
                                }
                            </>
                        }
                        </div>
                    </div>
                </div>
                <NotifFeedBackFecth modifAuthorizeValue={modifAuthorize} callBackMessageValue={callBackMessage} messageFecthValue={messageFecth} />
            </div>
        </>
    )
}

export default MessagesReçus