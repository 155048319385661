import React from 'react'

function AvisInfluencapp() {
  return (
    <>
        <h2 className='titlePage'>Avis Influencapp</h2>
    </>
  )
}

export default AvisInfluencapp