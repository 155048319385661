import { useEffect, useState } from "react";
import styles from "./index.module.scss";

function formatDerniereConnexion(dateDerniereConnexion) {
    const dateActuelle = new Date(dateDerniereConnexion);
    const maintenant = new Date();
    const difference = maintenant - dateActuelle;
  
    const minute = 60 * 1000;
    const heure = minute * 60;
    const jour = heure * 24;
    const semaine = jour * 7;
    const mois = jour * 30;
    const annee = jour * 365;
  
    if (difference < minute) {
      return "• Disponible";
    } else if (difference < heure) {
      const minutes = Math.floor(difference / minute);
      return `${minutes} minute${minutes > 1 ? "s" : ""}`;
    } else if (difference < jour) {
      const heures = Math.floor(difference / heure);
      return `${heures} heure${heures > 1 ? "s" : ""}`;
    } else if (difference < semaine) {
      const jours = Math.floor(difference / jour);
      return `${jours} jour${jours > 1 ? "s" : ""}`;
    } else if (difference < mois) {
      const semaines = Math.floor(difference / semaine);
      return `${semaines} semaine${semaines > 1 ? "s" : ""}`;
    } else if (difference < annee) {
      const moisPasses = Math.floor(difference / mois);
      return `${moisPasses} mois`;
    } else {
      const annees = Math.floor(difference / annee);
      return `${annees} an${annees > 1 ? "s" : ""}`;
    }
}


function abreviationNombre(n) {
    if (n < 1000) {
      return n.toString();
    } else if (n < 1000000) {
      return (n / 1000).toFixed(1) + 'k';
    } else if (n < 1000000000) {
      return (n / 1000000).toFixed(1) + 'M';
    } else if (n < 1000000000000) {
      return (n / 1000000000).toFixed(1) + 'B';
    } else {
      return n.toString();
    }
}
  

function ProfilStatistiques({ userStats }) {
    const [userConnect, setUserConnect] = useState("");
    const [colorGreen, setColorGreen] = useState("");

    useEffect(()=>{
        const newFormat = formatDerniereConnexion(userStats?.lastConnect);
        setUserConnect(newFormat);

        if(userConnect.split(" ")[0] === "•"){
            setColorGreen("green");
        } else{
            setColorGreen("");
        }

    }, [userStats?.lastConnect, userConnect])

    return (
        <>
            <div className={`fadinAnimation containerStyles ${styles.profilStat}`}>
                <h2>Statistique du profil</h2>
                <div className={styles.content}>
                    <p>Profil vu :</p>
                    <p>{abreviationNombre(userStats?.views)} <span>fois</span></p>
                </div>
                <div className={styles.content}>
                    <p>Ajouter aux favoris : </p>
                    <p>{abreviationNombre(userStats?.inFavorize)} <span>fois</span></p>
                </div>
                <div className={styles.content}>
                    <p>Connecté il y'a :</p>
                    <p style={{color: colorGreen}}>{userConnect.split(" ")[0]} <span>{userConnect.split(" ")[1]}</span></p>
                </div>
                <div className={styles.content}>
                    <p>Membre depuis :</p>
                    <p><span>{(userStats?.dateCrea).split(" ")[0]}</span> {(userStats?.dateCrea).split(" ")[1]}</p>
                </div>
            </div>
        </>
    )
}

export default ProfilStatistiques;
