export async function fecthStat(element, user, value) { 
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    
    const bodyData = {};
    bodyData[element] = value;
    bodyData.pseudoUser = user;

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(bodyData),
    };
    
    return fetch(`${process.env.REACT_APP_API_URL}/user/statprofile`, requestOptions)
      .then(response => response.json())
      .catch(error => {
        console.log('error', error);
        throw error;
      });
}