import React from 'react'
import styles from './LogTextRGPD.module.scss'
import { Link } from 'react-router-dom'

export default function LogTextRGPD() {
  return (
    <div className={styles.textRGPD}>
      En utilisant ce site, vous acceptez notre{' '}
      <Link to='/politique-de-confidentialite'>
        Politique de confidentialité
      </Link>{' '}
      et nos
      <Link to='/conditions-dutilisation'> Conditions d&apos;utilisation.</Link>
    </div>
  )
}
