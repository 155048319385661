import React from 'react'
import styles from "./GifFecthLoad.module.scss";
import LoadingGif from "../../assets/images/loadingpoint.gif"; 

function GifLoadingComponentFecth({ positionDiv }) {
  return (
    <div style={{position: `${positionDiv}`}} className={styles.loadingContainer}><img src={LoadingGif} alt="Loading" /></div>
  )
}

export default GifLoadingComponentFecth