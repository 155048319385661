import React from 'react'
import styles from './AuthorProfile.module.scss'
import { Link } from 'react-router-dom'
import ImageFlou from '../../../assets/images/profil_info_flou.webp'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock, faPlusCircle } from '@fortawesome/free-solid-svg-icons'

// Function de formatage de la date de création ou publication
function formatDeCreation(date) {
  const dateActuelle = new Date(date)
  const maintenant = new Date()
  const difference = maintenant - dateActuelle

  const minute = 60 * 1000
  const heure = minute * 60
  const jour = heure * 24
  const semaine = jour * 7
  const mois = jour * 30
  const annee = jour * 365

  if (difference < minute) {
    return '1 minute'
  } else if (difference < heure) {
    const minutes = Math.floor(difference / minute)
    return `${minutes} minute${minutes > 1 ? 's' : ''}`
  } else if (difference < jour) {
    const heures = Math.floor(difference / heure)
    return `${heures} heure${heures > 1 ? 's' : ''}`
  } else if (difference < semaine) {
    const jours = Math.floor(difference / jour)
    return `${jours} jour${jours > 1 ? 's' : ''}`
  } else if (difference < mois) {
    const semaines = Math.floor(difference / semaine)
    return `${semaines} semaine${semaines > 1 ? 's' : ''}`
  } else if (difference < annee) {
    const moisPasses = Math.floor(difference / mois)
    return `${moisPasses} mois`
  } else {
    const annees = Math.floor(difference / annee)
    return `${annees} an${annees > 1 ? 's' : ''}`
  }
}

function AuthorProfile({
  pseudo,
  name,
  statut,
  imgUrl,
  lastConnect,
  subscription,
}) {
  return (
    <>
      {!pseudo ? (
        <div className={`cardBackground ${styles.authorNotFoundContainer}`}>
          <div className={styles.callToSubscription}>
            <p className={styles.icone}>
              <FontAwesomeIcon icon={faLock} />
            </p>
            <p>
              Le profil du porteur de projet est reservé aux utilisateurs
              inscrits
              {/* Le profil du porteur de projet est reservé aux abonnés influencapp */}
            </p>
          </div>
          <img
            className={styles.imageFlou}
            src={ImageFlou}
            alt='profile author project'
          />
        </div>
      ) : (
        <Link to={`/profile/${pseudo}`}>
          <div className={`cardBackground ${styles.allContainer}`}>
            <div className={styles.imageContainer}>
              {imgUrl && imgUrl !== undefined ? (
                <img
                  src={`${process.env.REACT_APP_URL_BASE_IMAGE}${imgUrl}`}
                  alt={`Profile de ${pseudo}`}
                />
              ) : null}
            </div>
            <div>
              <div className={styles.profileNameContainer}>
                <p>{name ? name : pseudo}</p>
                {subscription && subscription !== 'null' && (
                  <div
                    className={styles.badgesPro}
                    style={
                      subscription === 'ultimate'
                        ? { minWidth: '65px', backgroundColor: '#FF8B49' }
                        : subscription === 'premium'
                          ? { minWidth: '60px', backgroundColor: '#FFAB7B' }
                          : // : subscription === 'standard'
                            //   ? { minWidth: '50px', backgroundColor: '#FFC65A' }
                            {}
                    }
                  >
                    {subscription !== 'standard' && <p>PRO</p>}
                    {(subscription === 'premium' ||
                      subscription === 'ultimate') && (
                      <FontAwesomeIcon
                        style={{ marginLeft: '4px' }}
                        icon={faPlusCircle}
                      />
                    )}
                    {subscription === 'ultimate' && (
                      <FontAwesomeIcon
                        style={{ marginLeft: '-4px' }}
                        icon={faPlusCircle}
                      />
                    )}
                  </div>
                )}
              </div>
              <p>{statut}</p>
              <div
                className={styles.lastConnect}
                style={
                  formatDeCreation(lastConnect) === '1 minute'
                    ? { color: 'green', fontWeight: '600' }
                    : { color: '#000' }
                }
              >
                {' '}
                {formatDeCreation(lastConnect) === '1 minute' ? (
                  <p>• Disponible</p>
                ) : (
                  <p>
                    Connecté il y'a :{' '}
                    <span>{formatDeCreation(lastConnect)}</span>
                  </p>
                )}
              </div>
            </div>
          </div>
        </Link>
      )}
    </>
  )
}

export default AuthorProfile
