import React from 'react'
import CardSuccesPayment from '../../components/CardSuccesPayment/CardSuccesPayment'


function Payment() {
  return (
    <>
        <CardSuccesPayment/>
    </>
  )
}

export default Payment