import { useState } from 'react'
import Cookies from 'js-cookie'
import { Link } from 'react-router-dom/dist'
import { useNavigate } from 'react-router-dom'
import NotifFeedBackFecth from '../../../components/NotifFeedBackFecth/NotifFeedBackFecth'

import styles from './form-inscription.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'

function FormInscription() {
  const navigate = useNavigate()
  const statutUser = Cookies.get('_influencapp_statut_select')
  const sponsor = Cookies.get('_influencapp_sponsor')

  const [formData, setFormData] = useState({
    email: '',
    statut: statutUser,
    code: '',
    name: '',
    pseudo: '',
    phone: '',
    password: '',
    ...(sponsor && { sponsor: sponsor }),
  })

  const [loading, setLoading] = useState('')
  const [messageSendCode, setMessageSendCode] = useState('')
  const [messageValidPseudo, setMessageValidPseudo] = useState('')
  const [visibilityPassword, setVisibilityPassword] = useState(false)

  const [modifAuthorize, setModifAuthorize] = useState('')
  const [messageFecth, setMessageFecth] = useState('')
  const [callBackMessage, setCallBackMessage] = useState('')

  const handleVisibilityPassword = () => {
    setVisibilityPassword(!visibilityPassword)
  }

  const resetFeedBack = () => {
    setTimeout(() => {
      setModifAuthorize('')
      setCallBackMessage('')
      setMessageFecth('')
    }, 6000)
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })

    if (e.target.name === 'pseudo') {
      if (e.target.value !== null) {
        setFormData({ ...formData, [name]: value.replace(/\s/g, '') })
        async function ValidationPseudo(pseudoEntry) {
          if (pseudoEntry.length >= 3) {
            try {
              const response = await fetch(
                `${process.env.REACT_APP_API_URL}/user/pseudo-test`,
                {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json' },
                  body: JSON.stringify({ pseudo: pseudoEntry }),
                },
              )

              const res = await response.json()
              if (res.valid === false) {
                setMessageValidPseudo('noValid')
              } else if (res.valid === true) {
                setMessageValidPseudo(true)
              }
            } catch (error) {
              console.error('Erreur lors de la requête fetch :', error)
            }
          } else {
            setMessageValidPseudo('noValid')
          }
        }

        ValidationPseudo(e.target.value)
      }
    }
  }

  // pour eviter un espace vide dans un champs
  const handleKeyDown = (e) => {
    if (e.key === ' ') {
      e.preventDefault()
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      setLoading('createAccount')
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/user/signup`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(formData),
        },
      )
      if (response.ok) {
        setTimeout(() => {
          setLoading('')
          Cookies.remove('_influencapp_statut_select')
          Cookies.remove('_influencapp_sponsor')
          setModifAuthorize(true)
          setCallBackMessage('flex')
          setMessageFecth('Compte créer avec succès !')

          const form = document.getElementById('form-reset-password')
          form.reset()
          resetFeedBack()

          setTimeout(() => {
            navigate('/connexion')
          }, 3000)
        }, 2000)
      } else {
        setTimeout(() => {
          setLoading('')
          setModifAuthorize('error')
          setCallBackMessage('flex')
          setMessageFecth(
            "Erreur, vérifier les informations saisies ou si votre code n'a pas expiré et réessayer !",
          )

          resetFeedBack()
        }, 2000)
      }
    } catch (error) {
      setLoading('')
      setModifAuthorize('error')
      setCallBackMessage('flex')
      setMessageFecth(
        "Oups, une erreur s'est produite veuillez réessayer ulterieurement !",
      )

      resetFeedBack()
      console.error('Erreur lors de la requête fetch :', error)
    }
  }

  // Envoi du code de verification à l'adresse e-mail entrez
  const sendCodeVerif = async () => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    if (regex.test(formData.email)) {
      setLoading('sendCodeVerif')
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/verification/send-mail`,
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              email: formData.email,
              context: 'verifMail',
            }),
          },
        )
        if (response.ok) {
          setTimeout(async () => {
            setLoading('')
            const res = await response.json()
            if (res.sendCode) {
              setMessageSendCode(true)
            }
          }, 2000)
        } else {
          setTimeout(async () => {
            const res = await response.json()
            if (res.user_mail_exist) {
              setMessageSendCode(false)
            } else {
              setMessageSendCode('error')
            }
            setLoading('')
          }, 2000)
        }
      } catch (error) {
        setLoading('')
        console.error('Erreur lors de la requête fetch :', error)
      }
    } else {
      setMessageSendCode('error')
    }
  }

  return (
    <>
      <div className={`fadinAnimation ${styles.allContainer}`}>
        <h2>Inscription statut {statutUser}</h2>
        <div className={styles.callToSignin}>
          Vous avez déjà un compte ? <Link to='/connexion'>Connectez-vous</Link>
        </div>

        <form id='form-reset-password' onSubmit={handleSubmit}>
          <div className={styles.emailInputContainer}>
            <input
              name='email'
              type='email'
              placeholder='Adresse e-mail*'
              required
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
            />
            <div onClick={sendCodeVerif} className={styles.buttonSendCode}>
              {loading === 'sendCodeVerif'
                ? 'Envoi du code...'
                : 'Envoyer le code'}
            </div>
          </div>

          <div className={styles.callbackMessageContainer}>
            {messageSendCode === true ? (
              <p className={styles.succes}>Code envoyé avec succès.</p>
            ) : messageSendCode === false ? (
              <p className={styles.failed}>
                Oups, code non envoyé, un compte existe déjà avec cette adresse
                e-mail.
              </p>
            ) : messageSendCode === 'error' ? (
              <p className={styles.failed}>
                Erreur, verifier votre saisie et réessayer.
              </p>
            ) : (
              ''
            )}
          </div>

          <input
            name='code'
            type='text'
            placeholder='Code de vérification*'
            required
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
          />
          <br />
          {!formData.code ? null : (
            <>
              <input
                name='name'
                type='text'
                placeholder='Nom complet'
                onChange={handleInputChange}
              />
              <br />

              <input
                id='pseudo'
                name='pseudo'
                minLength={3}
                type='text'
                placeholder='Pseudo*'
                required
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
              />
              <div className={styles.callbackMessageContainer}>
                {messageValidPseudo === 'noValid' ? (
                  <p className={styles.failed}>Pseudo invalid.</p>
                ) : (
                  ''
                )}
              </div>

              <input
                name='phone'
                type='phone'
                placeholder='Téléphone'
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
              />
              <br />

              <div className={styles.passwordInput}>
                <input
                  name='password'
                  minLength={6}
                  type={visibilityPassword ? 'text' : 'password'}
                  placeholder='Mot de passe* (6 crtès minimum)'
                  required
                  onChange={handleInputChange}
                />
                <div>
                  {visibilityPassword ? (
                    <FontAwesomeIcon
                      onClick={handleVisibilityPassword}
                      icon={faEyeSlash}
                    />
                  ) : (
                    <FontAwesomeIcon
                      onClick={handleVisibilityPassword}
                      icon={faEye}
                    />
                  )}
                </div>
              </div>

              <p>
                En créant votre compte vous attesté avoir lu et accepté nos{' '}
                <Link to='/conditions-dutilisation'>
                  conditions générales d'utilisation.
                </Link>
              </p>
              <button type='submit'>
                {loading === 'createAccount'
                  ? 'Création du compte...'
                  : 'Créer mon compte'}
              </button>
            </>
          )}
        </form>
      </div>
      <NotifFeedBackFecth
        modifAuthorizeValue={modifAuthorize}
        callBackMessageValue={callBackMessage}
        messageFecthValue={messageFecth}
      />
    </>
  )
}

export default FormInscription
