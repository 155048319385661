import styles from './index.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faLocationDot,
  faHeart,
  faPlusCircle,
} from '@fortawesome/free-solid-svg-icons'
import HeartIcone from '../../../assets/icone/heart-icon.svg'
import { useEffect, useState } from 'react'
import Cookies from 'js-cookie'
import { fecthFavourite } from '../../../apiUtils/FavouriteUser'
import { fecthStat } from '../../../apiUtils/StatsUser'
import ComponentAuthRequired from '../../../middlewares/ComponentAuthRequired/ComponentAuthRequired'
import { Link } from 'react-router-dom'

function FirstSection({
  urlProfile,
  urlBanniere,
  name,
  pseudo,
  statut,
  localisation,
  subscription,
}) {
  const LogCookies = Cookies.get('_influencapp_tk_log')
  const pseudoUserAuth = Cookies.get('_influencapp_user_pseudo')

  let token

  if (LogCookies !== undefined && LogCookies.length > 50) {
    token = Cookies.get('_influencapp_tk_log')
  } else {
    token = undefined
  }

  const [favorize, setFavorize] = useState(false)
  function disLike() {
    setFavorize(false)
    fecthStat('inFavorize', pseudo, 'dislike')
    fecthFavourite(pseudo, 'disliked')
  }
  function like() {
    setFavorize(true)
    fecthStat('inFavorize', pseudo, 'like')
    fecthFavourite(pseudo, 'liked')
  }

  useEffect(() => {
    if (pseudoUserAuth && !(pseudoUserAuth === pseudo)) {
      if (token !== undefined) {
        const fetchData = async () => {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/user/in-favourites?pseudo=${pseudo}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            },
          )
          if (response.ok) {
            const result = await response.json()
            if (result.inFavourites) {
              setFavorize(true)
            } else {
              setFavorize(false)
            }
          } else {
            setFavorize(false)
          }
        }
        fetchData()
      }
    }
  }, [pseudoUserAuth, pseudo, token])

  return (
    <>
      <div className={`${styles.banniere}`}>
        {urlBanniere && urlBanniere !== undefined ? (
          <img
            src={`${process.env.REACT_APP_URL_BASE_IMAGE}${urlBanniere}`}
            alt={`Couverture de ${pseudo}`}
          />
        ) : null}
      </div>

      <div className={styles.firstDetail}>
        <div className={styles.photoProfile}>
          {urlProfile && urlProfile !== undefined ? (
            <img
              src={`${process.env.REACT_APP_URL_BASE_IMAGE}${urlProfile}`}
              alt={`Profile de ${pseudo}`}
            />
          ) : null}
        </div>
        <div>
          <div className={styles.profileNameContainer}>
            <h2>{name ? `${name}` : pseudo}</h2>
            {subscription && subscription !== 'null' && (
              <div
                className={styles.badgesPro}
                style={
                  subscription === 'ultimate'
                    ? { width: '65px', backgroundColor: '#FF8B49' }
                    : subscription === 'premium'
                      ? { width: '60px', backgroundColor: '#FFAB7B' }
                      : //   : subscription === 'standard'
                        //     ? { width: '50px', backgroundColor: '#FFC65A' }
                        {}
                }
              >
                {subscription !== 'standard' && <p>PRO</p>}
                {(subscription === 'premium' ||
                  subscription === 'ultimate') && (
                  <FontAwesomeIcon
                    style={{ marginLeft: '4px' }}
                    icon={faPlusCircle}
                  />
                )}
                {subscription === 'ultimate' && (
                  <FontAwesomeIcon
                    style={{ marginLeft: '-4px' }}
                    icon={faPlusCircle}
                  />
                )}
              </div>
            )}
          </div>
          <h3>{statut}</h3>
          {localisation ? (
            <div className={styles.localisation}>
              <FontAwesomeIcon icon={faLocationDot} />
              <h4>{localisation}</h4>
            </div>
          ) : (
            ''
          )}
        </div>

        <div className={styles.callToAction}>
          <ComponentAuthRequired valueDefault={null}>
            {pseudoUserAuth && pseudoUserAuth !== pseudo ? (
              <div>
                {favorize ? "J'aime déjà" : "J'aime"}
                <p className={styles.heartIcon}>
                  <img
                    onClick={like}
                    src={HeartIcone}
                    alt='heart-icon'
                    title='Ajouter aux favoris'
                  />
                  {favorize ? (
                    <FontAwesomeIcon
                      onClick={disLike}
                      icon={faHeart}
                      title='Retirer des favoris'
                    />
                  ) : (
                    ''
                  )}
                </p>
              </div>
            ) : pseudoUserAuth && pseudoUserAuth === pseudo ? (
              <Link to='/my-account'>
                <div className={styles.editProfileBtn}>Modifier mon profil</div>
              </Link>
            ) : null}
          </ComponentAuthRequired>
        </div>
      </div>
    </>
  )
}

export default FirstSection
