import React from 'react'

function PolitiqueDeConfidentialite() {
  return (
    <>
        <h2 className='titlePage'>Politique de confidentialité</h2>
    </>
  )
}

export default PolitiqueDeConfidentialite