import { createBrowserRouter } from 'react-router-dom'
import App from './App'

// composants de pages
import Accueil from './pages/Accueil/Accueil'
import Connexion from './pages/connexion/connexion'
import Inscription from './pages/inscription/inscription'
import FormInscription from './pages/inscription/form-inscription/form-inscription'
import ResetPassword from './pages/mot-de-passe-oublie/resetPassword'
import MonCompte from './pages/mon-compte/monCompte'
import EditLogInfo from './pages/edit-log-info/edit-log-info'
import Profile from './pages/profile/profile'
import AllProfiles from './pages/tous-les-profils/allProfiles'
// import MyFavourites from './pages/mes-favoris/mesFavoris'
// import MesReseaux from './pages/mes-reseaux/mes-reseaux';
// import ReseauxUser from './pages/reseaux-user/reseauxUser';
import CreerUnProjet from './pages/creer-un-project/createProject'
// import TousLesProjets from './pages/tous-les-projets/allProjects'
import Project from './pages/projet/Project'
import MyProjects from './pages/mes-projets/mesProjets'
import EditProject from './pages/modifier-un-projet/editProject'
// import Offer from './pages/offer/offer'
// import MyOffersSend from './pages/mes-offres-envoyees/mesOffresEnvoyees'
import MySubscription from './pages/mon-abonnement/MySubscription'
import AideFaq from './pages/aide-faq/aide&Faq'
import NosTarifs from './pages/nos-tarifs/nosTarifs'
import CommentCaMarche from './pages/comment-ca-marche/commentCaMarche'
import ConditionDutilisation from './pages/condition-dutilisation/conditionDutilisation'
import PolitiqueDeConfidentialite from './pages/politique-de-confidentialite/politiqueDeConfidentialite'
import MentionsLegales from './pages/mentions-legales/mentionsLegales'
import AvisInfluencapp from './pages/avis-influencapp/avisInfluencapp'
import APropos from './pages/a-propos/aPropos'
import Partenaires from './pages/partenaires/partenaires'
import NousContacter from './pages/nous-contacter/nousContacter'
import ProfilesNiches from './pages/tous-profils-par-niche/profilesNiches'
import Messages from './pages/messages-reçus/messagesReçus'
import PaymentSuccess from './pages/Payment/Payment'

// middlewares de vérification
import StatutUserForRegister from './middlewares/StatutUserForRegister/StatutUserForRegister'
import AuthRequired from './middlewares/AuthRequired/AuthRequired'
import VerifLogActif from './middlewares/VerifLogActif/VerifLogActif'

// page d'erreur
import NotFound from './not-found'

export const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '/*',
        caseSensitive: true,
        element: <NotFound />,
      },
      {
        path: '/',
        element: <Accueil />,
      },
      {
        path: '/connexion',
        caseSensitive: true,
        element: (
          <VerifLogActif>
            {' '}
            <Connexion />{' '}
          </VerifLogActif>
        ),
      },
      {
        path: '/inscription',
        caseSensitive: true,
        element: (
          <VerifLogActif>
            {' '}
            <Inscription />{' '}
          </VerifLogActif>
        ),
      },
      {
        path: '/form-inscription',
        caseSensitive: true,
        element: (
          <StatutUserForRegister>
            {' '}
            <FormInscription />{' '}
          </StatutUserForRegister>
        ),
      },
      {
        path: '/reset-password',
        caseSensitive: true,
        element: <ResetPassword />,
      },
      {
        path: '/my-account',
        caseSensitive: true,
        element: (
          <AuthRequired>
            {' '}
            <MonCompte />{' '}
          </AuthRequired>
        ),
      },
      {
        path: '/edit-log-info',
        caseSensitive: true,
        element: (
          <AuthRequired>
            {' '}
            <EditLogInfo />{' '}
          </AuthRequired>
        ),
      },
      {
        path: '/profile/:user',
        caseSensitive: true,
        element: <Profile />,
      },
      {
        path: '/all-profiles',
        caseSensitive: true,
        element: <AllProfiles />,
      },
      // {
      //   path: '/my-favourites',
      //   caseSensitive: true,
      //   element: (
      //     <AuthRequired>
      //       {' '}
      //       <MyFavourites />{' '}
      //     </AuthRequired>
      //   ),
      // },
      // {
      //   path: '/my-social-net',
      //   caseSensitive: true,
      //   element: <AuthRequired> <MesReseaux /> </AuthRequired>,
      // },
      // {
      //   path: '/socials/:user',
      //   caseSensitive: true,
      //   element: <AuthRequired> <ReseauxUser /> </AuthRequired>,
      // },
      {
        path: '/create-project',
        caseSensitive: true,
        element: (
          <AuthRequired>
            {' '}
            <CreerUnProjet />{' '}
          </AuthRequired>
        ),
      },
      // {
      //   path: '/projects',
      //   caseSensitive: true,
      //   element: (
      //     <AuthRequired>
      //       {' '}
      //       <TousLesProjets />{' '}
      //     </AuthRequired>
      //   ),
      // },
      {
        path: '/project/:id',
        caseSensitive: true,
        element: <Project />,
      },
      {
        path: '/my-projects',
        caseSensitive: true,
        element: (
          <AuthRequired>
            {' '}
            <MyProjects />{' '}
          </AuthRequired>
        ),
      },
      {
        path: '/edit-project/:id',
        caseSensitive: true,
        element: (
          <AuthRequired>
            {' '}
            <EditProject />{' '}
          </AuthRequired>
        ),
      },
      // {
      //   path: '/offer/:id/:projectId',
      //   caseSensitive: true,
      //   element: (
      //     <AuthRequired>
      //       {' '}
      //       <Offer />{' '}
      //     </AuthRequired>
      //   ),
      // },
      // {
      //   path: '/offers-send',
      //   caseSensitive: true,
      //   element: (
      //     <AuthRequired>
      //       {' '}
      //       <MyOffersSend />{' '}
      //     </AuthRequired>
      //   ),
      // },
      {
        path: '/my-subscription',
        caseSensitive: true,
        element: <MySubscription />,
      },
      {
        path: '/aide',
        caseSensitive: true,
        element: <AideFaq />,
      },
      {
        path: '/nos-tarifs',
        caseSensitive: true,
        element: <NosTarifs />,
      },
      {
        path: '/comment-ca-marche',
        caseSensitive: true,
        element: <CommentCaMarche />,
      },
      {
        path: '/conditions-dutilisation',
        caseSensitive: true,
        element: <ConditionDutilisation />,
      },
      {
        path: '/politique-de-confidentialite',
        caseSensitive: true,
        element: <PolitiqueDeConfidentialite />,
      },
      {
        path: '/mentions-legales',
        caseSensitive: true,
        element: <MentionsLegales />,
      },
      {
        path: '/mentions-legales',
        caseSensitive: true,
        element: <MentionsLegales />,
      },
      {
        path: '/les-avis-influencapp',
        caseSensitive: true,
        element: <AvisInfluencapp />,
      },
      {
        path: '/a-propos',
        caseSensitive: true,
        element: <APropos />,
      },
      {
        path: '/partenaires',
        caseSensitive: true,
        element: <Partenaires />,
      },
      {
        path: '/nous-contacter',
        caseSensitive: true,
        element: <NousContacter />,
      },
      {
        path: '/profile-secteur/:secteur',
        caseSensitive: true,
        element: <ProfilesNiches />,
      },
      {
        path: '/messages',
        caseSensitive: true,
        element: (
          <AuthRequired>
            {' '}
            <Messages />{' '}
          </AuthRequired>
        ),
      },
      {
        path: '/payment/:session_id',
        caseSensitive: true,
        element: <PaymentSuccess />,
      },
    ],
  },
])
