import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import NotifFeedBackFecth from '../../../components/NotifFeedBackFecth/NotifFeedBackFecth';
import styles from "./VideoPrez.module.scss";
import YouTube from 'react-youtube';

function VideoPrez({ idVideo }) {
    const token = Cookies.get("_influencapp_tk_log");
    const [idYoutube, setIdYoutube] = useState("");
    const [loading, setLoading] = useState("");

    const [modifAuthorize, setModifAuthorize] = useState("");
    const [messageFecth, setMessageFecth] = useState("");
    const [callBackMessage, setCallBackMessage] = useState("");

    // pour eviter un espace vide dans un champs
    const handleKeyDown = (e) => {
        if (e.key === " ") {
          e.preventDefault();
        }
    };

    function resetFeedback (){
        setTimeout(()=>{
            setModifAuthorize("");
            setMessageFecth("");
            setCallBackMessage("");
        }, 6000)
    }

    const updateId = async(context) =>{
        setLoading(context);

        const raw = JSON.stringify({
            id : idYoutube
        });

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/user/video-prez`, {
                method : "PATCH",
                headers : {
                    Authorization : `Bearer ${token}`,
                    "Content-Type" : "application/json"
                },
                body : raw,
            });

            if(response.ok){
                setTimeout(()=>{
                    setLoading("");
                    setModifAuthorize(true);
                    setCallBackMessage("flex");
                    setMessageFecth("Enregistement effectué avec succès.");

                    resetFeedback();
                }, 1000);
            } else{
                setTimeout(()=>{
                    setLoading("");
                    setModifAuthorize("error");
                    setCallBackMessage("flex");
                    setMessageFecth("Oups, une erreur s'est produite, veuillez réessayer ultérieurement.");

                    resetFeedback();
                }, 1000);
            }
        } catch (error) {
            console.log("Error de la requette fecth", error);
            setLoading("");
            setModifAuthorize("error");
            setCallBackMessage("flex");
            setMessageFecth("Oups, une erreur s'est produite, veuillez réessayer ultérieurement.");

            resetFeedback();
        }
    }

    useEffect(() => {
        if(idVideo){
            setIdYoutube(idVideo);
        }
    }, [idVideo]);

    return (
        <>
            <div className={styles.allContainer}>
                <p>Ajoutez une vidéo de présentation en avant.</p>
                <input placeholder='Entrez un id de vidéo youtube' type="text" onChange={(e)=>setIdYoutube(e.target.value)} onKeyDown={handleKeyDown} value={idYoutube} required />
                {idYoutube && <div className={styles.videoContainer}><YouTube videoId={idYoutube} /></div>}
                <div className={styles.button}>
                    <div className={styles.save} onClick={()=>{ updateId("update") }} type='submit'>{loading === "update" ? "Enregistrement..." : "Enregistré"}</div>
                </div>
            </div>
            <NotifFeedBackFecth modifAuthorizeValue={modifAuthorize} callBackMessageValue={callBackMessage} messageFecthValue={messageFecth} />
        </>
    )
}

export default VideoPrez