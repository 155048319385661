import styles from './mesProjets.module.scss'
import CardProject from '../../components/CardProject/CardProject'
import { useEffect, useMemo, useState } from 'react'
import Cookies from 'js-cookie'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAngleRight,
  faFilter,
  faSearch,
} from '@fortawesome/free-solid-svg-icons'
import GifLoading from '../../components/GifLoading/GifLoading'
import GifFecthLoad from '../../components/GifFecthLoad/GifFecthLoad'
import { useNavigate } from 'react-router-dom'
import NotifFeedBackFecth from '../../components/NotifFeedBackFecth/NotifFeedBackFecth'
import ConfirmationRequired from '../../components/ConfirmationRequired/ConfirmationRequired'

function MesProjets() {
  const navigate = useNavigate()
  const [reload, setReload] = useState(false)
  const [data, setData] = useState([])
  const [page, setPage] = useState(1)
  const [pageSize] = useState(2)
  const [loading, setLoading] = useState('fecthLoad')
  const [hasMore, setHasMore] = useState(false)
  const [fecthUrl, setFecthUrl] = useState(
    `${process.env.REACT_APP_API_URL}/project/my-projects?filter&page=1&pageSize=2`,
  )
  const [confirmationContainer, setConfirmationContainer] = useState(false)
  const [idProjectClose, setIdProjectClose] = useState('')

  // Container de confirmation de la fermeture d'un projet
  const ShowConfirmationContainer = () => {
    setConfirmationContainer(!confirmationContainer)
    setIdProjectClose('')
  }

  const ConfirmationModif = async () => {
    await CloseProject(idProjectClose)
    setConfirmationContainer(!confirmationContainer)
    setIdProjectClose('')
  }
  //

  // les constante pour gerer la recherche et filtre des projets
  const [projectSearch, setProjectSearch] = useState('')
  const [filtreProfil, setFiltreProfil] = useState('')
  const [triPar, setTriPar] = useState('&triPar=croissant')

  const [filtrePublish, setFiltrePublish] = useState(false)
  const [filtreClose, setFiltreClose] = useState(false)
  const [filtreAttribute, setFiltreAttribute] = useState(false)
  const [filtreSave, setFiltreSave] = useState(false)

  const [projectmoins500, setProjectmoins500] = useState(false)
  const [project500a1k, setProject500a1k] = useState(false)
  const [project1ka10k, setProject1ka10k] = useState(false)
  const [projectplus10k, setProjectplus10k] = useState(false)
  //

  // Les constantes pour la gestion des messages feedBack
  const [modifAuthorize, setModifAuthorize] = useState('')
  const [messageFecth, setMessageFecth] = useState('')
  const [callBackMessage, setCallBackMessage] = useState('')

  // Function de reset des messages feedBack
  function resetFeedBack() {
    setTimeout(() => {
      setModifAuthorize('')
      setMessageFecth('')
      setCallBackMessage('')
    }, 3000)
  }
  //

  // Filtre par nom de projet
  const projectSearchFunction = (e) => {
    if (e.target.value !== '' && e.target.value !== null) {
      setProjectSearch(`&projectName=${e.target.value}`)
    } else {
      setProjectSearch('')
    }
    setPage(1)
  }
  //

  // filtre par type de profils
  const FiltreParProfil = (e) => {
    if (e.target.value !== 'Tous') {
      setFiltreProfil(`&profil=${e.target.value}`)
    } else {
      setFiltreProfil('')
    }
  }
  //

  // Tri par ordre croisant ou decroissant
  const FunctionTriPar = (e) => {
    setTriPar(`&triPar=${e.target.value}`)
  }
  //

  // Filtre par catégorie de projet
  const FiltreParProjectPublish = () => {
    setFiltrePublish(!filtrePublish)
  }

  const FiltreParProjectClose = () => {
    setFiltreClose(!filtreClose)
  }

  const FiltreParProjectAttribute = () => {
    setFiltreAttribute(!filtreAttribute)
  }

  const FiltreParProjectSave = () => {
    setFiltreSave(!filtreSave)
  }
  //

  // Filtre par budget
  const FiltreParProjectmoins500 = () => {
    setProjectmoins500(!projectmoins500)
  }

  const FiltreParProject500a1k = () => {
    setProject500a1k(!project500a1k)
  }

  const FiltreParProject1ka10k = () => {
    setProject1ka10k(!project1ka10k)
  }

  const FiltreParProjectplus10k = () => {
    setProjectplus10k(!projectplus10k)
  }
  //

  // function de création du lien de la requette de recherche ou filtre
  const NewUrlFecth = (e, onlyProfil = null) => {
    if (e) {
      e.preventDefault()
    }
    setLoading('filtre')
    let newUrl = `${process.env.REACT_APP_API_URL}/project/my-projects?filter`
    if (projectSearch !== '') {
      newUrl = `${newUrl}${projectSearch}`
    }
    if (onlyProfil || filtreProfil !== '') {
      // on effectue le filtre de onlyProfil ici lors du filtre depuis la LeftContent sur les grand ecran.
      newUrl = `${newUrl}${onlyProfil ? onlyProfil : filtreProfil}`
    }
    if (triPar !== '') {
      newUrl = `${newUrl}${triPar}`
    }
    if (filtrePublish === true) {
      newUrl = `${newUrl}${`&publish=${filtrePublish}`}`
    }
    if (filtreClose === true) {
      newUrl = `${newUrl}${`&close=${filtreClose}`}`
    }
    if (filtreAttribute === true) {
      newUrl = `${newUrl}${`&attribute=${filtreAttribute}`}`
    }
    if (filtreSave === true) {
      newUrl = `${newUrl}${`&save=${filtreSave}`}`
    }
    if (projectmoins500 === true) {
      newUrl = `${newUrl}${`&budgetMoins500=${projectmoins500}`}`
    }
    if (project500a1k === true) {
      newUrl = `${newUrl}${`&budget500a1k=${project500a1k}`}`
    }
    if (project1ka10k === true) {
      newUrl = `${newUrl}${`&budget1ka10k=${project1ka10k}`}`
    }
    if (projectplus10k === true) {
      newUrl = `${newUrl}${`&budgetplus10k=${projectplus10k}`}`
    }

    setTimeout(() => {
      setLoading('')
      setFecthUrl(`${newUrl}&page=1&pageSize=2`)
      setPage(1)
      setShowFilter(false)
    }, 300)
  }

  // Reinitialisation du filtre
  const ResetFilter = (e) => {
    setLoading('filtre')
    setTimeout(() => {
      setLoading('')
      e.preventDefault()
      setPage(1)
      setShowFilter(false)
      setFiltreProfil('')
      setTriPar('')
      setFiltrePublish(false)
      setFiltreClose(false)
      setFiltreAttribute(false)
      setFiltreSave(false)
      setProjectmoins500(false)
      setProject500a1k(false)
      setProject1ka10k(false)
      setProjectplus10k(false)
      const newUrl = `${process.env.REACT_APP_API_URL}/project/my-projects?filter`
      setFecthUrl(`${newUrl}&page=1&pageSize=2`)
    }, 2000)
  }
  //

  // La function pour fermer un projet
  const CloseProject = async (idProject) => {
    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Authorization', `Bearer ${tokenLog}`)

    const requestOptions = {
      method: 'PATCH',
      headers: myHeaders,
      body: JSON.stringify({ id: idProject }),
    }

    setLoading(`closeProject${idProject}`)
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/project/close`,
        requestOptions,
      )
      if (response.ok) {
        setTimeout(() => {
          setReload(!reload)
          setLoading('')
          setModifAuthorize(true)
          setCallBackMessage('flex')
          setMessageFecth('Projet fermé avec succès.')

          resetFeedBack()
        }, 2000)
      } else {
        setTimeout(() => {
          setLoading('')
          setModifAuthorize('error')
          setCallBackMessage('flex')
          setMessageFecth(
            "Une erreur s'est produite, veuillez réessayer ultérieurement.",
          )

          resetFeedBack()
        }, 2000)
      }
    } catch (error) {
      console.log('error lors de la requette fecth', error)
      setLoading('')
      setModifAuthorize('error')
      setCallBackMessage('flex')
      setMessageFecth(
        "Une erreur s'est produite, veuillez réessayer ultérieurement.",
      )

      resetFeedBack()
    }
  }
  //

  // constante et function pour gerer l'affichage de la section de filtre
  const [showFilter, setShowFilter] = useState(false)
  const displayFilterContainer = () => {
    setShowFilter(!showFilter)
  }

  // On englobe resquestOptions dans useMemo pour eviter de le recalculer si tokenLog ne change pas
  const tokenLog = Cookies.get('_influencapp_tk_log')
  const resquestOptions = useMemo(
    () => ({
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${tokenLog}`,
      },
    }),
    [tokenLog],
  )

  // La fonction pour faire un fecth en recupérant des user en paginant la reponse.
  const loadMoreData = async () => {
    setLoading('seeMore')

    try {
      const response = await fetch(
        `${fecthUrl.split('&page')[0]}&page=${page + 1}&pageSize=${pageSize}`,
        resquestOptions,
      )
      if (!response.ok) {
        throw new Error(`Erreur HTTP : ${response.status}`)
      }
      const result = await response.json()
      setTimeout(() => {
        const { projects, hasMore } = result
        setData([...data, ...projects])
        setHasMore(hasMore)
        setPage(page + 1)
        setLoading('')
      }, 1000)
    } catch (error) {
      setLoading('')
      console.log('error lors de la requette fecth', error)
    }
  }

  // Le premier rendu de la pagination
  useEffect(() => {
    setLoading('fecthLoad')
    const fetchInitialData = async () => {
      try {
        const response = await fetch(fecthUrl, resquestOptions)
        if (!response.ok) {
          setLoading('')
          throw new Error(`Erreur HTTP : ${response.status}`)
        }
        const result = await response.json()
        const { projects, hasMore } = result
        setData(projects)
        setHasMore(hasMore)
        setLoading('')
      } catch (error) {
        setLoading('')
        console.log('error lors de la requette fecth', error)
      }
    }

    fetchInitialData()
  }, [resquestOptions, fecthUrl, reload])

  return (
    <>
      <div className={`${styles.allContainer}`}>
        {loading === 'filtre' ? <GifLoading positionDiv='fixed' /> : null}
        <h2 className={`titlePage`}>Tous mes projets créer</h2>

        <div className={styles.widgetContent}>
          <div className={styles.LeftContent}>
            <h3>Filtrer par catégorie</h3>
            <div>
              <p
                onClick={() => {
                  setFiltreProfil('')
                  NewUrlFecth(null, '&profil=')
                }}
                style={
                  filtreProfil.split('&profil=')[1] === undefined ||
                  filtreProfil.split('&profil=')[1] === 'Tous'
                    ? { color: '#E87213' }
                    : {}
                }
              >
                <FontAwesomeIcon icon={faAngleRight} />
                Tous les catégories
              </p>

              <p
                onClick={() => {
                  setFiltreProfil('&profil=Influenceur')
                  NewUrlFecth(null, '&profil=Influenceur')
                }}
                style={
                  filtreProfil.split('&profil=')[1] === 'Influenceur'
                    ? { color: '#E87213' }
                    : {}
                }
              >
                <FontAwesomeIcon icon={faAngleRight} />
                Influenceur
              </p>

              <p
                onClick={() => {
                  setFiltreProfil('&profil=Créateur de contenu')
                  NewUrlFecth(null, '&profil=Créateur de contenu')
                }}
                style={
                  filtreProfil.split('&profil=')[1] === 'Créateur de contenu'
                    ? { color: '#E87213' }
                    : {}
                }
              >
                <FontAwesomeIcon icon={faAngleRight} />
                Créateur de contenu
              </p>

              <p
                onClick={() => {
                  setFiltreProfil('&profil=Youtubeur')
                  NewUrlFecth(null, '&profil=Youtubeur')
                }}
                style={
                  filtreProfil.split('&profil=')[1] === 'Youtubeur'
                    ? { color: '#E87213' }
                    : {}
                }
              >
                <FontAwesomeIcon icon={faAngleRight} />
                Youtubeur
              </p>

              <p
                onClick={() => {
                  setFiltreProfil('&profil=Agence')
                  NewUrlFecth(null, '&profil=Agence')
                }}
                style={
                  filtreProfil.split('&profil=')[1] === 'Agence'
                    ? { color: '#E87213' }
                    : {}
                }
              >
                <FontAwesomeIcon icon={faAngleRight} />
                Agence
              </p>

              <p
                onClick={() => {
                  setFiltreProfil('&profil=Agent')
                  NewUrlFecth(null, '&profil=Agent')
                }}
                style={
                  filtreProfil.split('&profil=')[1] === 'Agent'
                    ? { color: '#E87213' }
                    : {}
                }
              >
                <FontAwesomeIcon icon={faAngleRight} />
                Agent
              </p>

              <p
                onClick={() => {
                  setFiltreProfil('&profil=Marque')
                  NewUrlFecth(null, '&profil=Marque')
                }}
                style={
                  filtreProfil.split('&profil=')[1] === 'Marque'
                    ? { color: '#E87213' }
                    : {}
                }
              >
                <FontAwesomeIcon icon={faAngleRight} />
                Marque
              </p>
            </div>
          </div>

          <div className={styles.RightContent}>
            {/* input de recherhe et icone d'affichage de la section de filtre  */}
            <div className={styles.searhEfilterContainer}>
              <form onSubmit={NewUrlFecth} className={styles.containerSearch}>
                <div className={styles.champSearchProject}>
                  <label htmlFor='searchProject'>
                    <FontAwesomeIcon icon={faSearch} />
                  </label>
                  <input
                    onChange={projectSearchFunction}
                    id='searchProject'
                    type='text'
                    placeholder='rechercher un projet...'
                  />
                </div>
                <button type='submit' className={styles.btnSubmitSearch}>
                  <FontAwesomeIcon icon={faSearch} />
                </button>
              </form>

              <div
                onClick={displayFilterContainer}
                className={styles.btnFilter}
              >
                <FontAwesomeIcon icon={faFilter} />
              </div>
            </div>

            {/* section filtre  */}
            {showFilter ? (
              <div className={styles.allContainerFilter}>
                <div className={styles.containerFilter}>
                  <div className={styles.queryFilter}>
                    {/* filtre par type de profil */}
                    <div className={`w_inf_1080 ${styles.oneSectionFilter}`}>
                      <h3>Filtrer par profil</h3>
                      <label onClick={FiltreParProfil}>
                        <input
                          type='radio'
                          name='profil'
                          value='Tous'
                          defaultChecked={
                            filtreProfil.split('&profil=')[1] !== undefined
                              ? filtreProfil.split('&profil=')[1] === 'Tous'
                              : true
                          }
                        />
                        Tous
                      </label>{' '}
                      <br />
                      <label onClick={FiltreParProfil}>
                        <input
                          type='radio'
                          name='profil'
                          value='Créateur de contenu'
                          defaultChecked={
                            filtreProfil.split('&profil=')[1] ===
                            'Créateur de contenu'
                          }
                        />
                        Créateur de contenu
                      </label>{' '}
                      <br />
                      <label onClick={FiltreParProfil}>
                        <input
                          type='radio'
                          name='profil'
                          value='Influenceur'
                          defaultChecked={
                            filtreProfil.split('&profil=')[1] === 'Influenceur'
                          }
                        />
                        Influenceur
                      </label>{' '}
                      <br />
                      <label onClick={FiltreParProfil}>
                        <input
                          type='radio'
                          name='profil'
                          value='Agent'
                          defaultChecked={
                            filtreProfil.split('&profil=')[1] === 'Agent'
                          }
                        />
                        Agent
                      </label>{' '}
                      <br />
                      <label onClick={FiltreParProfil}>
                        <input
                          type='radio'
                          name='profil'
                          value='Agence'
                          defaultChecked={
                            filtreProfil.split('&profil=')[1] === 'Agence'
                          }
                        />
                        Agence
                      </label>{' '}
                      <br />
                      <label onClick={FiltreParProfil}>
                        <input
                          type='radio'
                          name='profil'
                          value='Acteur'
                          defaultChecked={
                            filtreProfil.split('&profil=')[1] === 'Acteur'
                          }
                        />
                        Acteur
                      </label>{' '}
                      <br />
                      <label onClick={FiltreParProfil}>
                        <input
                          type='radio'
                          name='profil'
                          value='Marque'
                          defaultChecked={
                            filtreProfil.split('&profil=')[1] === 'Marque'
                          }
                        />
                        Marque
                      </label>{' '}
                      <br />
                    </div>

                    {/* tri des projets par odre croisaant ou decroissant  */}
                    <div className={styles.oneSectionFilter}>
                      <h3>Ordre de tri</h3>
                      <label onClick={FunctionTriPar}>
                        <input
                          type='radio'
                          name='tri'
                          value='croissant'
                          defaultChecked={
                            triPar.split('&triPar=')[1] === 'croissant'
                          }
                        />
                        Les plus récents
                      </label>{' '}
                      <br />
                      <label onClick={FunctionTriPar}>
                        <input
                          type='radio'
                          name='tri'
                          value='decroissant'
                          defaultChecked={
                            triPar.split('&triPar=')[1] === 'decroissant'
                          }
                        />
                        Les plus anciens
                      </label>{' '}
                      <br />
                    </div>

                    {/* filtre par categorie de projet */}
                    <div className={styles.oneSectionFilter}>
                      <h3>Filtrer par catégorie</h3>
                      <label>
                        <input
                          onClick={FiltreParProjectPublish}
                          type='checkbox'
                          name='publish'
                          defaultChecked={filtrePublish}
                        />
                        Projets ouverts
                      </label>{' '}
                      <br />
                      <label>
                        <input
                          onClick={FiltreParProjectClose}
                          type='checkbox'
                          name='close'
                          defaultChecked={filtreClose}
                        />
                        Projets fermés
                      </label>{' '}
                      <br />
                      <label>
                        <input
                          onClick={FiltreParProjectAttribute}
                          type='checkbox'
                          name='attribute'
                          defaultChecked={filtreAttribute}
                        />
                        Projets attribués
                      </label>{' '}
                      <br />
                      <label>
                        <input
                          onClick={FiltreParProjectSave}
                          type='checkbox'
                          name='save'
                          defaultChecked={filtreSave}
                        />
                        Projets enregistrés
                      </label>{' '}
                      <br />
                    </div>

                    {/* filtre par budget */}
                    <div className={styles.oneSectionFilter}>
                      <h3>Filtrer par budget</h3>
                      <label>
                        <input
                          onClick={FiltreParProjectmoins500}
                          type='checkbox'
                          name='moins500'
                          defaultChecked={projectmoins500}
                        />
                        Moins de 500 €
                      </label>{' '}
                      <br />
                      <label>
                        <input
                          onClick={FiltreParProject500a1k}
                          type='checkbox'
                          name='500a1k'
                          defaultChecked={project500a1k}
                        />
                        500 € à 1 000 €
                      </label>{' '}
                      <br />
                      <label>
                        <input
                          onClick={FiltreParProject1ka10k}
                          type='checkbox'
                          name='1ka10k'
                          defaultChecked={project1ka10k}
                        />
                        1 000 € à 10 000 €
                      </label>{' '}
                      <br />
                      <label>
                        <input
                          onClick={FiltreParProjectplus10k}
                          type='checkbox'
                          name='plus10k'
                          defaultChecked={projectplus10k}
                        />
                        Plus de 10 000 €
                      </label>{' '}
                      <br />
                    </div>
                  </div>

                  <div className={styles.btnFilter}>
                    <div onClick={NewUrlFecth}>Appliquer</div>
                    <div onClick={ResetFilter}>Réinitialiser</div>
                  </div>
                </div>
              </div>
            ) : null}

            {/* liste des projets  */}
            {loading === 'fecthLoad' ? (
              <GifFecthLoad positionDiv='fixed' />
            ) : (
              <>
                <div className={styles.cardContainer}>
                  {data.map((project) => (
                    <div key={project?._id}>
                      <CardProject
                        id={project?._id}
                        title={project?.title}
                        description={project?.description}
                        statut={project?.statut}
                        budget={project?.budget}
                        profilSearch={project?.profilSearch}
                        views={project?.statistique?.views}
                        totalOffers={
                          (project?.statistique?.totaleOffers).length !==
                          undefined
                            ? (project?.statistique?.totaleOffers).length
                            : 0
                        }
                        dateCrea={project?.dateCrea}
                      />

                      <div className={styles.btnEditSeeOrClose}>
                        {/* { project?.statut === "save" ?  */}
                        <button
                          className={styles.btnEditProject}
                          onClick={(e) => {
                            e.preventDefault()
                            navigate(`/edit-project/${project?._id}`)
                          }}
                        >
                          Modifiier
                        </button>
                        {/* : null} */}

                        <button
                          className={styles.btnSeeProject}
                          onClick={(e) => {
                            e.preventDefault()
                            navigate(`/project/${project?._id}`)
                          }}
                        >
                          Voir{' '}
                          {project?.statut === 'publish'
                            ? 'les offres'
                            : 'le projet'}
                        </button>

                        {/* { project?.statut === "publish" ? 
                                                    <button className={styles.btnCloseProject} onClick={(e)=>{
                                                            e.preventDefault();
                                                            ShowConfirmationContainer();
                                                            setIdProjectClose(project?._id);
                                                        }}>
                                                        {loading === `closeProject${project?._id}` ? "Fermeture du projet..." : "Fermer le projet"}
                                                    </button>
                                                : null}   */}
                      </div>
                    </div>
                  ))}
                  {loading === 'fecthLoad' ? null : data.length > 0 ? null : (
                    <div className={styles.notFoundProject}>
                      Aucun projet trouver.
                    </div>
                  )}
                </div>

                {confirmationContainer && (
                  <ConfirmationRequired
                    contexte='La fermeture de ce projet est definif vous ne pourrez plus le reouvrir.'
                    confirmation={ConfirmationModif}
                    reset={ShowConfirmationContainer}
                  />
                )}

                {hasMore && (
                  <div className={styles.showMoreContainer}>
                    <button onClick={loadMoreData}>
                      {loading === 'seeMore'
                        ? 'Chargement...'
                        : 'Afficher plus'}
                    </button>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      <NotifFeedBackFecth
        modifAuthorizeValue={modifAuthorize}
        callBackMessageValue={callBackMessage}
        messageFecthValue={messageFecth}
      />
    </>
  )
}

export default MesProjets
