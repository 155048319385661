import React from 'react'
import styles from './LoadingAllSite.module.scss'
import GifLoadingPoint from '../../assets/images/loadingpoint.gif'
import LogoIcone from '../../assets/images/logo-icone-influencapp.png'

function LoadingAllSite() {
  return (
    <div className={styles.allContainer}>
      <h1 className={styles.logo}>
        <img src={LogoIcone} alt='Logo icone influencapp' />
      </h1>
      <p>
        <img src={GifLoadingPoint} alt='LoadingGift' />
      </p>
    </div>
  )
}

export default LoadingAllSite
