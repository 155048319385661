import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import styles from './CardMessage.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArchive, faStar } from '@fortawesome/free-solid-svg-icons'
import Cookies from 'js-cookie'
import NotifFeedBackFecth from '../NotifFeedBackFecth/NotifFeedBackFecth'

// Function de formatage de la date de création ou publication
function formatDeCreation(date) {
  const dateActuelle = new Date(date)
  const maintenant = new Date()
  const difference = maintenant - dateActuelle

  const minute = 60 * 1000
  const heure = minute * 60
  const jour = heure * 24
  const semaine = jour * 7
  const mois = jour * 30
  const annee = jour * 365

  if (difference < minute) {
    return '1 minute'
  } else if (difference < heure) {
    const minutes = Math.floor(difference / minute)
    return `${minutes} minute${minutes > 1 ? 's' : ''}`
  } else if (difference < jour) {
    const heures = Math.floor(difference / heure)
    return `${heures} heure${heures > 1 ? 's' : ''}`
  } else if (difference < semaine) {
    const jours = Math.floor(difference / jour)
    return `${jours} jour${jours > 1 ? 's' : ''}`
  } else if (difference < mois) {
    const semaines = Math.floor(difference / semaine)
    return `${semaines} semaine${semaines > 1 ? 's' : ''}`
  } else if (difference < annee) {
    const moisPasses = Math.floor(difference / mois)
    return `${moisPasses} mois`
  } else {
    const annees = Math.floor(difference / annee)
    return `${annees} an${annees > 1 ? 's' : ''}`
  }
}

function CardMessage({
  id,
  sender,
  project,
  idOffer,
  receiverRead,
  timestamp,
  statusRoom,
  refresh,
}) {
  const navigate = useNavigate()
  const [statusMessage, setStatusMessage] = useState('')
  const [messageFecth, setMessageFecth] = useState('')
  const [callBackMessage, setCallBackMessage] = useState('')
  const [modifAuthorize, setModifAuthorize] = useState('')

  const resetFeedBack = () => {
    setTimeout(() => {
      setModifAuthorize('')
      setCallBackMessage('')
      setMessageFecth('')
    }, 6000)
  }

  const HandleStatus = async (value) => {
    let status
    if (statusMessage !== '') {
      status = 'none'
    } else {
      status = value
    }

    const token = Cookies.get('_influencapp_tk_log')
    const raw = JSON.stringify({
      status: status,
      id: id,
    })

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/offer/message-status`,
        {
          method: 'PATCH',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: raw,
        },
      )
      if (response.ok) {
        if (statusMessage !== '') {
          setStatusMessage('')
          setModifAuthorize(true)
          setCallBackMessage('flex')
          setMessageFecth(
            value === 'favourite'
              ? 'Message retiré des favoris.'
              : value === 'archive'
                ? 'Message retiré des archives.'
                : null,
          )
        } else {
          setStatusMessage(value)
          setModifAuthorize(true)
          setCallBackMessage('flex')
          setMessageFecth(
            value === 'favourite'
              ? 'Message ajouté aux favoris.'
              : value === 'archive'
                ? 'Message archivé.'
                : null,
          )
        }

        if (value === 'archive') {
          setTimeout(() => {
            refresh()
          }, 1000)
        }

        resetFeedBack()
      } else {
        setModifAuthorize('error')
        setCallBackMessage('flex')
        setMessageFecth(
          "Oups, une erreur s'est produite, veuillez réessayer ultérieument.",
        )

        resetFeedBack()
      }
    } catch (error) {
      console.log('Erreur lors de la requette fectch', error)
      setModifAuthorize('error')
      setCallBackMessage('flex')
      setMessageFecth(
        "Oups, une erreur s'est produite, veuillez réessayer ultérieument.",
      )

      resetFeedBack()
    }
  }

  const HandleClick = () => {
    if (!receiverRead) {
      let messageNotRead = sessionStorage.getItem('notifMessage') - 1
      sessionStorage.setItem('notifMessage', messageNotRead)
    }
  }

  useEffect(() => {
    if (statusRoom && statusRoom !== 'none') {
      setStatusMessage(statusRoom)
    }
  }, [statusRoom])

  return (
    <>
      <div
        className={`fadinAnimation ${styles.allCardContainer}`}
        style={!receiverRead ? { backgroundColor: '#ffe3418f' } : {}}
      >
        <div
          onClick={() => navigate(`/offer/${idOffer}/${project._id}`)}
          className={styles.divVoid}
        ></div>
        <Link to={`/offer/${idOffer}/projectid=${project._id}`}>
          <div onClick={HandleClick} className={styles.allContainer}>
            <div className={styles.imageContainer}>
              {sender?._link_photo_profil?.url &&
              sender?._link_photo_profil?.url !== undefined ? (
                <img
                  src={`${process.env.REACT_APP_URL_BASE_IMAGE}${sender._link_photo_profil.url}`}
                  alt={`Profile de ${sender.pseudo}`}
                />
              ) : null}
            </div>
            <div>
              <div className={styles.profileNameContainer}>
                <p>
                  <span>{sender.pseudo}</span>
                </p>
              </div>
              <p>
                <span>Projet :</span> {project.title}
              </p>
              <div className={styles.timestamp} style={{ color: '#000' }}>
                {formatDeCreation(timestamp) === 'moins 1 minute' ? (
                  <p>Il y'a : moins 1 minuties</p>
                ) : (
                  <p>Il y'a : {formatDeCreation(timestamp)}</p>
                )}
              </div>
            </div>
          </div>
        </Link>
        <div className={styles.statusIconeContainer}>
          {(statusMessage === '' || statusMessage === 'favourite') && (
            <div onClick={() => HandleStatus('favourite')}>
              <FontAwesomeIcon
                style={
                  statusMessage === 'favourite' ? { color: '#E87213' } : {}
                }
                icon={faStar}
              />{' '}
            </div>
          )}
          {(statusMessage === '' || statusMessage === 'archive') && (
            <div onClick={() => HandleStatus('archive')}>
              <FontAwesomeIcon
                style={statusMessage === 'archive' ? { color: '#E87213' } : {}}
                icon={faArchive}
              />{' '}
            </div>
          )}
        </div>
      </div>

      <NotifFeedBackFecth
        modifAuthorizeValue={modifAuthorize}
        callBackMessageValue={callBackMessage}
        messageFecthValue={messageFecth}
      />
    </>
  )
}

export default CardMessage
