import styles from './CardProject.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEuro, faEye, faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom/dist'
import { useEffect, useState } from 'react'

// Function de formatage de la date de création ou publication
function formatDeCreation(date) {
  const dateActuelle = new Date(date)
  const maintenant = new Date()
  const difference = maintenant - dateActuelle

  const minute = 60 * 1000
  const heure = minute * 60
  const jour = heure * 24
  const semaine = jour * 7
  const mois = jour * 30
  const annee = jour * 365

  if (difference < minute) {
    return '1 minute'
  } else if (difference < heure) {
    const minutes = Math.floor(difference / minute)
    return `${minutes} minute${minutes > 1 ? 's' : ''}`
  } else if (difference < jour) {
    const heures = Math.floor(difference / heure)
    return `${heures} heure${heures > 1 ? 's' : ''}`
  } else if (difference < semaine) {
    const jours = Math.floor(difference / jour)
    return `${jours} jour${jours > 1 ? 's' : ''}`
  } else if (difference < mois) {
    const semaines = Math.floor(difference / semaine)
    return `${semaines} semaine${semaines > 1 ? 's' : ''}`
  } else if (difference < annee) {
    const moisPasses = Math.floor(difference / mois)
    return `${moisPasses} mois`
  } else {
    const annees = Math.floor(difference / annee)
    return `${annees} an${annees > 1 ? 's' : ''}`
  }
}

// function d'abreviation du nombre de vue ou nombre d'offres
function abreviationNombre(n) {
  if (n < 1000) {
    return n.toString()
  } else if (n < 1000000) {
    return (n / 1000).toFixed(1) + 'k'
  } else if (n < 1000000000) {
    return (n / 1000000).toFixed(1) + 'M'
  } else if (n < 1000000000000) {
    return (n / 1000000000).toFixed(1) + 'B'
  } else {
    return n.toString()
  }
}

function CardProject({
  id,
  dateCrea,
  title,
  description,
  statut,
  budget,
  profilSearch,
  views,
  totalOffers,
}) {
  const [dateCreation, setDateCreation] = useState('')

  useEffect(() => {
    const newFormat = formatDeCreation(dateCrea)
    setDateCreation(newFormat)
  }, [dateCrea])

  return (
    <>
      <Link className={styles.linkCard} to={`/project/${id}`}>
        <div key={id} className={`cardBackground ${styles.allContainer}`}>
          <div className={styles.firstSection}>
            <h3>{title}</h3>
            <div className={styles.statutEdateCrea}>
              <div
                style={
                  statut === 'publish'
                    ? { color: 'green' }
                    : statut === 'allocation'
                      ? { color: 'red' }
                      : statut === 'prePublish'
                        ? { color: '#E87213' }
                        : { color: 'gray' }
                }
              >
                <p
                  style={
                    statut === 'publish'
                      ? { backgroundColor: 'green' }
                      : statut === 'allocation'
                        ? { backgroundColor: 'red' }
                        : statut === 'prePublish'
                          ? { backgroundColor: '#E87213' }
                          : { backgroundColor: 'gray' }
                  }
                ></p>
                <span>
                  {statut === 'publish'
                    ? 'Ouvert'
                    : statut === 'allocation'
                      ? "Encours d'attribution"
                      : statut === 'save'
                        ? 'Enregistré, non publié'
                        : statut === 'close'
                          ? 'Fermé'
                          : statut === 'prePublish'
                            ? 'Encours de vérification'
                            : statut === 'attribute'
                              ? 'Attributé'
                              : ''}
                </span>
              </div>
              {statut !== 'prePublish' && (
                <p>
                  {statut === 'save' ? 'Créé' : 'Publiée'} il y'a :{' '}
                  <span>{dateCreation}</span>
                </p>
              )}
            </div>
          </div>

          <div className={styles.description}>
            {description !== undefined && description !== '' ? (
              <div>
                {description.substring(0, 196)}
                {description.length > 196 ? <span>...</span> : ''}
              </div>
            ) : (
              'Aucun détails renseigné.'
            )}
          </div>

          <div className={styles.statContainer}>
            <div className={styles.iconeContainer}>
              <p className={styles.icone}>
                <FontAwesomeIcon icon={faEuro} />
              </p>
              <p>{budget}</p>
            </div>

            <div className={styles.iconeContainer}>
              <p className={styles.icone}>
                <FontAwesomeIcon icon={faEye} />
              </p>
              <p>
                {abreviationNombre(views)} <span>fois</span>
              </p>
            </div>

            <div className={styles.iconeContainer}>
              <p className={styles.icone}>
                <FontAwesomeIcon icon={faPaperPlane} />
              </p>
              <p>
                {abreviationNombre(totalOffers)} <span>offres</span>
              </p>
            </div>
          </div>

          <div className={styles.profilsSearch}>
            {profilSearch.map((profil) => (
              <div key={profil}>
                <p className={styles.pointGris}></p> {profil}{' '}
              </div>
            ))}
          </div>
        </div>
      </Link>
    </>
  )
}

export default CardProject
