import React from 'react'
import styles from './InfoForDisableLink.module.scss'

function InfoForDisableLink({ reset }) {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.contentContainer}>
          <div className={styles.content}>
            <div>
              <h3>Non disponible</h3>
              <p>Cette page n'est pas encore disponible.</p>
              <div className={styles.buttonContainer}>
                <button onClick={reset}>Fermer</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default InfoForDisableLink
