import Cookies from "js-cookie";

export async function MessagesUser() {
    const token = Cookies.get('_influencapp_tk_log');  
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/offer/all-rooms-offer`, {
            headers : {Authorization : `Bearer ${token}`}
        })
        if(response.ok){
            const result = await response.json();
            const messageNotRead = result.roomsMessageOffer.filter((item) => item.receiverRead === false);
            sessionStorage.setItem("notifMessage", messageNotRead.length);
            return messageNotRead.length;
        }
    } catch (error) {
        console.log("Error lors de la requette fetch");
    }
}