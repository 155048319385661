import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'

import styles from './connexion.module.scss'
import TextRGPD from '../../components/LogTextRGPD/LogTextRGPD'
import NotifFeedBackFecth from '../../components/NotifFeedBackFecth/NotifFeedBackFecth'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'

function Connexion() {
  const [loading, setLoading] = useState('')
  const [modifAuthorize, setModifAuthorize] = useState('')
  const [messageFecth, setMessageFecth] = useState('')
  const [callBackMessage, setCallBackMessage] = useState('')
  const [visibilityPassword, setVisibilityPassword] = useState(false)

  const [formData, setFormData] = useState({
    pseudo: '',
    password: '',
  })
  const navigate = useNavigate()

  function resetFeedback() {
    setTimeout(() => {
      setModifAuthorize('')
      setMessageFecth('')
      setCallBackMessage('')
    }, 6000)
  }

  const handleVisibilityPassword = () => {
    setVisibilityPassword(!visibilityPassword)
  }

  // pour eviter un espace vide dans un champs
  const handleKeyDown = (e) => {
    if (e.key === ' ') {
      e.preventDefault()
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })

    setModifAuthorize('')
    setCallBackMessage('')
    setMessageFecth('')
  }

  const handleSubmit = async (e) => {
    setLoading('connexion')
    e.preventDefault()
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/user/login`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(formData),
        },
      )
      if (response.ok) {
        setTimeout(async () => {
          setLoading('')
          const user = await response.json()
          Cookies.set('_influencapp_tk_log', user.token, {
            expires: 1,
            sameSite: 'Strict',
          })
          navigate(`/profile/${user.pseudo}`)
        }, 2000)
      } else {
        setTimeout(async () => {
          setLoading('')
          setModifAuthorize('error')
          setCallBackMessage('flex')
          setMessageFecth('Utilisateur ou mot de passe incorrect !')

          resetFeedback()
        }, 1000)
      }
    } catch (error) {
      setTimeout(async () => {
        setLoading('')
        setModifAuthorize('error')
        setCallBackMessage('flex')
        setMessageFecth("Oups une erreur s'est produite !")
        console.error('Erreur lors de la requête fetch :', error)

        resetFeedback()
      }, 2000)
    }
  }

  useEffect(() => {
    Cookies.set('_influencapp_tk_log', 'defaut', {
      expires: 1,
      sameSite: 'Strict',
    })
  }, [])

  return (
    <>
      <div className={`fadinAnimation ${styles.allContainer}`}>
        <h2>Se connecter</h2>
        <p>
          Entrez vos informations de connexion dans les champs ci-dessous pour
          pouvoir accéder à votre compte
        </p>

        <div className={styles.callToSignup}>
          Pas encore de compte ?{' '}
          <Link to='/inscription'>Inscrivez-vous gratuitement</Link>
        </div>

        <form onSubmit={handleSubmit}>
          <input
            name='pseudo'
            type='text'
            placeholder='Votre pseudo'
            required
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
          />
          <br />

          <div className={styles.passwordInput}>
            <input
              name='password'
              minLength={6}
              type={visibilityPassword ? 'text' : 'password'}
              placeholder='Mot de passe* (6 crtès minimum)'
              required
              onChange={handleInputChange}
            />
            <div>
              {visibilityPassword ? (
                <FontAwesomeIcon
                  onClick={handleVisibilityPassword}
                  icon={faEyeSlash}
                />
              ) : (
                <FontAwesomeIcon
                  onClick={handleVisibilityPassword}
                  icon={faEye}
                />
              )}
            </div>
          </div>

          <p className={styles.linkResetPassword}>
            <Link to='/reset-password'>
              Identifiant ou mot de passe oublié ?
            </Link>
          </p>
          <br />
          <button type='submit'>
            {loading === 'connexion' ? 'Connexion encours...' : 'Se connecter'}
          </button>
        </form>

        <TextRGPD />
      </div>
      <NotifFeedBackFecth
        modifAuthorizeValue={modifAuthorize}
        callBackMessageValue={callBackMessage}
        messageFecthValue={messageFecth}
      />
    </>
  )
}

export default Connexion
