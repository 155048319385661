import React, { useState } from 'react'
import styles from './PacksAbonnement.module.scss'
import Cookies from 'js-cookie'
import NotifFeedBackFecth from '../../NotifFeedBackFecth/NotifFeedBackFecth'
import PopupConnexionRequis from '../../PopupConnexionRequis/PopupConnexionRequis'
import ComponentAuthRequired from '../../../middlewares/ComponentAuthRequired/ComponentAuthRequired'
import { loadStripe } from '@stripe/stripe-js'

function PacksAbonnement({ newSubscription }) {
  // const [abonnementEncours, setAbonnementEncours] = useState("");
  const [loading, setLoading] = useState('')
  const token = Cookies.get('_influencapp_tk_log')

  // Les constantes pour afficher un feedback ou notifications avec un registrement ou fecth..
  const [modifAuthorize, setModifAuthorize] = useState('')
  const [messageFecth, setMessageFecth] = useState('')
  const [callBackMessage, setCallBackMessage] = useState('')
  const [callToLogin, setCallToLogin] = useState(false)

  function resetNotifFeedBack() {
    setTimeout(() => {
      setModifAuthorize('')
      setCallBackMessage('')
      setMessageFecth('')
    }, 6000)
  }
  // fin //

  // Function d'envoi de l'abonnement
  // const makePayment = async (e) =>{
  //     e.preventDefault();
  //     setLoading(e.target.name);

  //     if(e.target.name !== "standard" && e.target.name !== "premium" && e.target.name !== "ultimate"){
  //         setTimeout(()=>{
  //             setLoading("");
  //             setModifAuthorize("error");
  //             setCallBackMessage("flex");
  //             setMessageFecth("Oups une erreur s'est produite, veuillez s'il vous plait contactez le service clientele d'influencapp.")

  //             resetNotifFeedBack();
  //         }, 1000)
  //     }

  //     try {
  //         const response = await fetch(`${process.env.REACT_APP_API_URL}/user/subscription?subscription=${e.target.name}`,{
  //             method : "POST",
  //             headers : {Authorization: `Bearer ${token}`}
  //         })
  //         if(response.ok){
  //             setTimeout(()=>{
  //                 setLoading("");
  //                 setModifAuthorize(true);
  //                 setCallBackMessage("flex");
  //                 setMessageFecth("Félicitations votre abonnement a été activé avec succès")

  //                 resetNotifFeedBack();

  //                 setTimeout(()=>{
  //                     newSubscription()
  //                 }, 1000)
  //             }, 1000)
  //         }
  //         else{
  //             setTimeout(()=>{
  //                 setLoading("");
  //                 setModifAuthorize("error");
  //                 setCallBackMessage("flex");
  //                 setMessageFecth("Oups une erreur s'est produite, veuillez s'il vous plait contactez le service clientele d'influencapp.")

  //                 resetNotifFeedBack();
  //             }, 1000)
  //         }
  //     } catch (error) {
  //         console.log("Erreur lors de la requette fecth", error);
  //         setLoading("");
  //         setModifAuthorize("error");
  //         setCallBackMessage("flex");
  //         setMessageFecth("Oups une erreur s'est produite, veuillez s'il vous plait contactez le service clientele d'influencapp.")

  //         resetNotifFeedBack();
  //     }
  // }

  const HandleClick = () => {
    setCallToLogin(!callToLogin)
  }

  // Function d'achat d'un pack
  const makePayment = async (e) => {
    const stripe = await loadStripe(
      `${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`,
    )

    e.preventDefault()
    setLoading(e.target.name)

    if (
      e.target.name !== 'standard' &&
      e.target.name !== 'premium' &&
      e.target.name !== 'ultimate'
    ) {
      setTimeout(() => {
        setLoading('')
        setModifAuthorize('error')
        setCallBackMessage('flex')
        setMessageFecth(
          "Oups une erreur s'est produite, veuillez s'il vous plait contactez le service clientele d'influencapp.",
        )

        resetNotifFeedBack()
      }, 1000)
    } else {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/user/subscription?subscription=${e.target.name}`,
          {
            method: 'POST',
            headers: { Authorization: `Bearer ${token}` },
          },
        )

        if (response.ok) {
          const session = await response.json()
          const result = stripe.redirectToCheckout({
            sessionId: session.id,
          })

          if (result.error) {
            console.log(result.error)
          }
        } else {
          setTimeout(() => {
            setLoading('')
            setModifAuthorize('error')
            setCallBackMessage('flex')
            setMessageFecth(
              "Oups une erreur s'est produite, veuillez s'il vous plait contactez le service clientele d'influencapp.",
            )

            resetNotifFeedBack()
          }, 1000)
        }
      } catch (error) {
        console.log('Erreur lors de la requette fecth', error)
        setLoading('')
        setModifAuthorize('error')
        setCallBackMessage('flex')
        setMessageFecth(
          "Oups une erreur s'est produite, veuillez s'il vous plait contactez le service clientele d'influencapp.",
        )

        resetNotifFeedBack()
      }
    }
  }

  return (
    <>
      <div className={`fadinAnimation ${styles.allContainer}`}>
        {/* <h3>Les abonnements influencapp</h3> */}
        <h3>Nos packs influencapp</h3>
        <div className={styles.packsContent}>
          <div className={`cardBackground ${styles.cardPack}`}>
            <p className={styles.title}>Standard</p>
            <p className={styles.amount}>30,99 €</p>
            <div className={styles.balanceOfferContainer}>
              <p>Contactez</p>
              {/* <p>90 clients / mois</p> */}
              <p>Jusqu'à 90 clients</p>
            </div>
            <ComponentAuthRequired
              // valueDefault={<button onClick={HandleClick}>S'abonner</button>}
              valueDefault={<button onClick={HandleClick}>Acheter</button>}
            >
              {/* <button onClick={makePayment} name="standard">{ loading === "standard" ? "Veuillez patienter..." : "S'abonner"}</button> */}
              <button onClick={makePayment} name='standard'>
                {loading === 'standard' ? 'Veuillez patienter...' : 'Acheter'}
              </button>
            </ComponentAuthRequired>
          </div>

          <div className={`cardBackground ${styles.cardPack}`}>
            <p className={styles.title}>Premium</p>
            <p className={styles.amount}>50,99 €</p>
            <div className={styles.balanceOfferContainer}>
              <p>Contactez</p>
              {/* <p>200 clients / mois</p> */}
              <p>Jusqu'à 200 clients</p>
            </div>
            <ComponentAuthRequired
              // valueDefault={<button onClick={HandleClick}>S'abonner</button>}
              valueDefault={<button onClick={HandleClick}>Acheter</button>}
            >
              {/* <button onClick={makePayment} name="premium">{ loading === "premium" ? "Veuillez patienter..." : "S'abonner"}</button> */}
              <button onClick={makePayment} name='premium'>
                {loading === 'premium' ? 'Veuillez patienter...' : 'Acheter'}
              </button>
            </ComponentAuthRequired>
          </div>

          <div className={`cardBackground ${styles.cardPack}`}>
            <p className={styles.title}>Ultimate</p>
            <p className={styles.amount}>100,99 €</p>
            <div className={styles.balanceOfferContainer}>
              <p>Contactez</p>
              {/* <p>420 clients / mois</p> */}
              <p>Jusqu'à 420 clients</p>
            </div>

            {/* <ComponentAuthRequired */}
            <ComponentAuthRequired
              Acheterfault={<button onClick={HandleClick}>S'abonner</button>}
            >
              {/* <button onClick={makePayment} name="ultimate">{ loading === "ultimate" ? "Veuillez patienter..." : "S'abonner"}</button> */}
              <button onClick={makePayment} name='ultimate'>
                {loading === 'ultimate' ? 'Veuillez patienter...' : 'Acheter'}
              </button>
            </ComponentAuthRequired>
          </div>
        </div>
      </div>
      <NotifFeedBackFecth
        modifAuthorizeValue={modifAuthorize}
        callBackMessageValue={callBackMessage}
        messageFecthValue={messageFecth}
      />

      {/* popup callToLogin */}
      {callToLogin && (
        <PopupConnexionRequis
          context={`Connectez-vous ou inscrivez-vous pour souscrire à un abonnement.`}
          HandleClick={HandleClick}
        />
      )}
    </>
  )
}

export default PacksAbonnement
