import styles from './profile.module.scss'
import FirstSection from '../../components/ProfileComponent/FirstSection'
import Presentation from '../../components/ProfileComponent/Presentation'
import Medias from '../../components/ProfileComponent/Medias'
import ProfilCoordonnees from '../../components/ProfileComponent/ProfilCoordonnees'
import ProfilStatistiques from '../../components/ProfileComponent/ProfilStatistiques'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom/dist'
import ViewProfile from '../../apiUtils/ViewProfile'
// import ReseauxUser from "../../components/ProfileComponent/ReseauxUser/ReseauxUser";
import Cookies from 'js-cookie'
import PartialInfoProfileUser from '../../components/PartialInfoProfileUser/PartialInfoProfileUser'
import ComponentAuthRequired from '../../middlewares/ComponentAuthRequired/ComponentAuthRequired'

function Profile() {
  const urlParams = useParams()
  const [userData, setUserData] = useState({})
  const [pseudoFecth, setPseudoFecth] = useState(urlParams.user)
  const [loading, setLoading] = useState('loadingComponent')
  const token = Cookies.get('_influencapp_tk_log')

  useEffect(() => {
    setLoading('loadingComponent')
    setPseudoFecth(urlParams.user)

    fetch(`${process.env.REACT_APP_API_URL}/user/profile?user=${pseudoFecth}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => response.json())
      .then((fetchData) => {
        setUserData(fetchData)
        setLoading('')
      })
      .catch((error) => {
        setLoading('')
        console.log('error', error)
      })
  }, [pseudoFecth, urlParams.user, token])

  return (
    <>
      {loading === 'loadingComponent' ? null : userData.pseudo !== undefined ? (
        <div className={`${styles.allContainer}`}>
          <FirstSection
            subscription={userData?.subscription}
            urlBanniere={userData?._link_photo_couverture?.url}
            urlProfile={userData?._link_photo_profil?.url}
            name={userData?.name}
            pseudo={userData?.pseudo}
            statut={userData?.statut}
            localisation={userData?.localisation}
          />

          <div className={styles.allDetailsProfile}>
            <div className={styles.leftContent}>
              <Presentation presentation={userData.presentation} />
              {/* medias */}
              <ComponentAuthRequired
                valueDefault={
                  <PartialInfoProfileUser
                    margin='marginBottom'
                    pseudo={userData.name ? userData.name : pseudoFecth}
                  />
                }
              >
                <Medias medias={userData._medias} />
              </ComponentAuthRequired>
            </div>

            <div className={styles.rightContent}>
              <ProfilCoordonnees
                secteur={userData.categorie}
                phone={userData.phone}
                identityVerify={userData.identity_verif}
              />
              <ProfilStatistiques userStats={userData.statistique} />

              {/* reseaux */}
              {/* <ComponentAuthRequired valueDefault={
                            <PartialInfoProfileUser margin="marginTop" pseudo={userData.name ? userData.name : pseudoFecth} />
                        }>
                            <ReseauxUser />
                        </ComponentAuthRequired> */}
            </div>

            <ViewProfile userPseudo={pseudoFecth} />
          </div>
        </div>
      ) : (
        <div className={styles.notFoundProfil}>Aucun profil trouvé</div>
      )}
    </>
  )
}

export default Profile
