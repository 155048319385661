import React, { useEffect, useState } from 'react'
import styles from './AbonnementEncours.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  //   faCalendarDays,
  faEuro,
  faStarOfLife,
  faTicket,
} from '@fortawesome/free-solid-svg-icons'

// function formatDate(dateFormat) {
//     const options = { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' };
//     const date = new Date(dateFormat);
//     const formattedDate = new Intl.DateTimeFormat('fr-FR', options).format(date);
//     return formattedDate.replace('/', '/');
// }

function AbonnementEncours({ userSubscription }) {
  const [subscription, setSubscription] = useState('')

  useEffect(() => {
    setSubscription(userSubscription)
  }, [userSubscription])

  return (
    <>
      {subscription ? (
        <div className={`fadinAnimation cardBackground ${styles.allContainer}`}>
          {/* <h3>Abonnement encours</h3> */}
          <h3>Pack encours</h3>
          <div className={styles.detailsSubscription}>
            <p className={styles.typeSubscription}>
              <FontAwesomeIcon icon={faStarOfLife} />
              Mon pack : <span>{subscription?.type}</span>
            </p>
            {/* <p><FontAwesomeIcon icon={faCalendarDays}/>Expire le : <span>{formatDate(subscription?.expireDate)}</span></p> */}
            <p>
              <FontAwesomeIcon icon={faTicket} />
              Crédit restants : <span>{subscription?.balanceOffer}</span>
            </p>
            <p>
              <FontAwesomeIcon icon={faEuro} />
              Paiement par : <span>Carte bancaire</span>
            </p>
          </div>
        </div>
      ) : null}
    </>
  )
}

export default AbonnementEncours
