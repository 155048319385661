import { useEffect } from "react";
import Cookies from "js-cookie";

function fecthStat(projectId){
  // Initialisation du temps d'expiration a 10min
  const d = new Date();
  d.setTime(d.getTime() + 10 * 60 * 1000);

  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify({ views : 1, projectId : projectId }),
  };

  fetch(`${process.env.REACT_APP_API_URL}/project/views`, requestOptions)
  .then(response => { 
    if(response.ok){
      Cookies.set(`_influencapp_view_project_${projectId}`, true, {expires: d, sameSite : "Strict"});
      Cookies.set(`_influencapp_view_project_expires_${projectId}`, d.toISOString(), { expires: d, sameSite: "Strict" });
    } 
    response.json();
  })
  .then(result => { return result })
  .catch(error => console.log('error', error));

  return;
}

function ViewProject({ projectId }) {
  useEffect(() => {
    const cookieValue = Cookies.get(`_influencapp_view_project_${projectId}`);
    const cookieExpirationDate = Cookies.get(`_influencapp_view_project_expires_${projectId}`);

    // Vérifiez si le cookie existe
    if (cookieValue !== undefined && cookieExpirationDate) {

      const expirationDate = new Date(cookieExpirationDate);
      const currentDate = new Date();

      if (currentDate > expirationDate) {
        fecthStat(projectId);
      } else{
        return
      }
    } else {
      fecthStat(projectId);
    }
  }, [projectId]);

  return null;
}

export default ViewProject;