import {
  faCircleUser,
  faHeart,
  faLock,
  //   faShareNodes,
  faUserPen,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import styles from './MonCompteSousMenu.module.scss'

function MonCompteSousMenu({ pseudo }) {
  const location = useLocation()
  const activeLink = {
    fontWeight: '500',
    color: '#E87213',
  }
  return (
    <div className={`${styles.sousMenuUserAccount}`}>
      <h3>Mon compte</h3>
      <p>
        <Link
          style={location.pathname === '/profile/*' ? activeLink : {}}
          to={`/profile/${pseudo}`}
        >
          <FontAwesomeIcon icon={faCircleUser} />
          Voir mon profil
        </Link>
      </p>
      <p>
        <Link
          style={location.pathname === '/edit-log-info' ? activeLink : {}}
          to='/edit-log-info'
        >
          <FontAwesomeIcon icon={faLock} />
          Informations de connexion
        </Link>
      </p>
      <p>
        <Link
          style={location.pathname === '/my-account' ? activeLink : {}}
          to='/my-account'
        >
          <FontAwesomeIcon icon={faUserPen} />
          Gérer mon compte
        </Link>
      </p>
      <p>
        <Link to='#' style={{ textDecoration: 'line-through' }}>
          <FontAwesomeIcon icon={faHeart} />
          Mes favoris
        </Link>

        {/* <Link
          style={
            location.pathname === '/my-favourites' ? activeLink : {}
          }
          to='/my-favourites'
        >
          <FontAwesomeIcon icon={faHeart} />
          Mes favoris
        </Link> */}
      </p>
      {/* <p><Link style={location.pathname === '/my-social-net' ? activeLink : {}} to='/my-social-net'><FontAwesomeIcon icon={faShareNodes} />Mes réseaux sociaux</Link></p> */}
    </div>
  )
}

export default MonCompteSousMenu
