import styles from "./index.module.scss";

function Presentation({presentation}) {
    const texteFormate = presentation?.split('\n');
    return (
        <>
            {presentation !== undefined && presentation !== "" ?
            
            <div style={{marginBottom: "-15px"}} className={`fadinAnimation containerStyles ${styles.allContainer}`}>
                <h2>Présentation</h2>
                {texteFormate.map((line, index) => (
                    <div key={index}>
                        {line}
                        {index !== texteFormate.length - 1 && <br />}
                    </div>
                ))}
            </div>
            
            : <div style={{marginBottom: "-15px", minHeight: "50vh"}} className={`fadinAnimation containerStyles ${styles.allContainer}`}>
                    <h2>Présentation</h2>
                    <p>Aucun contenu dans la présentation</p>
                </div> 
            }
        </>
    )
}

export default Presentation;
