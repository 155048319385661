import { useState } from 'react'
import Cookies from 'js-cookie'

import styles from './resetPassword.module.scss'
import TextRGPD from '../../components/LogTextRGPD/LogTextRGPD'
import NotifFeedBackFecth from '../../components/NotifFeedBackFecth/NotifFeedBackFecth'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'

function ResetPassword() {
  const [formData, setFormData] = useState({
    email: '',
    code: '',
    newpassword: '',
  })
  const navigate = useNavigate()
  const [loading, setLoading] = useState('')
  const [messageSendCode, setMessageSendCode] = useState('')
  const [visibilityPassword, setVisibilityPassword] = useState(false)

  const [modifAuthorize, setModifAuthorize] = useState('')
  const [messageFecth, setMessageFecth] = useState('')
  const [callBackMessage, setCallBackMessage] = useState('')

  const resetMessageFeedBack = () => {
    setTimeout(() => {
      setModifAuthorize('')
      setCallBackMessage('')
      setMessageFecth('')
    }, 6000)
  }

  const handleVisibilityPassword = () => {
    setVisibilityPassword(!visibilityPassword)
  }

  // pour eviter un espace vide dans un champs
  const handleKeyDown = (e) => {
    if (e.key === ' ') {
      e.preventDefault()
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
    setMessageSendCode('')
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading('resetPassword')
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/user/reset-password`,
        {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(formData),
        },
      )
      if (response.ok) {
        setTimeout(() => {
          setLoading('')
          setMessageSendCode('')
          Cookies.remove('_influencapp_tk_log')
          setModifAuthorize(true)
          setCallBackMessage('flex')
          setMessageFecth('Réinitialisation effectué avec succès')

          const form = document.getElementById('form-reset-password')
          form.reset()
          resetMessageFeedBack()

          setTimeout(() => {
            navigate('/connexion')
          }, 3000)
        }, 2000)
      } else {
        setTimeout(() => {
          setLoading('')
          setModifAuthorize('error')
          setCallBackMessage('flex')
          setMessageFecth(
            'Echec de réinitialisation, vérifiez votre saisie et réessayer',
          )
          resetMessageFeedBack()
        }, 2000)
      }
    } catch (error) {
      setLoading('')
      setModifAuthorize('error')
      setCallBackMessage('flex')
      setMessageFecth(
        "Oups, une erreur s'est produite veuillez réessayer plustard.",
      )
      resetMessageFeedBack()
      console.error('Erreur lors de la requête fetch :', error)
    }
  }

  const sendCodeVerif = async () => {
    setLoading('sendCodeVerif')
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/verification/edit-password`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ email: formData.email }),
        },
      )
      if (response.ok) {
        setTimeout(() => {
          setLoading('')
          setMessageSendCode(true)
        }, 2000)
      } else {
        setTimeout(() => {
          setLoading('')
          setMessageSendCode(false)
        }, 2000)
      }
    } catch (error) {
      setLoading('')
      console.error('Erreur lors de la requête fetch :', error)
    }
  }

  return (
    <>
      <div className={`fadinAnimation ${styles.allContainer}`}>
        <h2>Mot de passe oublier ?</h2>
        <p>
          Saisissez l'email utilisé lors de votre inscription afin de renouveler
          votre mot de passe.
        </p>

        <form id='form-reset-password' onSubmit={handleSubmit}>
          <div className={styles.emailInputContainer}>
            <input
              name='email'
              type='email'
              placeholder='adresse e-mail'
              required
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
            />
            <div onClick={sendCodeVerif} className={styles.buttonSendCode}>
              {loading === 'sendCodeVerif'
                ? 'Envoi du code...'
                : 'Envoyer le code'}
            </div>
          </div>

          <div className={styles.messageSendCodeContainer}>
            {messageSendCode ? (
              <p className={styles.succes}>Code envoyé avec succès.</p>
            ) : messageSendCode !== '' ? (
              <p className={styles.failed}>
                Erreur, verifier l'email saisie et réessayer.
              </p>
            ) : (
              ''
            )}
          </div>

          <input
            name='code'
            type='text'
            placeholder='Code de vérification'
            required
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
          />
          <br />

          <div className={styles.passwordInput}>
            <input
              name='newpassword'
              minLength={6}
              type={visibilityPassword ? 'text' : 'password'}
              placeholder='Nouveau mot de passe* (6 crtès minimum)'
              required
              onChange={handleInputChange}
            />
            <div>
              {visibilityPassword ? (
                <FontAwesomeIcon
                  onClick={handleVisibilityPassword}
                  icon={faEyeSlash}
                />
              ) : (
                <FontAwesomeIcon
                  onClick={handleVisibilityPassword}
                  icon={faEye}
                />
              )}
            </div>
          </div>

          <br />
          <button type='submit'>
            {loading === 'resetPassword'
              ? 'Réinitialition...'
              : 'Réinitialiser'}
          </button>
        </form>
        <TextRGPD />
      </div>

      <NotifFeedBackFecth
        modifAuthorizeValue={modifAuthorize}
        callBackMessageValue={callBackMessage}
        messageFecthValue={messageFecth}
      />
    </>
  )
}

export default ResetPassword
