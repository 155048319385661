import React from 'react'

function NousContacter() {
  return (
    <>
        <h2 className='titlePage'>Nous contacter</h2>
    </>
  )
}

export default NousContacter