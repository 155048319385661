import React from 'react'

function NosTarifs() {
  return (
    <>
        <h2 className='titlePage'>Nos tarifs</h2>
    </>
  )
}

export default NosTarifs