import styles from './AuthorCoordonnees.module.scss'
import ImageFlou from '../../../assets/images/profil_coordonees_flou.webp'
import { Link } from 'react-router-dom'

function AuthorCoordonnees({ secteur, identityVerify, phone }) {
  return (
    <>
      {identityVerify === undefined ? (
        <div className={`cardBackground ${styles.authorNotFoundContainer}`}>
          <div className={styles.callToSubscription}>
            <p>
              Inscrivez-vous dès maintenant pour deposer une offre sur ce
              projet.
            </p>
            <Link to={`/inscription`}>
              <button>S'inscrire gratuitement</button>
            </Link>
          </div>
          {/* <div className={styles.callToSubscription}>
                        <p>Abonnez-vous maintenant pour accéder au profil complet et d'autres avantages.</p>
                        <Link to={`/my-subscription`}><button>Voir les abonnements</button></Link>
                    </div> */}
          <img
            className={styles.imageFlou}
            src={ImageFlou}
            alt='profile author project'
          />
        </div>
      ) : (
        <div className={`cardBackground ${styles.profilEcoordonnees}`}>
          <h2>Vérification et coordonnées</h2>

          {secteur && secteur !== undefined && secteur !== null ? (
            <div className={styles.content}>
              <p>Secteur :</p>
              <p>{secteur}</p>
            </div>
          ) : (
            ''
          )}

          <div className={styles.content}>
            <p>Adresse e-mail : </p>
            <p className={styles.verify}>vérifier</p>
          </div>
          <div className={styles.content}>
            <p>Pièce d'identité :</p>
            <p className={identityVerify ? styles.verify : styles.noVerify}>
              {identityVerify ? 'vérifier' : 'non vérifier'}
            </p>
          </div>

          {phone && phone !== undefined && phone !== null ? (
            <div className={styles.content}>
              <p>N° de téléphone :</p>
              <p>{phone}</p>
            </div>
          ) : (
            ''
          )}
        </div>
      )}
    </>
  )
}

export default AuthorCoordonnees
