import React from 'react'

function ConditionDutilisation() {
  return (
    <>
        <h2 className='titlePage'>Conditions d'utilisation</h2>
    </>
  )
}

export default ConditionDutilisation