import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmarkCircle } from '@fortawesome/free-solid-svg-icons'
import styles from './PopupConnexionRequis.module.scss'

function PopupConnexionRequis({ HandleClick, context }) {
  return (
    <div className={styles.containerCallToLogin}>
      <div className={styles.contentContainer}>
        <div className={styles.contentCallToLogin}>
          <p onClick={HandleClick} className={styles.iconeClose}>
            <FontAwesomeIcon icon={faXmarkCircle} />
          </p>
          <div>
            <>
              <h3>Compte requis</h3>
              <p>{context}</p>
            </>
            <Link to={`/connexion`}>
              <button>Se connecter</button>
            </Link>
            <Link to={`/inscription`}>
              <button>S'inscrire gratuitement</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PopupConnexionRequis
