import styles from './accueil.module.scss'
import ImagaBookIa from '../../assets/images/image-book-ia.png'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { useRef } from 'react'
import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowLeft,
  faArrowRight,
  faCheck,
  faPlusCircle,
} from '@fortawesome/free-solid-svg-icons'
import { Link, useNavigate } from 'react-router-dom'
import PopupConnexionRequis from '../../components/PopupConnexionRequis/PopupConnexionRequis'
import GifFecthLoad from '../../components/GifFecthLoad/GifFecthLoad'
import ComponentAuthRequired from '../../middlewares/ComponentAuthRequired/ComponentAuthRequired'
import CardProject from '../../components/CardProject/CardProject'

// images
import InfluBeauty from '../../assets/images/influ-beauty.png'
import InfluBienEtre from '../../assets/images/influ-bien-etre.png'
import InfluSport from '../../assets/images/influ-sport.png'
import InfluCuisine from '../../assets/images/influ-cuisine.png'
import InfluMode from '../../assets/images/influ-mode.png'
import InfluMusique from '../../assets/images/influ-musique.png'

import Image1Widget2 from '../../assets/images/image1_widget2.png'
import Image2Widget2 from '../../assets/images/image2_widget2.png'

import BenefIcone1 from '../../assets/images/benef-icone-1.png'
import BenefIcone2 from '../../assets/images/benef-icone-2.png'
import BenefIcone3 from '../../assets/images/benef-icone-3.png'
import BenefIcone4 from '../../assets/images/benef-icone-4.png'
import BenefIcone5 from '../../assets/images/benef-icone-5.png'
import BenefIcone6 from '../../assets/images/benef-icone-6.png'

import Image1Widget5 from '../../assets/images/image1_widget5.png'
import { useEffect } from 'react'

function Accueil() {
  const navigate = useNavigate()
  const sliderRef = useRef(null)
  const [callToLogin, setCallToLogin] = useState(false)
  const [context, setContext] = useState('')
  const [data, setData] = useState([])
  const [loading, setLoading] = useState('fecthLoad')

  // redirection de la page
  const disable = false
  window.location.href = 'https://inscription.influencapp.com'

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    slidesToShow: 5,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  }

  const slidePrev = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev()
    }
  }

  const slideNext = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext()
    }
  }

  const HandleClick = (clickContext) => {
    setContext(clickContext)
    setCallToLogin(!callToLogin)
  }

  const GoToCreateProject = (clickContext) => {
    sessionStorage.setItem('titleProject', clickContext)
    navigate('/create-project')
  }

  useEffect(() => {
    const fectData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/project/spotlight`,
        )
        if (response.ok) {
          const result = await response.json()
          setData(result.projects)
          setLoading('')
        } else {
          setLoading('')
        }
      } catch (error) {
        setLoading('')
        console.log('Erreur lors de la requette fecth', error)
      }
    }

    fectData()
  }, [])

  return (
    <>
      {disable && (
        <div className={`fadinAnimation ${styles.allContainer}`}>
          <div className={styles.firstSection}>
            <div>
              <h2>Trouvez des clients grâces aux influenceurs</h2>
              <ComponentAuthRequired
                valueDefault={
                  <Link to='/connexion'>
                    <button>Trouver des influenceurs</button>
                  </Link>
                }
              >
                <Link to='/create-project'>
                  <button>Trouver des influenceurs</button>
                </Link>
              </ComponentAuthRequired>
            </div>
            <img src={ImagaBookIa} alt='prez-ia-book-influencer' />
          </div>

          <div className={styles.advantageSection}>
            <div className={styles.oneAdvantage}>
              <p className={styles.title}>Simple</p>
              <p className={styles.content}>
                Décrivez votre projet en quelques mots sur la plateforme
              </p>
            </div>

            <div className={styles.oneAdvantage}>
              <p className={styles.title}>Rapide</p>
              <p className={styles.content}>
                Trouver des influenceurs et agence influenceur immédiatement
              </p>
            </div>

            <div className={styles.oneAdvantage}>
              <p className={styles.title}>Efficace</p>
              <p className={styles.content}>
                Triez facilement les propositions sans prendre de risque
              </p>
            </div>
          </div>

          <div className={styles.sliderContainer}>
            <div className={styles.titleEslideBtn}>
              <h3>Influenceurs par thèmes</h3>
              <div className={styles.slideButton}>
                <div onClick={slidePrev}>
                  <FontAwesomeIcon icon={faArrowLeft} />
                </div>
                <div onClick={slideNext}>
                  <FontAwesomeIcon icon={faArrowRight} />
                </div>
              </div>
            </div>

            <Slider ref={sliderRef} {...settings}>
              <div className={styles.containerOneSlide}>
                <div className={styles.OneSlide}>
                  <ComponentAuthRequired
                    valueDefault={
                      <div
                        onClick={() => HandleClick('beauté')}
                        className={styles.voidDiv}
                      ></div>
                    }
                  >
                    <div
                      onClick={() => GoToCreateProject('beauté')}
                      className={styles.voidDiv}
                    ></div>
                  </ComponentAuthRequired>

                  <div className={styles.callToAddProject}>
                    <FontAwesomeIcon icon={faPlusCircle} />
                    <p>Recevoir des devis</p>
                  </div>

                  <img src={InfluBeauty} alt={'Img Influ Beauty'} />
                  <div className={styles.content}>
                    <p>Influenceurs</p>
                    <p>Beauté</p>
                  </div>
                </div>
              </div>

              <div className={styles.containerOneSlide}>
                <div className={styles.OneSlide}>
                  <ComponentAuthRequired
                    valueDefault={
                      <div
                        onClick={() => HandleClick('bien-être')}
                        className={styles.voidDiv}
                      ></div>
                    }
                  >
                    <div
                      onClick={() => GoToCreateProject('bien-être')}
                      className={styles.voidDiv}
                    ></div>
                  </ComponentAuthRequired>

                  <div className={styles.callToAddProject}>
                    <FontAwesomeIcon icon={faPlusCircle} />
                    <p>Recevoir des devis</p>
                  </div>

                  <img src={InfluBienEtre} alt={'Img Influ Bien-Etre'} />
                  <div className={styles.content}>
                    <p>Influenceurs</p>
                    <p>Bien-être</p>
                  </div>
                </div>
              </div>

              <div className={styles.containerOneSlide}>
                <div className={styles.OneSlide}>
                  <ComponentAuthRequired
                    valueDefault={
                      <div
                        onClick={() => HandleClick('sport')}
                        className={styles.voidDiv}
                      ></div>
                    }
                  >
                    <div
                      onClick={() => GoToCreateProject('sport')}
                      className={styles.voidDiv}
                    ></div>
                  </ComponentAuthRequired>

                  <div className={styles.callToAddProject}>
                    <FontAwesomeIcon icon={faPlusCircle} />
                    <p>Recevoir des devis</p>
                  </div>

                  <img src={InfluSport} alt={'Img Influ Sport'} />
                  <div className={styles.content}>
                    <p>Influenceurs</p>
                    <p>Sport</p>
                  </div>
                </div>
              </div>

              <div className={styles.containerOneSlide}>
                <div className={styles.OneSlide}>
                  <ComponentAuthRequired
                    valueDefault={
                      <div
                        onClick={() => HandleClick('cuisine')}
                        className={styles.voidDiv}
                      ></div>
                    }
                  >
                    <div
                      onClick={() => GoToCreateProject('cuisine')}
                      className={styles.voidDiv}
                    ></div>
                  </ComponentAuthRequired>

                  <div className={styles.callToAddProject}>
                    <FontAwesomeIcon icon={faPlusCircle} />
                    <p>Recevoir des devis</p>
                  </div>

                  <img src={InfluCuisine} alt={'Img Influ Cuisine'} />
                  <div className={styles.content}>
                    <p>Influenceurs</p>
                    <p>Cuisine</p>
                  </div>
                </div>
              </div>

              <div className={styles.containerOneSlide}>
                <div className={styles.OneSlide}>
                  <ComponentAuthRequired
                    valueDefault={
                      <div
                        onClick={() => HandleClick('mode')}
                        className={styles.voidDiv}
                      ></div>
                    }
                  >
                    <div
                      onClick={() => GoToCreateProject('mode')}
                      className={styles.voidDiv}
                    ></div>
                  </ComponentAuthRequired>

                  <div className={styles.callToAddProject}>
                    <FontAwesomeIcon icon={faPlusCircle} />
                    <p>Recevoir des devis</p>
                  </div>

                  <img src={InfluMode} alt={'Img Influ Mode'} />
                  <div className={styles.content}>
                    <p>Influenceurs</p>
                    <p>Mode</p>
                  </div>
                </div>
              </div>

              <div className={styles.containerOneSlide}>
                <div className={styles.OneSlide}>
                  <ComponentAuthRequired
                    valueDefault={
                      <div
                        onClick={() => HandleClick('musique')}
                        className={styles.voidDiv}
                      ></div>
                    }
                  >
                    <div
                      onClick={() => GoToCreateProject('musique')}
                      className={styles.voidDiv}
                    ></div>
                  </ComponentAuthRequired>

                  <div className={styles.callToAddProject}>
                    <FontAwesomeIcon icon={faPlusCircle} />
                    <p>Recevoir des devis</p>
                  </div>

                  <img src={InfluMusique} alt={'Img Influ Musique'} />
                  <div className={styles.content}>
                    <p>Influenceurs</p>
                    <p>Musique</p>
                  </div>
                </div>
              </div>
            </Slider>
          </div>

          <div className={styles.widget0}>
            <div className={styles.typeDprofils}>
              <>
                <h3>Nos principaux profils</h3>
                {/* liste de base  */}
                {/* <div>
                <p
                  onClick={() =>
                    navigate(
                      '/profile-secteur/Influenceur(se) niche chien',
                    )
                  }
                >
                  Influenceur(se) niche chien
                </p>
                <p
                  onClick={() =>
                    navigate('/profile-secteur/Influenceuse mode')
                  }
                >
                  Influenceuse mode
                </p>
                <p
                  onClick={() =>
                    navigate(
                      '/profile-secteur/Influenceur(se) niche voyage',
                    )
                  }
                >
                  Influenceur(se) niche voyage
                </p>
                <p
                  onClick={() =>
                    navigate(
                      '/profile-secteur/Influenceur(se) food',
                    )
                  }
                >
                  Influenceur(se) food
                </p>
                <p
                  onClick={() =>
                    navigate(
                      '/profile-secteur/Influenceur(se) sport',
                    )
                  }
                >
                  Influenceur(se) sport
                </p>
                <p
                  onClick={() =>
                    navigate(
                      '/profile-secteur/Influenceur(se) automobile',
                    )
                  }
                >
                  Influenceur(se) automobile
                </p>
                <p
                  onClick={() =>
                    navigate('/profile-secteur/Influenceuse beauté')
                  }
                >
                  Influenceuse beauté
                </p>
                <p
                  onClick={() =>
                    navigate(
                      '/profile-secteur/Influenceur mode homme',
                    )
                  }
                >
                  Influenceur mode homme
                </p>
                <p
                  onClick={() =>
                    navigate(
                      '/profile-secteur/Influenceur(se) décoration',
                    )
                  }
                >
                  Influenceur(se) décoration
                </p>
                <p
                  onClick={() =>
                    navigate(
                      '/profile-secteur/Influenceur(se) gastronomie',
                    )
                  }
                >
                  Influenceur(se) gastronomie
                </p>
              </div> */}

                {/* liste apres demande de modif par amb */}
                <div>
                  <p
                    onClick={() =>
                      navigate('/profile-secteur/Influenceur chien')
                    }
                  >
                    Influenceur chien
                  </p>
                  <p
                    onClick={() =>
                      navigate('/profile-secteur/Influenceuse mode')
                    }
                  >
                    Influenceuse mode
                  </p>
                  <p
                    onClick={() =>
                      navigate('/profile-secteur/Influenceur voyage')
                    }
                  >
                    Influenceur voyage
                  </p>
                  <p
                    onClick={() =>
                      navigate('/profile-secteur/Influenceur food')
                    }
                  >
                    Influenceur food
                  </p>
                  <p
                    onClick={() =>
                      navigate('/profile-secteur/Influenceur sport')
                    }
                  >
                    Influenceur sport
                  </p>
                  <p
                    onClick={() =>
                      navigate('/profile-secteur/Influenceur automobile')
                    }
                  >
                    Influenceur automobile
                  </p>
                  <p
                    onClick={() =>
                      navigate('/profile-secteur/Influenceuse beauté')
                    }
                  >
                    Influenceuse beauté
                  </p>
                  <p
                    onClick={() =>
                      navigate('/profile-secteur/Influenceur mode homme')
                    }
                  >
                    Influenceur mode homme
                  </p>
                  <p
                    onClick={() =>
                      navigate('/profile-secteur/Influenceur décoration')
                    }
                  >
                    Influenceur décoration
                  </p>
                  <p
                    onClick={() =>
                      navigate('/profile-secteur/Influenceur technologie')
                    }
                  >
                    Influenceur technologie
                  </p>
                  <p
                    onClick={() =>
                      navigate('/profile-secteur/Influenceur gaming')
                    }
                  >
                    Influenceur gaming
                  </p>
                  <p
                    onClick={() => navigate('/profile-secteur/Influenceur DIY')}
                  >
                    Influenceur DIY
                  </p>
                  <p
                    onClick={() =>
                      navigate('/profile-secteur/Influenceur santé')
                    }
                  >
                    Influenceur santé
                  </p>
                  <p
                    onClick={() =>
                      navigate('/profile-secteur/Influenceurs parents')
                    }
                  >
                    Influenceurs parents
                  </p>
                  <p
                    onClick={() =>
                      navigate('/profile-secteur/Influenceur gastronomie')
                    }
                  >
                    Influenceur gastronomie
                  </p>
                  <p
                    onClick={() =>
                      navigate('/profile-secteur/Influenceur animaux')
                    }
                  >
                    Influenceur animaux
                  </p>
                  <p
                    onClick={() =>
                      navigate('/profile-secteur/Développement personnel')
                    }
                  >
                    Développement personnel
                  </p>
                  <p
                    onClick={() =>
                      navigate('/profile-secteur/Influenceur moto')
                    }
                  >
                    Influenceur moto
                  </p>
                </div>
                <Link to='/all-profiles'>
                  <button>Voir tous les profils</button>
                </Link>
              </>

              <div className={styles.portageSalarial}>
                <h3>Exercez votre activité en portage salarial</h3>
                <div>
                  Oui, il y a des agences pour influenceurs dans ce métier qui
                  se structure et se professionnalise en 2024. InfluencApp est
                  une plateforme entre influenceurs et annonceurs par filtres
                  thèmes, supports, méthodes et plein d'autres critère de
                  qualification qualifiant une bonne relation commerciale. Il
                  est naturel d'avoir des structures plus importantes offrants
                  des garanties plus sûres.
                </div>
                <Link to='/all-profiles'>
                  <button>Consultez les agences influenceurs</button>
                </Link>
              </div>
            </div>

            <div className={styles.projetAlaUne}>
              <h2>Les projets à la une</h2>

              {/* liste project à la une  */}
              <div>
                {/* Liste des projets */}
                {loading === 'fecthLoad' ? (
                  <GifFecthLoad positionDiv='fixed' />
                ) : (
                  <>
                    <div className={styles.cardContainer}>
                      {data.map((project) => (
                        <CardProject
                          key={project?._id}
                          id={project?._id}
                          title={project?.title}
                          description={project?.description}
                          statut={project?.statut}
                          budget={project?.budget}
                          profilSearch={project?.profilSearch}
                          views={project?.statistique?.views}
                          totalOffers={
                            (project?.statistique?.totaleOffers).length !==
                            undefined
                              ? (project?.statistique?.totaleOffers).length
                              : 0
                          }
                          dateCrea={project?.dateCrea}
                        />
                      ))}
                      {loading === 'loadingComponent' ? null : data.length >
                        0 ? null : (
                        <div className={styles.notFoundProject}>
                          Aucune annonce trouver.
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>

              <div className={styles.btnContainer}>
                <ComponentAuthRequired
                  valueDefault={
                    <Link to={`/connexion`}>
                      <button>Voir plus</button>
                    </Link>
                  }
                >
                  <Link to={`/projects`}>
                    <button>Voir plus</button>
                  </Link>
                </ComponentAuthRequired>
              </div>
            </div>
          </div>

          <div className={styles.widget1}>
            <p>
              Trouvez des clients grâce aux influenceurs qui vous correspondent
            </p>
            <ComponentAuthRequired
              valueDefault={
                <Link to='/connexion'>
                  <button>Trouver des influenceurs</button>
                </Link>
              }
            >
              <Link to='/create-project'>
                <button>Trouver des influenceurs</button>
              </Link>
            </ComponentAuthRequired>
          </div>

          <div className={styles.widget2}>
            <div className={styles.allContent}>
              <img src={Image1Widget2} alt='img widget' />
              <div className={styles.textContent}>
                <div>
                  <h4>
                    Plus de 250 000 créateurs sont inscrits sur Influencapp.com{' '}
                  </h4>
                  <p>
                    Influencapp, votre plateforme dynamique d'influenceurs,
                    concentre une communauté en pleine expansion d'experts en
                    influence digitale.
                    <br />
                    En 2024, avec un potentiel de plus de 100 000 influenceurs
                    en France, Influencapp est l'endroit idéal pour trouver des
                    influenceurs adaptés à vos besoins uniques. Que vous
                    cherchiez à collaborer avec une agence d'influenceur
                    spécialisée ou à engager un influenceur chien pour une
                    campagne spécifique, notre plateforme vous offre une
                    diversité de talents.
                    <br />
                    Formulez votre projet en rédigeant une annonce précise et
                    diffusez-la sur notre plateforme pour attirer les
                    propositions des influenceurs les plus en vue. Anticipez de
                    recevoir une variété d'offres, vous permettant de
                    sélectionner le collaborateur le plus adapté pour votre
                    initiative marketing.
                    <br />
                    Rejoignez Influencapp aujourd'hui pour explorer un univers
                    où les marques et les créateurs de contenu s'unissent pour
                    des partenariats influents et réussis!
                  </p>
                </div>

                <ComponentAuthRequired
                  valueDefault={
                    <Link to='/inscription'>
                      <button>Rejoindre la communauté Influencapp.com</button>
                    </Link>
                  }
                ></ComponentAuthRequired>
              </div>
            </div>

            <div className={styles.allContent}>
              <img src={Image2Widget2} alt='img widget' />
              <div className={styles.textContent}>
                <div>
                  <h4>
                    Influencapp : la plateforme incontournable pour se connecter
                    avec des influenceurs de tous les horizons
                  </h4>
                  <p>
                    Sur Influencapp, trouvez l'influenceur parfait pour mener à
                    bien vos projets de marketing d'influence.
                    <br />
                    Que vous cherchiez à lancer une campagne de mode avec un
                    influenceur mode, à explorer les dernières tendances en
                    technologie avec un influenceur technologie, au à dynamiser
                    votre marque dans le secteur alimentaire avec un influenceur
                    food, la solution est sur Influencapp. Notre plateforme
                    regorge de talents spécialisés: influenceurs voyage pour des
                    campagnes d'évasion. influenceurs sport pour un dynamisme
                    sans pareil, ou encore Influenceurs automobile pour les
                    passionnés de véhicules.
                    <br />
                    Des influenceurs décoration pour embellir votre image de
                    marque, aux influenceurs beauté pour des conseils avisés, en
                    passant par les influenceurs mode homme pour un style
                    affirmé, Influencapp est la destination de choix. Même pour
                    des niches spécifiques comme un influenceur chien, notre
                    plateforme vous connecte avec des profils variés et adaptés
                    à vos besoins.
                    <br />
                    Avec Influencapp, que ce soit pour des besoins ponctuels ou
                    des collaborations à long terme, profitez d'une flexibilité
                    et d'une réactivité exceptionnelles. Connectez-vous avec des
                    agences d'influenceurs ou des influenceurs indépendants, et
                    donnez une nouvelle dimension à vos campagnes publicitaires
                  </p>
                </div>
                <ComponentAuthRequired
                  valueDefault={
                    <Link to='/inscription'>
                      <button>S'inscrire gratuitement</button>
                    </Link>
                  }
                ></ComponentAuthRequired>
                {/* <Link to='/all-profiles'>
                <button>Voir les Influenceurs</button>
              </Link> */}
              </div>
            </div>
          </div>

          <div className={styles.widget3}>
            <div className={styles.titleContent}>
              <p>COMMENT ÇA MARCHE</p>
              <h4>
                Recevez des devis de Freelances disponibles en moins de 2
                minutes
              </h4>
              <p>
                Soumettez votre besoin sur notre plateforme et obtenez
                rapidement des offres de la part d'influenceurs et d'agences
                influenceurs spécialisées. Comparez les profils pour dénicher
                l'expert idéal qui boostera votre visibilité.
              </p>
            </div>

            <div className={styles.cardContainer}>
              <div className={styles.oneCard}>
                <p>1</p>
                <h5>Lancez votre recherche d'Influenceurs</h5>
                <p>
                  Publiez gratuitement votre besoin sur notre plateforme,
                  décrivant le profil d'influenceur ou d'agence d'influenceurs
                  que vous recherchez.
                </p>
                <ComponentAuthRequired
                  valueDefault={
                    <Link to='/connexion'>
                      <button>Je poste une annonce</button>
                    </Link>
                  }
                >
                  <Link to='/create-project'>
                    <button>Je poste une annonce</button>
                  </Link>
                </ComponentAuthRequired>
              </div>

              <div className={styles.oneCard}>
                <p>2</p>
                <h5>Recevez des propositions personnalisées</h5>
                <p>
                  Attendez juste un instant pour obtenir des offres sur mesure
                  de nano, micro, macro influenceurs et all stars, dans des
                  domaines variés comme la mode, la technologie, la gastronomie,
                  etc.
                </p>
              </div>

              <div className={styles.oneCard}>
                <p>3</p>
                <h5>Sélectionnez votre partenaire idéal</h5>
                <p>
                  Utilisez notre messagerie instantanée pour dialoguer avec les
                  influenceurs et choisissez celui qui correspond parfaitement à
                  vos attentes pour votre projet.
                </p>
              </div>
            </div>
          </div>

          <div className={styles.widget4}>
            <h2>Les bénéfices de collaborer avec un Influenceur</h2>
            <div className={styles.beneficeContainer}>
              <div className={styles.content}>
                <img src={BenefIcone1} alt='icone-benefice' />
                <h4>Rapidité d'exécution</h4>
                <p>
                  Que ce soit pour une collaboration ponctuelle ou sur le long
                  terme, trouvez rapidement des influenceurs de tous niveaux
                  (nano, micro, macro, all star) sur notre plateforme, prêts à
                  dynamiser votre projet dans les délais impartis.
                </p>
              </div>

              <div className={styles.content}>
                <img src={BenefIcone2} alt='icone-benefice' />
                <h4>Coûts avantageux</h4>
                <p>
                  Collaborer avec un influenceur individuel coûte souvent moins
                  cher qu'avec une agence classique, car ils ont moins de frais
                  fixes. Cela se traduit par des tarifs plus attractifs pour des
                  services de qualité équivalente.
                </p>
              </div>

              <div className={styles.content}>
                <img src={BenefIcone3} alt='icone-benefice' />
                <h4>Expertise spécifique</h4>
                <p>
                  En choisissant un influenceur spécialisé, vous bénéficiez
                  d'une expertise pointue dans des domaines variés tels que la
                  mode, la technologie, la gastronomie, offrant une valeur
                  ajoutée incontestable à votre projet.
                </p>
              </div>

              <div className={styles.content}>
                <img src={BenefIcone4} alt='icone-benefice' />
                <h4>Flexibilité et adaptabilité</h4>
                <p>
                  Les influenceurs, en tant qu'entrepreneurs individuels, sont
                  souvent plus flexibles et adaptables que les grandes agences,
                  facilitant les ajustements et les modifications en cours de
                  projet selon vos besoins.
                </p>
              </div>

              <div className={styles.content}>
                <img src={BenefIcone5} alt='icone-benefice' />
                <h4>Investissement réduit et efficace</h4>
                <p>
                  En collaborant avec un influenceur, vous évitez les coûts
                  associés à l'embauche et aux équipements, car ils utilisent
                  leurs propres outils et plateformes pour mener à bien votre
                  projet.
                </p>
              </div>

              <div className={styles.content}>
                <img src={BenefIcone6} alt='icone-benefice' />
                <h4>Perspective extérieure innovante</h4>
                <p>
                  Un freelance apporte un regard neuf à votre projet. Le choix
                  vous revient de lui demander de réaliser sa mission en tant
                  qu’exécutant ou en tant que spécialiste capable d’apporter des
                  idées innovantes pour votre entreprise.
                </p>
              </div>
            </div>
          </div>

          <div className={styles.widget5}>
            <div className={styles.imageContainer}>
              <img src={Image1Widget5} alt='img widget4' />
            </div>
            <div className={styles.textContent}>
              <h4>Types de missions à confier aux Influenceurs</h4>
              <p>
                Les influenceurs offrent une expertise polyvalente pour
                promouvoir votre marque dans divers secteurs, de la mode au
                gaming, en créant des campagnes uniques et ciblées adaptées à
                vos objectifs.
              </p>
              <div className={styles.checkContainer}>
                <div className={styles.oneCheck}>
                  <div>
                    <FontAwesomeIcon icon={faCheck} />
                  </div>
                  <div className={styles.content}>
                    <p>Stratégie Digitale Personnalisée</p>
                    <p>
                      Trouvez des influenceurs spécialisés pour booster votre
                      stratégie digitale, adaptés à chaque niche technologique
                      spécifique
                    </p>
                  </div>
                </div>

                <div className={styles.oneCheck}>
                  <div>
                    <FontAwesomeIcon icon={faCheck} />
                  </div>
                  <div className={styles.content}>
                    <p>Graphiste et Webdesign</p>
                    <p>
                      Vous avez besoin d'un nouveau logo et d'un nouveau site
                      internet ? Confiez des missions à un graphiste freelance
                      et à un webdesigner.
                    </p>
                  </div>
                </div>

                <div className={styles.oneCheck}>
                  <div>
                    <FontAwesomeIcon icon={faCheck} />
                  </div>
                  <div className={styles.content}>
                    <p>Commerce</p>
                    <p>
                      Vous cherchez à développer l'activité de votre entreprise
                      ? Trouvez un commercial indépendant et confiez lui la
                      mission de recruter des leads.Marketing et Expansion
                      Commerciale avec des Influenceurs
                    </p>
                  </div>
                </div>
              </div>
              {/* <Link to='/create-project'>
              <button>Voir toutes les missions Freelance</button>
            </Link> */}
              <ComponentAuthRequired
                valueDefault={
                  <Link to='/connexion'>
                    <button>Trouver des influenceurs</button>
                  </Link>
                }
              >
                <Link to='/create-project'>
                  <button>Trouver des influenceurs</button>
                </Link>
              </ComponentAuthRequired>
            </div>
          </div>

          <div className={styles.widget6}>
            <p>
              “Boostez l'activité de votre entreprise en collaborant avec des
              influenceurs experts en marketing pour générer des leads ciblés.”
            </p>
            {/* <Link to='/projects'>
            <button>Voir toutes les propositions</button>
          </Link> */}
            <ComponentAuthRequired
              valueDefault={
                <Link to='/connexion'>
                  <button>Recevoir des devis</button>
                </Link>
              }
            >
              <Link to='/create-project'>
                <button>Recevoir des devis</button>
              </Link>
            </ComponentAuthRequired>
          </div>
        </div>
      )}

      {/* popup callToLogin */}
      {callToLogin && (
        <PopupConnexionRequis
          context={`Connectez-vous ou inscrivez-vous gratuitement pour créer un projet de recherche d'influenceurs dans la niche de ${context}.`}
          HandleClick={HandleClick}
        />
      )}
    </>
  )
}

export default Accueil
