import React from 'react';
import CreateOrEditProject from '../../components/FormCreateOrEditProject/CreateOrEditProject';

function CreateProject() {

    return (
        <>
            <div>
                <h1 className="titlePage">Créer un nouveau projet</h1>
                <CreateOrEditProject context="createProject"/>
            </div>     
        </>
    )
}

export default CreateProject