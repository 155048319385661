import React, { useEffect, useState } from 'react'
import styles from "./CardSuccesPayment.module.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom';
import GifLoading from '../GifLoading/GifLoading';

function CardSuccesPayment() {
  const {session_id} = useParams();
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState("");

  useEffect(()=>{
    const fetchData = async () =>{
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/user/update-subscription?session_id=${session_id}`, {
                method: "POST",
                headers: {Authorization : "application/json"}
            })
            if(response.ok){
                setLoading(false);
                setStatus(true);
            } else{
                setLoading(false);
                setStatus(false);
            }
        } catch (error) {
            setLoading(false);
            setStatus(false);
            console.log("Erreur lors de la requette fecth", error)
        }
    }

    fetchData();
  }, [session_id])

  return (
    <>
        {loading ? <GifLoading positionDiv="fixed" /> : status === true || status === false ?
            <div className={styles.allContainer}>
                <div className={styles.cardContent}>
                    {status ? <FontAwesomeIcon icon={faCheckCircle}/> : status === false ? <FontAwesomeIcon style={status === false ? {color: "gray"} : {}} icon={faQuestionCircle}/> : null}
                    <p style={status === false ? {color: "gray"} : {}}>{status ? "Paiement réussi" : status === false ? "Paiement non reconnu" : null}</p>
                </div>
            </div>
            : null
        }
    </>
  )
}

export default CardSuccesPayment