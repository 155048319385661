import React, { useEffect, useState } from 'react';
import styles from "./index.module.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import Cookies from 'js-cookie';
import NotifFeedBackFecth from '../../NotifFeedBackFecth/NotifFeedBackFecth';
import ConfirmationRequired from "../../ConfirmationRequired/ConfirmationRequired";

function UpdateImageCouverture({ userData }) {
    const urlStreamImage = `${process.env.REACT_APP_URL_BASE_IMAGE}`;
    const [loading, setLoading] = useState("");
    const [selectedImage, setSelectedImage] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [deleteButton, setDeleteButton] = useState(false);
    const [modifAuthorize, setModifAuthorize] = useState("");
    const [messageFecth, setMessageFecth] = useState("");
    const [callBackMessage, setCallBackMessage] = useState("");
    const userCouvertureURL = userData?._link_photo_couverture?.url;

    const [confirmationContainer, setConfirmationContainer] = useState(false);


    // Confirmation de la suppression de la photo de couverture
    const ShowConfirmationContainer = (e) =>{
        e.preventDefault();
        setConfirmationContainer(!confirmationContainer);
    }
    const ConfirmationModif = () =>{
        setConfirmationContainer(!confirmationContainer);
        deletePhoto();
    }

    useEffect(()=>{
        if(userCouvertureURL !== "" && userCouvertureURL !== undefined && userCouvertureURL !== null){
            setDeleteButton(true);
            setImagePreview(`${urlStreamImage}${userCouvertureURL}`);
        } else{
            setImagePreview(null);
        }
    },[userCouvertureURL, urlStreamImage]);

    function resetFeedBack(){
        setTimeout(()=>{
            setModifAuthorize("");
            setCallBackMessage("");
            setMessageFecth("");
        }, 6000);
    }

    const handleImageChange = (e) => {
        const selectedFile = e.target.files[0];
        const tailleMaxAutorisee = 5 * 1024 * 1024;

        if (selectedFile) {
            const allowedFormats = ["image/jpeg", "image/jpg", "image/png", "image/webp", "image/svg+xml"];

            if (allowedFormats.includes(selectedFile.type) && selectedFile.size <= tailleMaxAutorisee) {
                const reader = new FileReader();
                reader.onload = () => {
                    setSelectedImage(selectedFile);
                    setImagePreview(reader.result);
                };
                reader.readAsDataURL(selectedFile);
                setModifAuthorize("");
                setCallBackMessage("");
                setMessageFecth("");
            } else {
                setModifAuthorize("error");
                setCallBackMessage("flex");
                setMessageFecth("Erreur, vérifier le format et la taille de votre image et réessayer.");
                e.target.value = '';
                setSelectedImage(null);

                resetFeedBack();
            }
        }
    };

    // Modification de la photo de couverture 
    const handleImageSubmit = async (e) => {
        e.preventDefault();
        if (selectedImage) {
            setLoading("editImage");
            const formData = new FormData();
            formData.append("photo", selectedImage);        
            try {
                const tokenLog = Cookies.get("_influencapp_tk_log");
                const response = await fetch(`${process.env.REACT_APP_API_URL}/user/update-profil-image?photo=couvertures`, {
                    method: "PUT",
                    headers: {
                        Authorization: `Bearer ${tokenLog}`,
                    },
                    body: formData,
                });
                if (response.ok) {
                    setTimeout(async ()=>{
                        setLoading("");
                        const result = await response.json();
                        setImagePreview(`${process.env.REACT_APP_URL_BASE_IMAGE}${result.imagePath}`);
                        setModifAuthorize(true);
                        setCallBackMessage("flex");
                        setMessageFecth("Photo de couverture modifié avec succès.");
                        setDeleteButton(true);
                        setSelectedImage(null);

                        resetFeedBack();
                    }, 2000)
                } else {
                    setTimeout(()=>{
                        setLoading("");
                        setModifAuthorize("error");
                        setCallBackMessage("flex");
                        setMessageFecth("Oups, une erreur s'est produite, veuillez réessayer plustard.");

                        resetFeedBack();
                    }, 2000)
                }
            } catch (error) {
                setLoading("");
                setModifAuthorize("error");
                setCallBackMessage("flex");
                setMessageFecth("Oups, une erreur s'est produite, veuillez réessayer plustard.");
                console.error("Erreur lors de la requête fetch :", error);

                resetFeedBack();
            }
        }
    };

    //Suppression de la photo de couverture
    const deletePhoto = async () => {
        setLoading("deleteImage");      
        try {
            const tokenLog = Cookies.get("_influencapp_tk_log");
            const response = await fetch(`${process.env.REACT_APP_URL_BASE_IMAGE}${userCouvertureURL ? userCouvertureURL : imagePreview.split(`${process.env.REACT_APP_URL_BASE_IMAGE}`)[1]}`, {
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${tokenLog}`,
                },
            });
            if (response.ok) {
                setTimeout(()=>{
                    setLoading("");
                    setSelectedImage(null);
                    setImagePreview(null)
                    setDeleteButton(false);
                    setModifAuthorize(true);
                    setCallBackMessage("flex");
                    setMessageFecth("Photo de couverture supprimé avec succès.");

                    resetFeedBack();
                }, 2000)
            } else {
                setTimeout(()=>{
                    setLoading("");
                    setModifAuthorize("error");
                    setCallBackMessage("flex");
                    setMessageFecth("Oups, une erreur s'est produite, veuillez réessayer plustard.");

                    resetFeedBack();
                }, 2000)
            }
        } catch (error) {
            setLoading("");
            setModifAuthorize("error");
            setCallBackMessage("flex");
            setMessageFecth("Oups, une erreur s'est produite, veuillez réessayer plustard.");
            console.error("Erreur lors de la requête fetch :", error);

            resetFeedBack();           
        }
    }

  return (
    <>
        <div className={`fadinAnimation ${styles.allContainer}`}>
            <h3>Photo de couverture</h3>
            <form encType="multipart/form-data">
                <label htmlFor='imageCouverture'>
                    <div>
                        {imagePreview && <img src={imagePreview} alt="Aperçu du banniere" />}
                    </div>
                    <FontAwesomeIcon icon={faPen} />
                </label>
                <input id='imageCouverture' type="file" name="photo" accept=".jpg, .jpeg, .png, .webp, .svg" onChange={handleImageChange} required />

                <div>
                    <p><span>Formats :</span> .jpg, .jpeg, .png, .webp, .svg</p>
                    <p><span>Taille maximum :</span> 5 Mo</p>
                </div>

                <div className={styles.button}>
                    <div onClick={handleImageSubmit} className={styles.save}>{loading === "editImage" ? "Enregistrement..." : "Enregistré"}</div> 
                    {deleteButton ? <div onClick={ShowConfirmationContainer} className={styles.delete}>{loading === "deleteImage" ?  "Suppression..." : "Supprimer la photo"}</div> : ""}
                </div>
            </form>
        </div>
        <NotifFeedBackFecth modifAuthorizeValue={modifAuthorize} callBackMessageValue={callBackMessage} messageFecthValue={messageFecth} />
        {confirmationContainer && <ConfirmationRequired
            contexte="Pour le respet du RGPD la suppression de votre photo de couverture sera definitive, confirmez-vous sa suppression ?"
            confirmation={ConfirmationModif}
            reset={ShowConfirmationContainer}
        />}
    </>
  );
}

export default UpdateImageCouverture;