import React, { useEffect, useState } from 'react'
import Cookies from 'js-cookie'
import NotifFeedBackFecth from '../../components/NotifFeedBackFecth/NotifFeedBackFecth'
import { useNavigate, useParams } from 'react-router-dom'
import CreateOrEditProject from '../../components/FormCreateOrEditProject/CreateOrEditProject'

function EditProject() {
  const navigate = useNavigate()
  const { id } = useParams()
  const [project, setProject] = useState({})

  // Les constantes de la gestions des notifs feedBack
  const [modifAuthorize, setModifAuthorize] = useState('')
  const [messageFecth, setMessageFecth] = useState('')
  const [callBackMessage, setCallBackMessage] = useState('')
  const [loading, setLoading] = useState('loadingComponent')

  function resetFeeback() {
    setTimeout(() => {
      setModifAuthorize('')
      setMessageFecth('')
      setCallBackMessage('')
    }, 6000)
  }

  useEffect(() => {
    const getProjectEditing = async () => {
      try {
        const token = Cookies.get('_influencapp_tk_log')
        const myHeaders = new Headers()
        myHeaders.append('Content-Type', 'application/json')
        myHeaders.append('Authorization', `Bearer ${token}`)

        const requestOptions = {
          method: 'GET',
          headers: myHeaders,
        }
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/project/get-edit-project?id=${id}`,
          requestOptions,
        )
        if (response.ok) {
          const resultat = await response.json()
          setProject(resultat.project)
          setLoading('')
        } else {
          const resultat = await response.json()
          if (resultat.Authorization === false) {
            navigate('/my-projects')
            setLoading('')
          } else {
            setModifAuthorize('error')
            setCallBackMessage('flex')
            setMessageFecth(
              "Oups, une erreur s'est produite, veuillez réessayer ultérièrement.",
            )

            resetFeeback()
            setLoading('')
          }
        }
      } catch (error) {
        setLoading('')
        console.log(
          "Une erreur s'est produite lors de la requette fecth",
          error,
        )
        setModifAuthorize('error')
        setCallBackMessage('flex')
        setMessageFecth(
          "Oups, une erreur s'est produite, veuillez réessayer ultérièrement.",
        )

        resetFeeback()
      }
    }

    getProjectEditing()
  }, [id, navigate])

  return (
    <>
      {Object.keys(project).length > 0 ? (
        <div>
          <h1 className='titlePage'>Modifier votre projet</h1>
          <CreateOrEditProject context='editProject' project={project} />
        </div>
      ) : loading === 'loadingComponent' ? null : (
        <div className='error'>
          <h1>500</h1>
          <p>oups, une erreur s'est produite, réessayer ultérieurement.</p>
        </div>
      )}
      <NotifFeedBackFecth
        modifAuthorizeValue={modifAuthorize}
        callBackMessageValue={callBackMessage}
        messageFecthValue={messageFecth}
      />
    </>
  )
}

export default EditProject
