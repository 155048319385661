import styles from './profilesNiches.module.scss'
import CardUserProfile from '../../components/CardUserProfile/CardUserProfile'
import { useEffect, useMemo, useState } from 'react'
import Cookies from 'js-cookie'
import GifFecthLoad from '../../components/GifFecthLoad/GifFecthLoad'
import { Link, useLocation, useParams } from 'react-router-dom'

function ProfilesNiches() {
  const location = useLocation()
  const { secteur } = useParams()
  const [data, setData] = useState([])
  const [loading, setLoading] = useState('fecthLoad')
  const fecthUrl = `${process.env.REACT_APP_API_URL}/user/get-users-secteur?filter&page=1&pageSize=10&secteur=${secteur}`
  // console.log(location);
  // On englobe resquestOptions dans useMemo pour eviter de le recalculer si tokenLog ne change pas
  const tokenLog = Cookies.get('_influencapp_tk_log')
  const resquestOptions = useMemo(
    () => ({
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${tokenLog}`,
      },
    }),
    [tokenLog],
  )

  useEffect(() => {
    setLoading('fecthLoad')
    const fetchInitialData = async () => {
      try {
        const response = await fetch(fecthUrl, resquestOptions)
        if (!response.ok) {
          setLoading('')
          throw new Error(`Erreur HTTP : ${response.status}`)
        }
        const result = await response.json()
        const { users } = result
        setData(users)
        setLoading('')
      } catch (error) {
        setLoading('')
        console.log('error lors de la requette fecth', error)
      }
    }

    fetchInitialData()
  }, [resquestOptions, fecthUrl])

  const activeLink = {
    fontWeight: '500',
    color: '#E87213',
  }

  return (
    <>
      <div className={`fadinAnimation ${styles.allContainer}`}>
        <h2 className={`titlePage`}>{secteur}</h2>
        <div className={styles.widgetContent}>
          <div className={styles.LeftContent}>
            <h3>Nos principaux profils</h3>
            {/* liste de base  */}
            {/* <div>
                            <p><Link style={location.pathname === '/profile-secteur/Influenceur(se)%20niche%20chien' ? activeLink : {}} to="/profile-secteur/Influenceur(se) niche chien">Influenceur(se) niche chien</Link></p>
                            <p><Link style={location.pathname === '/profile-secteur/Influenceuse%20mode' ? activeLink : {}} to="/profile-secteur/Influenceuse mode">Influenceuse mode</Link></p>
                            <p><Link style={location.pathname === '/profile-secteur/Influenceur(se)%20niche%20voyage' ? activeLink : {}} to="/profile-secteur/Influenceur(se) niche voyage">Influenceur(se) niche voyage</Link></p>
                            <p><Link style={location.pathname === '/profile-secteur/Influenceur(se)%20food' ? activeLink : {}} to="/profile-secteur/Influenceur(se) food">Influenceur(se) food</Link></p>
                            <p><Link style={location.pathname === '/profile-secteur/Influenceur(se)%20sport' ? activeLink : {}} to="/profile-secteur/Influenceur(se) sport">Influenceur(se) sport</Link></p>
                            <p><Link style={location.pathname === '/profile-secteur/Influenceur(se)%20automobile' ? activeLink : {}} to="/profile-secteur/Influenceur(se) automobile">Influenceur(se) automobile</Link></p>
                            <p><Link style={location.pathname === '/profile-secteur/Influenceuse%20beauté' ? activeLink : {}} to="/profile-secteur/Influenceuse beauté">Influenceuse beauté</Link></p>
                            <p><Link style={location.pathname === '/profile-secteur/Influenceur%20mode%20homme' ? activeLink : {}} to="/profile-secteur/Influenceur mode homme">Influenceur mode homme</Link></p>
                            <p><Link style={location.pathname === '/profile-secteur/Influenceur(se)%20d%C3%A9coration' ? activeLink : {}} to="/profile-secteur/Influenceur(se) décoration">Influenceur(se) décoration</Link></p>
                            <p><Link style={location.pathname === '/profile-secteur/Influenceur(se)%20gastronomie' ? activeLink : {}} to="/profile-secteur/Influenceur(se) gastronomie">Influenceur(se) gastronomie</Link></p>
                        </div> */}

            {/* liste apres demande de modif par amb */}
            <div>
              <p>
                <Link
                  style={
                    location.pathname === '/profile-secteur/Influenceur%20chien'
                      ? activeLink
                      : {}
                  }
                  to='/profile-secteur/Influenceur chien'
                >
                  Influenceur chien
                </Link>
              </p>
              <p>
                <Link
                  style={
                    location.pathname === '/profile-secteur/Influenceuse%20mode'
                      ? activeLink
                      : {}
                  }
                  to='/profile-secteur/Influenceuse mode'
                >
                  Influenceuse mode
                </Link>
              </p>
              <p>
                <Link
                  style={
                    location.pathname ===
                    '/profile-secteur/Influenceur%20voyage'
                      ? activeLink
                      : {}
                  }
                  to='/profile-secteur/Influenceur voyage'
                >
                  Influenceur voyage
                </Link>
              </p>
              <p>
                <Link
                  style={
                    location.pathname === '/profile-secteur/Influenceur%20food'
                      ? activeLink
                      : {}
                  }
                  to='/profile-secteur/Influenceur food'
                >
                  Influenceur food
                </Link>
              </p>
              <p>
                <Link
                  style={
                    location.pathname === '/profile-secteur/Influenceur%20sport'
                      ? activeLink
                      : {}
                  }
                  to='/profile-secteur/Influenceur sport'
                >
                  Influenceur sport
                </Link>
              </p>
              <p>
                <Link
                  style={
                    location.pathname ===
                    '/profile-secteur/Influenceur%20automobile'
                      ? activeLink
                      : {}
                  }
                  to='/profile-secteur/Influenceur automobile'
                >
                  Influenceur automobile
                </Link>
              </p>
              <p>
                <Link
                  style={
                    location.pathname ===
                    '/profile-secteur/Influenceuse%20beaut%C3%A9'
                      ? activeLink
                      : {}
                  }
                  to='/profile-secteur/Influenceuse beauté'
                >
                  Influenceuse beauté
                </Link>
              </p>
              <p>
                <Link
                  style={
                    location.pathname ===
                    '/profile-secteur/Influenceur%20mode%20homme'
                      ? activeLink
                      : {}
                  }
                  to='/profile-secteur/Influenceur mode homme'
                >
                  Influenceur mode homme
                </Link>
              </p>
              <p>
                <Link
                  style={
                    location.pathname ===
                    '/profile-secteur/Influenceur%20d%C3%A9coration'
                      ? activeLink
                      : {}
                  }
                  to='/profile-secteur/Influenceur décoration'
                >
                  Influenceur décoration
                </Link>
              </p>
              <p>
                <Link
                  style={
                    location.pathname ===
                    '/profile-secteur/Influenceur%20technologie'
                      ? activeLink
                      : {}
                  }
                  to='/profile-secteur/Influenceur technologie'
                >
                  Influenceur technologie
                </Link>
              </p>
              <p>
                <Link
                  style={
                    location.pathname ===
                    '/profile-secteur/Influenceur%20gaming'
                      ? activeLink
                      : {}
                  }
                  to='/profile-secteur/Influenceur gaming'
                >
                  Influenceur gaming
                </Link>
              </p>
              <p>
                <Link
                  style={
                    location.pathname === '/profile-secteur/Influenceur%20DIY'
                      ? activeLink
                      : {}
                  }
                  to='/profile-secteur/Influenceur DIY'
                >
                  Influenceur DIY
                </Link>
              </p>
              <p>
                <Link
                  style={
                    location.pathname ===
                    '/profile-secteur/Influenceur%20sant%C3%A9'
                      ? activeLink
                      : {}
                  }
                  to='/profile-secteur/Influenceur santé'
                >
                  Influenceur santé
                </Link>
              </p>
              <p>
                <Link
                  style={
                    location.pathname ===
                    '/profile-secteur/Influenceurs%20parents'
                      ? activeLink
                      : {}
                  }
                  to='/profile-secteur/Influenceurs parents'
                >
                  Influenceurs parents
                </Link>
              </p>
              <p>
                <Link
                  style={
                    location.pathname ===
                    '/profile-secteur/Influenceur%20gastronomie'
                      ? activeLink
                      : {}
                  }
                  to='/profile-secteur/Influenceur gastronomie'
                >
                  Influenceur gastronomie
                </Link>
              </p>
              <p>
                <Link
                  style={
                    location.pathname ===
                    '/profile-secteur/Influenceur%20animaux'
                      ? activeLink
                      : {}
                  }
                  to='/profile-secteur/Influenceur animaux'
                >
                  Influenceur animaux
                </Link>
              </p>
              <p>
                <Link
                  style={
                    location.pathname ===
                    '/profile-secteur/D%C3%A9veloppement%20personnel'
                      ? activeLink
                      : {}
                  }
                  to='/profile-secteur/Développement personnel'
                >
                  Développement personnel
                </Link>
              </p>
              <p>
                <Link
                  style={
                    location.pathname === '/profile-secteur/Influenceur%20moto'
                      ? activeLink
                      : {}
                  }
                  to='/profile-secteur/Influenceur moto'
                >
                  Influenceur moto
                </Link>
              </p>
            </div>
          </div>

          <div className={styles.RightContent}>
            {loading === 'fecthLoad' ? (
              <GifFecthLoad positionDiv='fixed' />
            ) : (
              <>
                <div className={styles.cardContainer}>
                  {data.map((user, index) => (
                    <CardUserProfile
                      key={index}
                      pseudo={user?.pseudo}
                      name={user?.name}
                      urlProfil={user?._link_photo_profil?.url}
                      statut={user?.statut}
                      presentation={user?.presentation}
                      views={user?.statistique?.views}
                      inFavorize={user?.statistique?.inFavorize}
                      lastConnect={user?.statistique?.lastConnect}
                      subscription={user?.subscription?.type}
                    />
                  ))}

                  {loading === 'fecthLoad' ? null : data.length > 0 ? null : (
                    <div className={styles.notFoundProfil}>
                      Aucun profil trouver
                    </div>
                  )}
                </div>

                <div className={styles.callSeeAllProfiles}>
                  <Link to='/all-profiles'>
                    <button>Voir tous les profils</button>
                  </Link>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default ProfilesNiches
